import {
    LoadingOutlined,
    DownloadOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    EditOutlined, CalendarOutlined
} from "@ant-design/icons";
import React, {useContext, useEffect, useRef, useState} from "react";
import {
    Button,
    Col,
    Row,
    Select,
    Table,
    Typography,
    Skeleton,
    Statistic,
    Form,
    Card,
    Input,
    Tag,
    Popover,
    Badge, Switch, Tabs, Modal, InputNumber, Tooltip, Radio
} from "antd";
import {buildTimePeriods, buildTimeKey, buildWeekLabel, parseTimeKey, exportToExcel, getPeriodStart} from "../utils";
import Loading from "../Loading";
import CombinationChart from "../charts/Chart";
import {currencyFormatter, volumeFormatter, findChangedIndex, nullSafeConcat} from '../utils'
import dayjs from "dayjs";

const colorPrimary = '#4fad96';
const EditableContext = React.createContext(null);


const PlanningSheet = (props) => {
    const {email, accessToken, dataSource, handleSave, validating, setSkuSelect, volumeSetting, setVolumeSetting, unit, setUnit, aggPeriod, setAggPeriod} = props;
    const [tab, setTab] = useState('1');
    const [keywords, setKeywords] = useState([]);
    const [chartOptions, setChartOptions] = useState(null);
    const [stats, setStats] = useState(null);

    const {timePeriods, pastTimePeriods} = buildTimePeriods(aggPeriod);

    const volumeName = dataSource['settings'].length > 0 ? (volumeSetting ? dataSource['settings'][0]['po_unit'] : dataSource['settings'][0]['agg_unit']) : 'units';
    const staticSuffix = (unit === 'revenue' ? '' : volumeName)
    const timeDenom = (aggPeriod === 'MS' ? 'Month' : (aggPeriod === 'QS' ? 'Quarter' : 'Week')) ;
    const rateSuffix = (unit === 'revenue' ? '' : volumeName) + ' / ' + timeDenom;
    const ratePrefix = (unit === 'revenue' ? '$' : '');

    useEffect(() => {
        const minX = getPeriodStart(dayjs().subtract(12, 'months').toDate(), aggPeriod);
        const maxX = getPeriodStart(dayjs().add(12, 'months').toDate(), aggPeriod);
    
        let aggregatedHistory = {};
        let aggregatedFutureData = [0, 0, 0];
        let aggregatedActuals = {};
        let aggregatedPosEvent = {};
        let aggregatedNegEvent = {};
        let aggregatedLastYearData = {};
    
        dataSource['history'].forEach((history, skuIndex) => {
            if (dataSource['settings'][skuIndex].archived) return;

            Object.entries(history.actual).forEach(([date, volume]) => {
                aggregatedHistory[date] = (aggregatedHistory[date] || 0) + parseFloat(volume);
            });
    
            const futureData = dataSource['forecasts'][skuIndex];
            futureData.forEach((value, index) => {
                aggregatedFutureData[index] += value;
            });
    
            const actuals = dataSource['actuals']['row'][skuIndex].map(innerRow => {
                const mutated = Object.fromEntries(Object.entries(innerRow).filter(([k, v]) => !k.includes('user_input')));
                return mutated;
            });
    
            Object.entries(actuals[0]).forEach(([date, value]) => {
                aggregatedActuals[date] = (aggregatedActuals[date] || 0) + parseFloat(value);
            });
    
            const posEvent = dataSource['actuals']['data'][skuIndex]['pos_event'];
            Object.entries(posEvent).forEach(([date, value]) => {
                aggregatedPosEvent[date] = (aggregatedPosEvent[date] || 0) + parseFloat(value);
            });
    
            const negEvent = dataSource['actuals']['data'][skuIndex]['neg_event'];
            Object.entries(negEvent).forEach(([date, value]) => {
                aggregatedNegEvent[date] = (aggregatedNegEvent[date] || 0) + parseFloat(value);
            });
    
            const lastYearData = dataSource['actuals']['data'][skuIndex]['last_year'];
            Object.entries(lastYearData).forEach(([date, value]) => {
                aggregatedLastYearData[date] = (aggregatedLastYearData[date] || 0) + parseFloat(value);
            });
        });
    
        const future = {
            yupper: aggregatedFutureData[0],
            ylower: aggregatedFutureData[2],
            yhat: aggregatedActuals
        };

        const positiveEventContributionSeries = {
            name: 'Pipeline',
            data: Object.entries(aggregatedPosEvent)
                .map(([date, value]) => {
                    return [parseTimeKey(date, aggPeriod), value];
                })
                .sort((a, b) => a[0] - b[0]),
            type: 'column',
            zIndex: 2,
            color: '#4fad96',
            lcolor: '#4fad96'
        };
    
        const negativeEventContributionSeries = {
            name: 'Discontinuation',
            data: Object.entries(aggregatedNegEvent)
                .map(([date, value]) => {
                    return [parseTimeKey(date, aggPeriod), value]
                })
                .sort((a, b) => a[0] - b[0]),
            type: 'column',
            zIndex: 2,
            color: {
                pattern: {
                    path: {
                        d: 'M 0 0 L 10 10 M -1 9 L 9 19 M 9 -1 L 19 9',
                        strokeWidth: 3
                    },
                    width: 10,
                    height: 10,
                    color: '#A00054',
                    opacity: 1
                },
            },
            lcolor: '#A00054'
        };
    
        const lastYear = {
            name: "Last Year",
            data: Object.keys(aggregatedLastYearData)
                .map(date => {
                    const val = aggregatedLastYearData[date];
                    return [parseTimeKey(date, aggPeriod).getTime(), parseFloat(val)];
                })
                .filter(dataPoint => !dataPoint.some(value => isNaN(value) || value === 0))
                .sort((a, b) => a[0] - b[0]),
            type: 'line',
            zIndex: 2,
            lineWidth: 1,
            connectNulls: true,
            fillOpacity: 0.5,
            color: '#FFA726',
            id: 'lastyear',
        };
    
        const futureBarData = {
            name: 'Existing Business Forecast',
            data: Object.entries(aggregatedActuals) 
                .map(([dateKey, yhatValue]) => {
                    const userEdited = false; 
                    const negEventValue = aggregatedNegEvent[dateKey] || 0;
                    const adjustedYhat = yhatValue + negEventValue;
    
                    return {
                        x: parseTimeKey(dateKey, aggPeriod),
                        y: parseFloat(adjustedYhat),
                        color: userEdited ? 'rgb(191 216 237)' : 'rgba(64,145,203)',
                        userEdited: userEdited
                    };
                })
                .sort((a, b) => a.x - b.x),
            type: 'column',
            zIndex: 1,
            id: 'futureBarDataGroup',
            //color: 'rgba(64,145,203)'
        };
        
        const aggPeriodMapping = {
            'MS': { pointWidth: 20, timeShift: 5 * 24 * 3600 * 1000 }, // for Monthly
            'QS': { pointWidth: 60, timeShift: 15 * 24 * 3600 * 1000 }, // for Quarterly
            'W': { pointWidth: 5, timeShift: 24 * 3600 * 1000 } // for Weekly
        };

        const { pointWidth, timeShift } = aggPeriodMapping[aggPeriod];

        const updatedChartData = {
            name: 'Actuals',
            data: Object.entries(aggregatedHistory)
                .map(([date, volume]) => {
                    return [parseTimeKey(date, aggPeriod).getTime(), parseFloat(volume)];
                })
                .sort((a, b) => a[0] - b[0]),
            type: 'column',
            zIndex: 1,
            color: '#FFA726',
        };
    
        const updatedChartDataWithPlacement = {
            ...updatedChartData,
            pointPadding: 0.0,
            pointWidth: pointWidth,
            data: updatedChartData.data.map(([x, y]) => [x + timeShift, y])
        };
    
        const newOptions = {
            chart: {
                zoomType: 'xy',
            },
            legend: {
                useHTML: true,
                symbolWidth: 0,
                symbolHeight: 0,
                labelFormatter: function () {
                    const vis = this.visible;
                    const grey = '#cccccc';
    
                    if (this.userOptions.id === 'customerGroup') {
                        // return  [...largestCustomersWithColors].map(customer => {
                        //     return `<span style="display:inline-block; width: 12px; height: 12px; margin-top: 1px; margin-left: 5px; border-radius: 50%; background-color: ${vis ? customer.color : grey};"></span>`;
                        // }).join('') + `<span style="color: ${vis ? 'black' : '#cccccc'}"> Customer Actuals</span>`;
                        return `<span style="margin-left: 5px; color: ${vis ? 'black' : '#cccccc'}"> Actuals</span>`;
                    } else if (this.userOptions.id === 'lastyear') {
                        return `<span style="display:inline-block; width: 12px; height: 2px; margin-bottom: 2px; margin-left: 5px; border-radius: 2px; background-color: ${vis ? '#FFA726' : grey};"></span>` + `<span style="margin-left: 5px; color: ${vis ? 'black' : '#cccccc'}"> Last Year</span>`;
                    } else if (this.userOptions.id === 'futureBarDataGroup') {
                        return `<span style="margin-left: 5px; color: ${vis ? 'black' : '#cccccc'};">Existing Business Forecast</span>`;
                        //return `<span style="margin-left: 5px; color: ${vis ? 'black' : '#cccccc'};"> ${this.name}</span>`
                    } else {
                        return `<span style="margin-left: 5px;"> ${this.name}</span>`;
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                min: minX.getTime(),
                max: maxX.getTime(),
                plotLines: [{
                    color: 'black', // Color of the line
                    width: 1, // Width of the line
                    value: new Date().getTime(), // Today's date in milliseconds
                    zIndex: 1,
                    dashStyle: 'dash', // Add this property to make the line dashed
                    label: {
                        text: 'Today', // The label text
                        align: 'left', // Alignment of the label
                        y: -5, // Vertical position of the label
                        x: 5, // Horizontal position of the label
                        style: {
                            color: 'black', // Color of the label text
                        },
                    },
                }]
            },
            yAxis: {
                title: {
                    text: unit !== 'revenue' ? `Volume [${!volumeSetting ? 'secondary unit' : 'primary unit' }]` : `Revenue`
                },
                labels: {
                    formatter: function() {
                        if (unit === 'revenue') {
                            return currencyFormatter.format(this.value.toFixed(2));
                        } else {
                            return volumeFormatter.format(this.value.toFixed(2));
                        }
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    grouping: false,
                    groupPadding: 0,
                    pointPadding: 0
                },
                series: {
                    events: {
                    //   click: function (event) {
                    //     handleColumnClick(event);
                    //   },
                    },
                },
            },
            tooltip: {
                shared: false,
                formatter: function() {
                    let message;
                    if (unit === 'revenue') {
                        message = `${this.series.name}: ${currencyFormatter.format(this.y.toFixed(2))}</b>`;
                    } else {
                        message = `${this.series.name}: ${volumeFormatter.format(this.y.toFixed(2))}</b>`;
                    }

                    if (this.point.userEdited) {
                        return `[User Edited Override] ${this.series.name}: <b>${volumeFormatter.format(this.y.toFixed(2))}</b>`;
                    }

                    if (this.series.name === 'Actuals' || this.series.name === 'Last Year') {
                        return `${message}`;
                    } 

                    return message;
                }
            },
            series: [
                { ...positiveEventContributionSeries, pointPadding: 0.0, pointWidth: pointWidth, data: positiveEventContributionSeries.data.map(([x, y]) => [x - timeShift, y]) },
                { ...futureBarData, pointPadding: 0.0, pointWidth: pointWidth, data: futureBarData.data.map(({x, y, color, userEdited}) => ({x: x - timeShift, y, color, userEdited})) },
                lastYear,
                { ...negativeEventContributionSeries, pointPadding: 0.0, pointWidth: pointWidth, data: negativeEventContributionSeries.data.map(([x, y]) => [x - timeShift, y]) },
                updatedChartDataWithPlacement
            ],
        };
        
        setChartOptions(newOptions);

    }, [dataSource]);

    useEffect(() => {
        // TODO: in the fullness of time this horror should be calculated on the backend with a true timepicker for this report
        if (validating) return;
    
        const keywordLowercased = keywords.map(keyword => keyword.toLowerCase());
        const filtered = dataSource['settings'].filter((s) => keywordLowercased.every(kw => s.name.toLowerCase().includes(kw)))
        const total = filtered.map((f) => f.sum).reduce((a, b) => a + b, 0)
        
        const pastTotals = pastTimePeriods.map((m, ri) => {
            const mstr = buildTimeKey(m, aggPeriod);
            const index = 'units';

            const total = dataSource['plan'].filter((row) => {
                return !row.archived && (keywords.every(keyword => row.name.toLowerCase().includes(keyword.toLowerCase())))
            }).map((row) => {
                return dataSource['history'][parseInt(row['key'])][index][mstr];
            }).reduce((a, b) => {
                const cleanA = isNaN(a) ? 0 : a
                const cleanB = isNaN(b) ? 0 : b
                return cleanA + cleanB
            }, 0)
            var returnObj = {};
            returnObj['month'] = mstr;
            returnObj['total'] = total;

            return returnObj;
        });

        const recentTotals = pastTotals.slice(Math.max(pastTotals.length - 3, 1));
        const recentVal = recentTotals.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);

        setStats({
            timeStr: recentTotals[0]['month'] + ' - ' + recentTotals[2]['month'],
            velocity: recentVal / 3,
            total: recentVal,
        });
    }, [dataSource, keywords]);
    
    const totalsColumns = [
        {
            title: null,
            dataIndex: 'total',
            width: 210,
            render: (_, row) => {
                return <Typography.Text>Totals</Typography.Text>
            }
        },
        ].concat((tab === '1' ? timePeriods : pastTimePeriods).map((m, i) => {
            const mstr = buildTimeKey(m, aggPeriod);
            return {
                title: aggPeriod !== 'W' ? mstr : buildWeekLabel(m),
                dataIndex: mstr,
                width: 100,
                align: 'right',
                render: (val) => {
                    const cleaned = isNaN(val) ? 0 : val
                    if (unit === 'revenue' || unit === 'cost' || unit === 'profit') {
                        return currencyFormatter.format(cleaned)
                    } else if (unit === 'volume') {
                        return volumeFormatter.format(cleaned)
                    }
                }
            }
        })
    );

    const defaultColumns = [
        {
            title: 'SKU',
            dataIndex: 'sku',
            width: 210,
            render: (_, row) => {
                return <>
                    <Typography.Text
                        onClick={() => setSkuSelect(row.sku)}
                        style={{
                            color: "#0091AE", // Set the text color to blue
                            textDecoration: 'underline', // Underline the text
                            cursor: 'pointer', // Change cursor to pointer when hovering
                        }}
                    >
                        {row.name}
                    </Typography.Text>
                    <Typography.Text
                        style={{
                            color: "grey", // Set the text color to blue
                        }}
                    > [{unit === 'revenue' ? 'Revenue' : (volumeSetting ? dataSource['settings'][parseInt(row.key)]['po_unit'] : dataSource['settings'][parseInt(row.key)]['agg_unit'])}]
                    </Typography.Text>
                </>
            }
        },
    ].concat(
        (tab === '1' ? timePeriods : pastTimePeriods).map((m, i) => {
            const mstr = buildTimeKey(m, aggPeriod);

            return {
                title: aggPeriod !== 'W' ? mstr : buildWeekLabel(m),
                dataIndex: mstr,
                width: 100,
                align: 'right',
                sorter: (a, b) => {
                    return (isNaN(b[mstr]) ? 0 : b[mstr])  - (isNaN(a[mstr]) ? 0 : a[mstr])
                },
                defaultSortOrder: i === 0 ? 'ascend' : undefined,
                sortDirections: ['ascend', 'descend'],
                render: (val, row) => {
                    const userEntry = row[mstr + 'user_input']
                    const base = row[mstr + 'baseline']
                    const pos = row[mstr + 'positive_events'] ? row[mstr + 'positive_events'] : 0
                    const neg = row[mstr + 'negative_events'] ? row[mstr + 'negative_events'] : 0

                    var cleaned = isNaN(val) ? 0 : val;
                    if (unit === 'revenue' || unit === 'cost' || unit === 'profit') {
                        cleaned = currencyFormatter.format(cleaned)
                    } else {
                        cleaned = volumeFormatter.format(cleaned)
                    }

                    return {
                        props: {
                            style: {
                                //borderRight: '1px solid',
                                //backgroundColor: userEntry ? "#4091CB7E" : undefined,
                            }
                        },
                        children: (
                            <div>
                                <Popover title={row.name + ' - ' + mstr + ` [${volumeSetting ? dataSource['settings'][parseInt(row.key)].po_unit : dataSource['settings'][parseInt(row.key)].agg_unit }]`} content={
                                    <div style={{minWidth: 350}}>
                                        <Row align="middle">
                                            <Col span={2}>
                                                <Badge color="#4fad96" />
                                            </Col>
                                            <Col span={14}>
                                                <Typography.Text>New Business & Promo Lift:</Typography.Text>
                                            </Col>
                                            <Col span={8}>
                                                <Typography.Text>{volumeFormatter.format(pos)}</Typography.Text>
                                            </Col>
                                        </Row>
                                        <Row align="middle">
                                            <Col span={2}>
                                                <Badge color="#A00054" />
                                            </Col>
                                            <Col span={14}>
                                                <Typography.Text>Discontinuation:</Typography.Text>
                                            </Col>
                                            <Col span={8}>
                                                <Typography.Text>{volumeFormatter.format(neg)}</Typography.Text>
                                            </Col>
                                        </Row>
                                        <Row align="middle">
                                            <Col span={2}>
                                                <Badge color="rgba(64,145,203)" />
                                            </Col>
                                            <Col span={14}>
                                                <Typography.Text>AI Baseline:</Typography.Text>
                                            </Col>
                                            <Col span={8}>
                                                <Typography.Text>{volumeFormatter.format(base)}</Typography.Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <hr style={{ margin: '8px 0' }} />
                                            </Col>
                                        </Row>
                                        <Row align="middle">
                                            <Col span={2}>
                                            </Col>
                                            <Col span={14}>
                                                <Typography.Text strong>Total:</Typography.Text>
                                            </Col>
                                            <Col span={8}>
                                                <Typography.Text strong>{cleaned}</Typography.Text>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                >
                                    <Row>
                                        <Col span={2}>
                                            { pos > 0 &&
                                                <Badge color="#4fad96" />
                                            }
                                        </Col>
                                        <Col span={2}>
                                            { neg < 0 &&
                                                <Badge color="#A00054" />
                                            }
                                        </Col>
                                        <Col span={20}>
                                            <Typography.Text>{cleaned}</Typography.Text>
                                        </Col>
                                    </Row>
                                </Popover>

                        </div>
                        )
                    }
                }
            }
        })
    );

    const exportData = dataSource ? (dataSource['plan'].filter((row) => {
        return !row.archived && (keywords.every(keyword => row.name.toLowerCase().includes(keyword.toLowerCase())))
    }).map((row, ri) => {
        var mutated = {...row};

        (tab === '1' ? timePeriods : pastTimePeriods).map((m, i) => {
            const mstr = buildTimeKey(m, aggPeriod);
            const index = 'units';

            if (tab === '1') {
                const dataPoint = dataSource['actuals']['data'][parseInt(row['key'])][index][mstr];
                const userInput = dataSource['actuals']['data'][parseInt(row['key'])]['userEdited'][mstr]
                const posEvent = dataSource['actuals']['data'][parseInt(row['key'])]['pos_event'][mstr]
                const negEvent = dataSource['actuals']['data'][parseInt(row['key'])]['neg_event'][mstr]

                mutated[mstr] = isNaN(dataPoint) ? 0 : parseInt(dataPoint);

                // add event contributions
                mutated[mstr] = mutated[mstr] + posEvent;
                mutated[mstr] = Math.round(mutated[mstr] + negEvent);
            } else {
                const dataPoint = dataSource['history'][parseInt(row['key'])][index][mstr];
                mutated[mstr] = isNaN(dataPoint) ? 0 : parseInt(dataPoint);
            }

            mutated[mstr] = isNaN(mutated[mstr]) ? 0 : mutated[mstr]
        })

        // order
        mutated = Object.fromEntries(Object.entries(mutated).filter((e) => {
            const [k, v] = e;
            return !['status', 'key', 'archived', 'product_id'].includes(k);
        }).sort(([a, va], [b, vb]) => {
            if (!isNaN(new Date(a).getTime()) && !isNaN(new Date(b).getTime())) {
                return new Date(a).getTime() - new Date(b).getTime()
            } else {
                if (a === 'sku') {
                    return -100000000
                } else {
                    return 10000000
                }
            }
        }))

        return mutated;
    })) : [];

    const tableData = dataSource ? (dataSource['plan'].filter((row) => {
        return !row.archived && (keywords.every(keyword => row.name.toLowerCase().includes(keyword.toLowerCase())))
    }).map((row, ri) => {
        const mutated = {...row};

        (tab === '1' ? timePeriods : pastTimePeriods).map((m, i) => {
            const mstr = buildTimeKey(m, aggPeriod);
            const index = 'units';

            if (tab === '1') {

                const dataPoint = dataSource['actuals']['data'][parseInt(row['key'])][index][mstr]
                const userInput = dataSource['actuals']['data'][parseInt(row['key'])]['userEdited'][mstr]
                const posEvent = dataSource['actuals']['data'][parseInt(row['key'])]['pos_event'][mstr]
                const negEvent = dataSource['actuals']['data'][parseInt(row['key'])]['neg_event'][mstr]

                mutated[mstr+'user_input'] = userInput;
                mutated[mstr] = isNaN(dataPoint) ? 0 : dataPoint;
                mutated[mstr+'baseline'] = mutated[mstr];

                // add event contributions
                mutated[mstr] = mutated[mstr] + posEvent;
                mutated[mstr+'positive_events'] = posEvent;
                mutated[mstr] = mutated[mstr] + negEvent;
                mutated[mstr+'negative_events'] = negEvent;

            } else {
                const dataPoint = dataSource['history'][parseInt(row['key'])][index][mstr];
                mutated[mstr] = isNaN(dataPoint) ? 0 : dataPoint;
            }
        })

        return mutated;
    })) : [];

    const handleFilterChange = value => {
        setKeywords(value);
    }

    return (
        <div>
            <Row>
                <Col flex={'150px'}>
                    <Typography.Text style={{fontSize: 18, fontWeight: "bold"}}>Forecast Report</Typography.Text>
                </Col>
                <Col flex={'600px'}>
                    {dataSource &&
                        <Typography.Text style={{fontSize: 16}}>Crunching <Tag>{volumeFormatter.format(dataSource['meta']['total_orders'])}</Tag>data points... latest purchase order placed for <Tag>{dataSource['meta']['newest_data']}</Tag></Typography.Text>
                    }
                </Col>
                <Col flex={'auto'}>
                </Col>
                <Col align={'right'}>
                    {validating &&
                        <Tag  color={colorPrimary}><Typography.Text style={{color: "white"}}><LoadingOutlined/> Loading</Typography.Text></Tag>
                    }
                </Col>
            </Row>
            <br/>
            <Row>
                <Col flex={'250px'} style={{paddingRight: 15}}>
                    { dataSource &&
                        <>
                            <Tooltip placement="left" title={`Values can only be edited in default units`}>
                                <Select
                                    style={{width: '100%'}}
                                    optionFilterProp="children"
                                    defaultValue={unit === 'revenue' ? 'revenue' : (volumeSetting ? 'volume_unit' : 'volume_case')}
                                    onChange={(value, event) => {
                                        if (value === 'volume_unit' || value === 'volume_case') {
                                            setUnit('volume');
                                            if (value === 'volume_unit')
                                                setVolumeSetting(true);
                                            else
                                                setVolumeSetting(false);
                                        } else {
                                            setUnit('revenue');
                                            setVolumeSetting(true);
                                        }
                                    }}
                                    options={
                                        nullSafeConcat(
                                        [
                                            // {label: 'Profit/Loss (Plan)', value: 'profit'},
                                            // {label: 'Cost (Plan)', value: 'cost'},
                                            {label: `Volume (${dataSource['settings'].length > 0 ? dataSource['settings'][0]['po_unit'] : 'units'})`, value: 'volume_unit'},
                                        ], dataSource['settings'].length > 0 && dataSource['settings'][0]['agg_unit'] === null ? null : [
                                            {label: `Volume (${dataSource['settings'].length > 0 ? dataSource['settings'][0]['agg_unit'] : 'cases'})`, value: 'volume_case'},
                                        ]).concat([
                                            {label: 'Revenue', value: 'revenue'},
                                        ])
                                    }
                                />
                            </Tooltip>
                        </>
                    }
                </Col>
                <Col flex={'270px'}>
                    <Radio.Group onChange={(val) => setAggPeriod(val.target.value)} value={aggPeriod}>
                        <Radio.Button value="W">Weekly</Radio.Button>
                        <Radio.Button value="MS">Monthly</Radio.Button>
                        <Radio.Button value="QS">Quarterly</Radio.Button>
                    </Radio.Group>
                </Col>
                <Col flex={'300px'}>
                    <Select
                        style={{ width: '100%' }}
                        mode={"tags"}
                        value={keywords}
                        placeholder="Filter SKUs (e.g. 6pk)"
                        notFoundContent={null}
                        onChange={handleFilterChange}
                    >
                        {keywords.map(keyword => (
                            <Select.Option key={keyword}>{keyword}</Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col flex={'auto'}>
                </Col>
                <Col align={'right'}>
                    {dataSource &&
                        <Button type={"primary"} onClick={() => exportToExcel(exportData, 'PantryForecast')}>
                            <DownloadOutlined style={{paddingRight: 5}}/>
                            Export Forecasts
                        </Button>
                    }
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Card bordered={false}>
                    {(validating || stats === null) && <Skeleton active paragraph={{ rows: 1 }} />}
                    {!(validating || stats === null) && <>
                        <Statistic
                        title={`Average Velocity Actual (${stats.timeStr})`}
                        value={stats.velocity}  
                        precision={0} 
                        prefix={ratePrefix}
                        suffix={rateSuffix}
                        />
                    </>}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                    {(validating || stats === null) && <Skeleton active paragraph={{ rows: 1 }} />}
                    {!(validating || stats === null) && <>
                        <Statistic
                            title={`Total Actual (${stats.timeStr})`}
                            value={stats.total}  
                            precision={0} 
                            prefix={ratePrefix}
                            suffix={staticSuffix}
                        />
                    </>}
                    </Card>
                </Col>
                </Row>
            <br/>
            {!validating && dataSource &&
                <div>
                    <CombinationChart options={chartOptions}/>
                </div>
            }
            <Tabs
                size={"large"}
                type={"card"}
                hideAdd={true}
                defaultActiveKey="1"
                items={[
                    {
                        key: '1',
                        label: "Future",
                    },
                    {
                        key: '2',
                        label: "Past",
                    },
                ]}
                onChange={(t) => {return setTab(t)}}
            />
            { validating &&
                <>
                    <Loading/>
                    <br/>
                    <Typography.Text style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        Processing data, this may take a moment...
                    </Typography.Text>
                </>
            }
            {!validating && dataSource &&
                <div style={{overflow: "auto"}}>
                <Table
                    style={{ minWidth: aggPeriod === 'QS' ? 1000 : 2000}}
                    //scroll={{ x: 2000 }}
                    bordered
                    pagination={false}
                    dataSource={[(tab === '1' ? timePeriods : pastTimePeriods).map((m, ri) => {
                        const mstr = buildTimeKey(m, aggPeriod);
                        const index = 'units';

                        const total = dataSource['plan'].filter((row) => {
                            return !row.archived && (keywords.every(keyword => row.name.toLowerCase().includes(keyword.toLowerCase())))
                        }).map((row) => {
                            if (tab === '1') {
                                const posEvent = dataSource['actuals']['data'][parseInt(row['key'])]['pos_event'][mstr]
                                const negEvent = dataSource['actuals']['data'][parseInt(row['key'])]['neg_event'][mstr]
                
                                return dataSource['actuals']['data'][parseInt(row['key'])][index][mstr] + posEvent + negEvent;
                            } else {
                                return dataSource['history'][parseInt(row['key'])][index][mstr];
                            }
                        }).reduce((a, b) => {
                            const cleanA = isNaN(a) ? 0 : a
                            const cleanB = isNaN(b) ? 0 : b
                            return cleanA + cleanB
                        }, 0)
                        var returnObj = {key: 0};
                        returnObj[mstr] = total;

                        return returnObj;
                    }).reduce((a, b) => {return {...a, ...b}})]}
                    columns={totalsColumns}
                />
                <Table
                    style={{minWidth: aggPeriod === 'QS' ? 1000 : 2000}}
                    pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ['25', '50', '100'],
                        style: {float: 'left'}
                    }}
                    bordered
                    dataSource={tableData}
                    columns={defaultColumns}
                />
            </div>
            }
        </div>
    );
}

export default PlanningSheet;