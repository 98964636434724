import { Slider } from "antd";

const serverUrl = process.env.REACT_APP_API_URL;


const buildProjection = async (newEvents, accessToken, convertUnit, aggPeriod, revenue, selectedProducts, selectedCustomers) => {
    let headers = {
        Authorization: `Bearer ${accessToken}`,
    }
    const response = await fetch(
        `${serverUrl}/product?` + new URLSearchParams({
            convertUnit: convertUnit === undefined ? false : convertUnit,
            aggPeriod: aggPeriod === undefined ? 'MS' : aggPeriod,
            revenue: revenue === undefined ? false : revenue,
        }),
        {
            headers: headers,
            body: JSON.stringify({
                'customerIds': selectedCustomers, 
                'productIds': selectedProducts,
                'newEvents': newEvents
            }),
            method: "POST"
        },
    );

    return await response.json();
};

export {buildProjection};
