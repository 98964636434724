import {Layout, Menu, Result} from "antd";
import React, {useEffect, useState, useRef} from "react";
import {Content} from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import {
    CalendarOutlined,
    CompassOutlined,
    MonitorOutlined,
    ApiOutlined,
    FunctionOutlined, LineChartOutlined, ContainerOutlined, SettingOutlined
} from "@ant-design/icons";
import Loading from "../Loading";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";

const sideColor = '#F5F5F5'

const SettingsContainer = (props) => {
    const {userData, hasError, setHasError} = props;

    const nav = useNavigate();
    const abortControllerRef = useRef(null);

    const [pageMenu, setPageMenu] = useState('order-settings');
    const [products, setProducts] = useState([]);
    const [orderUnit, setOrderUnit] = useState(null);
    const [custConfigs, setCustConfigs] = useState(null);
    const [accountConfig, setAccountConfig] = useState(null);
    const [collapsed, setCollapsed] = useState(false); 

    const {pathname} = useLocation();

    // Set nav state if relevant
    if (pathname.includes('order-settings') && pageMenu !== 'order-settings') {
        setPageMenu('order-settings');
    } else if (pathname.includes('integrations') && pageMenu !== 'integrations') {
        setPageMenu('integrations');
    }

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const fetch = async () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();

        const result = await fetchThreadsData(
            userData.accessToken,
            {'info_type': 'undismissed_count'}, 
            { signal: abortControllerRef.current.signal }
        )

        // TODO: we need better handling of empty defualts
        const defaultConfig = {'FOB' : result['default_cust_config'].FOB ? undefined : 'destination', ...result['default_cust_config'], 'uuid': 'default', 'name': 'default'};

        setCustConfigs({'default': defaultConfig, ...result['cust_configs']});
        setProducts(result['products']);
        setOrderUnit(result['default_po_unit']);
        setAccountConfig(result['account_config']);
    }

    useEffect(() => {
        if (orderUnit === null)
            fetch();
    }, [])
    
    return (
        <Layout>
            <Sider className={"side-menu"} width={150} style={{background: sideColor}} trigger={null}>
                <Menu
                    style={{background: sideColor}}
                    mode="inline"
                    selectedKeys={[pageMenu]}
                    onSelect={({item, key, keyPath, selectedKeys, domEvent}) => {
                        setPageMenu(key)

                        if (key === 'order-settings') {
                            nav('/settings/order-settings');
                        } else if (key === 'integrations') {
                            nav('/settings/integrations');
                        }
                    }}
                    items={[
                        {
                            key: 'order-settings',
                            icon: <SettingOutlined />,
                            label: <Link to="/settings/order-settings">Ordering</Link>,
                        },
                        {
                            key: 'integrations',
                            icon: <ApiOutlined />,
                            label: <Link to="/settings/integrations">Integrations</Link>,
                        },
                    ]}
                />
            </Sider>
            <Content>
                <div className="app-layout-content">
                    <Outlet context={[null, () => {}, products, setProducts, orderUnit, setOrderUnit, custConfigs, accountConfig, setAccountConfig]}/>
                </div>
            </Content>
        </Layout>
    );
}

export default withAuthenticationRequired(SettingsContainer, {
    onRedirecting: () => <Loading style={{paddingTop: 200}}/>,
});
