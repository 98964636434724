import {Button, Table, Col, Modal, Row, Tooltip, Typography, Skeleton} from "antd";
import React, {useEffect, useState, useMemo} from "react";
import {StopOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {useSearchParams} from 'react-router-dom';
import {fetchOrderData} from '../../endpoints/fetchOrderData';
import Loading from "../Loading";
import { currencyFormatter, volumeFormatter } from "../utils";
import { buildTimeKey } from "../utils";

const OrderExplorer = (props) => {
    const {isOpen, setIsOpen, userData, setHasError, customerIds, productIds, trange, aggPeriod} = props;
    const {accessToken} = userData;

    const [pulling, setPulling] = useState(false);
    const [dataSource, setDataSource] = useState(null);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [excludeId, setExcludeId] = useState(null);

    const maxQuantity = useMemo(() => {
        if (dataSource) {
            return Math.max(...dataSource.map(item => item.quantity));
        }
        return 0;
    }, [dataSource]);

    useEffect(() => {
        if (dataSource) {
            const total = dataSource.reduce((sum, item) => sum + item.quantity, 0);
            setTotalQuantity(total);
        }
    }, [dataSource]);

    const fetchData = async () => {
        if (pulling || trange.length === 0) return;

        const rangeStr = trange.map(d => d.toISOString().slice(0, 10)).join(',');

        setPulling(true);
        let projData;
        try {
            projData = await fetchOrderData(accessToken, rangeStr, customerIds, productIds, excludeId);
        } catch (e) {
            setHasError(e);
        }
        console.log(projData)

        if (!projData) return;

        setPulling(false);
        setExcludeId(null);

        setDataSource(projData['tableData']);
    }

    useEffect(() => {
        fetchData();
    }, [trange])

    useEffect(() => {
        if (excludeId === null) return;

        fetchData();
    }, [excludeId])

    if (pulling || trange.length === 0) (<Loading/>);

    const columns = [
        {
            title: 'Order ID',
            dataIndex: 'uid',
            defaultSortOrder: 'ascend',
            key: 'uid',
            sorter: (a, b) => {
                return a.uid.localeCompare(b.uid);
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'right',
            sorter: (a, b) => {
                return new Date(a.date)  - new Date(b.date)
            },
        },
        {
            title: 'Product',
            //defaultSortOrder: 'ascend',
            //sorter: (a, b) => Date.parse(a.start_date) - Date.parse(b.start_date),
            dataIndex: 'product',
            key: 'product',
            align: 'right',
        },
        {
            title: '% of Total',
            key: 'percentOfTotal',
            align: 'right',
            render: (val, row) => {
                const percentage = (row.quantity / totalQuantity) * 100;
                const width = (row.quantity / maxQuantity) * 100;

                const alpha = 0.5;
                return (
                    <div style={{ width: '100%', position: 'relative' }}>
                        <div style={{
                            width: `${width}%`,
                            backgroundColor: `rgb(79, 173, 150, ${alpha})`,
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                        }} />
                        <div style={{ position: 'relative', zIndex: 1 }}>
                            {percentage.toFixed(2)}%
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'right',
            render: (val, row) => volumeFormatter.format(val),
            sorter: (a, b) => {
                return a.quantity - b.quantity
            },
        },
        {
            title: 'Amount',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (val, row) => {
                return currencyFormatter.format(val)
            },
            sorter: (a, b) => {
                return a.price - b.price
            },
        },
        {
            title: 'Order Type',
            key: 'action',
            render: (text, record) => (
                record.excluded ? 
                <Tooltip title="Click to flag as recurring and include this order in automated forecasting (changes to automatic forecast wil be reflected tomorrow)">
                    <Button
                        //icon={<PlusCircleOutlined />}
                        onClick={() => setExcludeId(record.internal_id)}
                    >
                        One-Off
                    </Button>
                </Tooltip> : 
                <Tooltip title="Click to flag as one-off and exclude this order from automated forecasting (changes to automatic forecast wil be reflected tomorrow)">
                    <Button
                        //icon={<StopOutlined />}
                        onClick={() => setExcludeId(record.internal_id)}
                    >
                        Recurring
                    </Button>
                </Tooltip>
            ),
        }
    ]

    return (
        <Modal
            title={trange.length > 0 ? `Actual Order Line Items (${buildTimeKey(trange[0], aggPeriod)})` : 'Actual Order Line Items'}
            open={isOpen}
            onCancel={() => { setIsOpen(false); }}
            width={1000}
            footer={null}
        >
            {pulling ? <Skeleton active={true}/> :
                <div>
                    <Table
                        dataSource={dataSource} 
                        columns={columns}
                        pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ['25', '50', '100'],
                        }}
                    />
                </div>
            }
        </Modal>
    )
}

export default OrderExplorer;