import React from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';

// Initialize highcharts-more
HighchartsMore(Highcharts);
patternFill(Highcharts);


const CombinationChart = ({ options }) => {

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default CombinationChart;