const serverUrl = process.env.REACT_APP_API_URL;


const fetchCustomer = async (selectedCustomers, trange, accessToken, convertUnit, aggPeriod, revenue, updates, selectedProducts, options={}) => {
    // send customerIDs in body to avoid potential http 414 on too many query params if many are selected
    let body = {'customerIds': selectedCustomers, 'updates': updates, 'productIds': selectedProducts}
    let headers = {
        Authorization: `Bearer ${accessToken}`,
    }
    
    const response = await fetch(
        `${serverUrl}/customer_index?` + new URLSearchParams({
            info_type: 'specific_customer',
            trange: trange,
            convertUnit: convertUnit === undefined ? false : convertUnit,
            aggPeriod: aggPeriod === undefined ? 'MS' : aggPeriod,
            revenue: revenue === undefined ? false : revenue,
        }),
        {
            headers: headers,
            body: JSON.stringify(body),
            method: "POST",
            ...options
        },
    );

    if (response.status === 403) {
        throw new Error('Not Found');
    }

    return await response.json();
};

export {fetchCustomer};
