import {LoadingOutlined, DollarCircleOutlined, LineChartOutlined, ToolOutlined, ContainerOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Route} from "react-router-dom";
import {
    Button,
    Col,
    Row,
    Select,
    Table,
    Typography,
    Form,
    Input,
    Popconfirm,
    Tag,
    Popover,
    Badge,
    Space,
    Dropdown, Checkbox
} from "antd";

const colorPrimary = '#4fad96';
const EditableContext = React.createContext(null);

const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};


const EditableCell = ({
                          title,
                          editable,
                          required,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({[dataIndex]: record[dataIndex]});
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={[
                    {
                        required: required,
                        message: `${title} is required.`,
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value)
                                return Promise.reject(new Error("Can't be blank"))
                            if (dataIndex === 'vendor' || dataIndex === 'unit_name')
                                return Promise.resolve();
                            if (!isNaN(value))
                                return Promise.resolve();

                            return Promise.reject(new Error('Numbers only'));
                        },
                    }),
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};


const SkuSettings = (props) => {
    const {dataSource, setDataSource, handleSave, validating} = props;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const defaultColumns = [
        {
            title: 'SKU',
            dataIndex: 'sku',
            fixed: 'left',
            width: 200,
            render: (_, row) => {
                if (row.archived) {
                    return <Typography.Text>{'(' + row.sku + ') ' + row.name} <Tag color={'gold'}>ARCHIVED</Tag></Typography.Text>
                } else {
                    return <Typography.Text>{'(' + row.sku + ') ' + row.name}</Typography.Text>
                }
            },
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                if (a.archived === b.archived)
                    return a.sku > b.sku ? 1 : 0
                else {
                    return !a.archived ? 2 : -1
                }
            }
        },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Entering a source lets Pantry segment by SKU source in the order plan</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><ContainerOutlined/> Source</Typography.Text>
                </Popover>
            ),
            width: 100,
            dataIndex: 'vendor',
            editable: true,
        },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Entering a plural unit name tells Pantry what unit your POs are in (cases, palettes, etc.)</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><ContainerOutlined/> Unit Name</Typography.Text>
                </Popover>
            ),
            width: 100,
            dataIndex: 'unit_name',
            editable: true,
        },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Safety stock tells Pantry how much stock you want in inventory at any given time</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><ToolOutlined/> Safety Stock</Typography.Text>
                </Popover>
            ),
            width: 100,
            dataIndex: 'safety_stock',
            editable: true,
        },
        // {
        //     title: (
        //         <Popover content={<div className={"tablePop"}><Typography.Text>Cost allows Pantry to project costs for a SKU PO unit</Typography.Text></div>} title="What's this for?">
        //             <Typography.Text><DollarCircleOutlined/> Cost</Typography.Text>
        //         </Popover>
        //     ),
        //     width: 100,
        //     render: (val) => formatter.format(val),
        //     dataIndex: 'cost',
        //     editable: true,
        // },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Price allows Pantry to project revenue for a SKU</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><DollarCircleOutlined /> Price</Typography.Text>
                </Popover>
            ),
            width: 100,
            render: (val) => formatter.format(val),
            dataIndex: 'price',
            editable: true,
        },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Lead Time tells Pantry how long (in days) it takes to secure the finished goods for a SKU</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><ToolOutlined/> Lead Time</Typography.Text>
                </Popover>
            ),
            width: 100,
            dataIndex: 'lead_time',
            editable: true,
        },
        {
            title:  (
                <Popover content={<div className={"tablePop"}><Typography.Text>Minimum Order Quantity (MOQ) tells Pantry how small the minimum size of a production run can be for a SKU (e.g. a co-packer minimum order size)</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><ToolOutlined/> MOQ</Typography.Text>
                </Popover>
            ),
            dataIndex: 'moq',
            width: 100,
            editable: true,
        },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Max Shelf-Life tells Pantry how long (in days) a finished SKU unit should sit as inventory. This is used to minimize stock or prevent spoliage.</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><ToolOutlined/> Shelf-Life</Typography.Text>
                </Popover>
            ),
            dataIndex: 'msl',
            editable: true,
            width: 100,
        }
    ];

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const applyEditable = (col) => {
        if (!col.editable) {
            return col;
        }
        if (col.children) {
            col.children = col.children.map((child) => {
                if (!child.editable) {
                    return child;
                }
                return {
                    ...child,
                    onCell: (record) => ({
                        record,
                        required: child.required,
                        editable: child.editable,
                        dataIndex: child.dataIndex,
                        title: child.title,
                        handleSave: (row) => handleSave(row, child.dataIndex),
                    }),
                };
            })
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                required: col.required,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: (row) => handleSave(row, col.dataIndex),
            }),
        };
    }

    const columns = defaultColumns.map(applyEditable);

    return (
        <div>
            <Row>
                <Col flex={'auto'}><Typography.Text style={{fontSize: 18, fontWeight: "bold"}}>These settings are used to generate an order calendar</Typography.Text>
                </Col>
                <Col align={'right'}>
                    {validating &&
                        <Tag  color={colorPrimary}><Typography.Text style={{color: "white"}}><LoadingOutlined/> Loading</Typography.Text></Tag>
                    }
                </Col>
            </Row>
            <br/>
            {/*<Row>*/}
            {/*    <Checkbox>Auto-fill the Order Plan with the "likely case" AI projection</Checkbox>*/}
            {/*</Row>*/}
            {/*<br/>*/}
            {/*<Row>*/}
            {/*    <Checkbox>Warn when a month's Order Plan is 50% higher or lower than typical</Checkbox>*/}
            {/*</Row>*/}
            {/*{ dataSource['meta']['units'] &&*/}
            {/*    <>*/}
            {/*    <Row><Typography.Text style={{fontWeight: 'bold'}}>Units & Conversion</Typography.Text></Row>*/}
            {/*    <Row>*/}
            {/*        <Typography.Text>Help Pantry’s model understand units, and provide alternate units to display information in</Typography.Text>*/}
            {/*    </Row>*/}
            {/*    <Row>*/}
            {/*        <Typography.Text style={{padding: "5px 5px 5px 0"}}>My PO’s are in</Typography.Text>*/}
            {/*        <Input defaultValue={dataSource['meta']['units']['bulk_name']} style={{width: 100}} onPressEnter={(val) => {*/}
            {/*            const newData = {...dataSource};*/}
            {/*            newData['meta']['units']['bulk_name'] = val.target.value;*/}
            {/*            savePlan(newData);*/}
            {/*        }}/>*/}
            {/*    </Row>*/}
            {/*        <br/>*/}
            {/*    <Row>*/}
            {/*        <Typography.Text style={{padding: "5px 5px 5px 0"}}>One {dataSource['meta']['units']['bulk_name']}</Typography.Text>*/}
            {/*        <Typography.Text style={{padding: "5px 5px 5px 5px"}}>=</Typography.Text>*/}
            {/*        <Input defaultValue={dataSource['meta']['units']['bulk_size']} style={{width: 100}} onPressEnter={(val) => {*/}
            {/*            const newData = {...dataSource};*/}
            {/*            newData['meta']['units']['bulk_size'] = parseInt(val.target.value);*/}
            {/*            savePlan(newData);*/}
            {/*        }}/>*/}
            {/*        <Typography.Text style={{padding: "5px 5px 5px 5px"}}>Units</Typography.Text>*/}
            {/*        /!*<Input defaultValue={dataSource['meta']['units']['unit_name']} style={{width: 100}} onPressEnter={(val) => {*!/*/}
            {/*        /!*    const newData = {...dataSource};*!/*/}
            {/*        /!*    newData['meta']['units']['unit_name'] = val.target.value;*!/*/}
            {/*        /!*    savePlan(newData);*!/*/}
            {/*        /!*}}/>*!/*/}
            {/*    </Row>*/}
            {/*    </>*/}
            {/*}*/}
            <br/>
            { dataSource &&
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['25', '50', '100']}}
                    bordered
                    dataSource={dataSource['settings']}
                    columns={columns}
                />
            }
        </div>
    );
}

export default SkuSettings;