import React, {useState, useEffect, useRef, useContext} from "react";
import { buildTimeKey } from "../utils";
import {
    InputNumber,
    Modal,
    Table,
    Tag,
} from "antd";
import { volumeFormatter } from "../utils";

const IgnoreProduct = ({visible, setVisible, savePlan, productId, dispName, setSelectedSku, otherProductSku}) => {
    const handleSave = () => {
        savePlan(
            [{'type': 'product', 'ignored': true, 'sku': productId}], 
            false
        );
        if (otherProductSku !== null) {
          setSelectedSku(otherProductSku);
        }
        setVisible(false);
    }

    return (
      <>
        <Modal
          title="Remove Product"
          open={visible}
          onCancel={() => {setVisible(false)}}
          onOk={() => handleSave()}
          okText={'Save'}
        >
          <p>Are you sure you'd like to mark <Tag>{dispName}</Tag> as an invalid product?</p>          
          <p>This will be removed from Pantry altogether.</p>
        </Modal>
      </>
    );
  };

  
  export default IgnoreProduct;