const serverUrl = process.env.REACT_APP_API_URL;


const fetchOrderData = async (accessToken, trange, selectedCustomers, selectedProducts, excludeId) => {
    // send customerIDs in body to avoid potential http 414 on too many query params if many are selected
    let body = {'customerIds': selectedCustomers, 'productIds': selectedProducts}
    let headers = {
        Authorization: `Bearer ${accessToken}`,
    }

    const response = await fetch(
        `${serverUrl}/save_sku_plan?` + new URLSearchParams({
            info_type: 'order_details',
            excludeId: excludeId,
            trange: trange,
            initial: false,
            convertUnit: false,
            aggPeriod: 'MS',
            revenue: false,
        }),
        {
            headers: headers,
            body: JSON.stringify(body),
            method: "POST"
        },
    );

    if (response.status === 403) {
        throw new Error('Not Found');
    }

    return await response.json();
};

export {fetchOrderData};
