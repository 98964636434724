import React, {useState, useEffect, useRef, useContext} from "react";
import { buildTimeKey } from "../utils";
import {
    InputNumber,
    Modal,
    Table,
    Tag,
} from "antd";
import { volumeFormatter } from "../utils";

const AdjustForecastModal = ({visible, setVisible, forecast, lastYear, savePlan, productId}) => {
    const [formData, setFormData] = useState(generateInitialData(forecast, lastYear));
    const [canSave, setCanSave] = useState(false);

    useEffect(() => {
        setFormData(generateInitialData(forecast, lastYear));
    }, [visible, forecast, lastYear]);

    const handleAdjustForecastChange = (value, key) => {
      const updatedData = formData.map((item) =>
        item.key === key ? { ...item, adjustForecast: value } : item
      );
      const canSave = updatedData.some(item => item.adjustForecast !== null);

      setCanSave(canSave);
      setFormData(updatedData);
    };

    const handleSave = () => {

        savePlan(
            formData.filter((item) => item.adjustForecast !== null).map((item) => {return {
                'sku': productId, 'month': item.month, 'value': item.adjustForecast, 'type': 'demand_plan'
            }}), 
            false
        );

        setVisible(false);
    }
  
    return (
      <>
        <Modal
          title="Manually Adjust Forecast"
          open={visible}
          onCancel={() => {setVisible(false)}}
          onOk={() => handleSave()}
          okButtonProps={{ disabled: !canSave }} 
          okText={'Save'}
        >
          <p>Manually override & lock the baseline forecast for upcoming months</p>
          <Table 
            dataSource={formData} 
            columns={
                [   
                    {
                      title: 'Month',
                      dataIndex: 'month',
                      key: 'month',
                    },
                    {
                      title: 'Forecast',
                      dataIndex: 'forecasted',
                      key: 'forecasted',
                    },
                    {
                      title: 'Last Year Actual',
                      dataIndex: 'lastYear',
                      key: 'lastYear',
                    },
                    {
                      title: 'Adjust Forecast To',
                      dataIndex: 'adjustForecast',
                      key: 'adjustForecast',
                      render: (text, record) => (
                        <InputNumber
                          placeholder={'--'}
                          value={record.adjustForecast}
                          onChange={(value) => handleAdjustForecastChange(value, record.key)}
                        />
                      ),
                    },
                  ]
            } 
            pagination={false} />
          {
            formData.filter((item) => item.adjustForecast !== null).map((item) => 
                <p>Change forecast for <Tag>{item.month}</Tag> from {volumeFormatter.format(item.forecasted)} to {volumeFormatter.format(item.adjustForecast)}</p>
            )
          }
        </Modal>
      </>
    );
  };
  
  const generateInitialData = (forecast, lastYear) => {
    const currentDate = new Date();
    const data = [];
  
    for (let i = 0; i < 6; i++) {
      const month = currentDate.getMonth() + i;
      const year = currentDate.getFullYear() + Math.floor(month / 12);
      const correctMonth = month % 12;
      const timeKey = buildTimeKey(new Date(year, correctMonth), 'MS');
  
      data.push({
        key: i,
        month: timeKey,
        forecasted: forecast[timeKey], // Your forecasted value here
        lastYear: lastYear[timeKey], // Your last year value here
        adjustForecast: null, // Your adjust forecast value here
      });
    }
    return data;
  }
  
export default AdjustForecastModal;