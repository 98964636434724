
import React, {useState, useEffect, useRef, useContext} from "react";
import {Row, Col, Button, Select, Radio, Typography, Card, Statistic, Modal, Skeleton} from 'antd'
import {buildProjection} from "../../../endpoints/projections";
import {LoadingOutlined, DeleteOutlined, EditOutlined, CalendarOutlined, MenuOutlined} from "@ant-design/icons";
import EventList from "./EventList";
import AddEvent from "./AddEvent";
import Loading from "../../Loading";
import { volumeFormatter, nullSafeConcat } from "../../utils";

const colorPrimary = '#4fad96';
const stages = ['Upcoming', 'Low Probability', 'Medium Probability', 'High Probability', 'Confirmed', 'Complete'];
const getStages = (include_complete) => {
    if (include_complete) {
        return stages;
    }
    return stages.filter(s => s !== 'Complete');
}
const stageDescriptions = {
  'Upcoming': 'For organizing upcoming events. Events in this stage will not affect the forecast.',
  'Low Probability': 'Events in this stage will be reflected in the sales forecast proportionally to the stage weight.',
  'Medium Probability': 'Events in this stage will be reflected in the sales forecast proportionally to the stage weight.',
  'High Probability': 'Events in this stage will be reflected in the sales forecast proportionally to the stage weight.',
  'Confirmed': 'Events in this stage will be reflected in the sales forecast proportionally to the stage weight.',
  'Complete': 'For completed events that no longer affect the forecast.'
}
const stageWeights = {
    'Upcoming': 0.0,
    'Low Probability': 0.25,
    'Medium Probability': 0.5,
    'High Probability': 0.75,
    'Confirmed': 1.0,
    'Complete': 1.0
}
const Inputs = (props) => {
    const {userData, setHasError, savePlan, productIds, customerIds, hideCards} = props;
    const {accessToken} = userData;

    const [saving, setSaving] = useState(false);
    const [dataSource, setDataSource] = useState(null);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [prepopulateEvent, setPrepopulateEvent] = useState(null);

    const [localUpdates, setLocalUpdates] = useState([]);
    const [localEvents, setLocalEvents] = useState(null);
    const [filteredEvents, setFilteredEvents] = useState(null);
    const [viewMode, setViewMode] = useState("row");
    const [selectedStages, setSelectedStages] = useState(stages);
    const [isFocused, setIsFocused] = useState(false); // To keep track of focus state
    const [keywords, setKeywords] = useState([]);

    const [volumeSetting, setVolumeSetting] = useState(true);
    const [unit, setUnit] = useState('volume');

    const postEventUpdates = async () => {
        if (saving) return;

        setSaving(true);

        const hadUpdates = localUpdates.length > 0;

        let projData;
        try {
            projData = await buildProjection(
                localUpdates,
                accessToken,
                !volumeSetting,
                'MS',
                unit === 'revenue',
                productIds,
                customerIds
            );
        } catch (e) {
            setHasError(true);
        }

        setSaving(false)
        console.log(projData);

        if (!projData) return;

        // call hook to parent componant to signal parent a refresh of evevent data is needed
        if (savePlan && hadUpdates)
            savePlan([], false);
        
        setDataSource(projData);
    }

    // Initial data fetch
    useEffect(() => {
        if (dataSource === null && !saving) postEventUpdates();
    }, []);

    const handleViewModeChange = e => {
        setViewMode(e.target.value);
    };
    const handleFilterChange = value => {
        setKeywords(value);
    }
    const handleStageChange = (value) => {
        setSelectedStages(value);
    };

    const resetEvents = () => {
        if (dataSource === null) return;

        const listEvents = dataSource['events'].map((pe, i) => {
            // TODO: check this logic
            const skuData = dataSource['products'].find((s) => s.product_id === pe.product_id);
            const productName = skuData.name;

            return {...pe, productName: productName, id: pe.group_id} 
        });

        // merge events into event "groups" by event.group_id
        const listKeysToMerge = ['product_id', 'volume_lift', 'ongoing_add', 'productName'];
        const groupedEventMap = {};

        listEvents.forEach(obj => {
            // If the group_id hasn't been encountered yet, initialize its structure
            if (!groupedEventMap[obj.group_id]) {
                groupedEventMap[obj.group_id] = { group_id: obj.group_id };
        
                // Initialize keys that need to be merged into lists
                for (let key of listKeysToMerge) {
                    groupedEventMap[obj.group_id][key] = [];
                }
            }
        
            // Merge properties
            for (let key in obj) {
                if (listKeysToMerge.includes(key)) {
                    // Handle keys that need to be merged by adding them to their respective lists
                    if (obj[key] !== undefined) {
                        groupedEventMap[obj.group_id][key].push(obj[key]);
                    }
                } else {
                    // Directly merge other properties
                    groupedEventMap[obj.group_id][key] = obj[key];
                }
            }
        });
        
        const events = Object.values(groupedEventMap);  // convert back to list

        const stageEvents = stages.reduce((acc, stage) => {
            acc[stage] = events.filter(event => event.stage === stage);
            return acc;
        }, {});

        // Ensuring every stage has at least an empty array
        stages.forEach(stage => {
          if (!stageEvents[stage]) {
            stageEvents[stage] = [];
          }
        });
        
        setLocalEvents(stageEvents);
    }

    const filterEvents = () => {
        if (!localEvents) return;

        const events = Object.values(localEvents).reduce((acc, curr) => acc.concat(curr), []);

        const stageEvents = stages.reduce((acc, stage) => {
            acc[stage] = events.filter(event => 
              event.stage === stage &&
              selectedStages.includes(event.stage) &&
              (
                keywords.length === 0 || 
                (
                    keywords.every(kw => 
                        (event.note !== null && event.note.toLowerCase().includes(kw.toLowerCase())) || 
                        event.event_type.toLowerCase().includes(kw.toLowerCase()) ||
                        event.productName.toString().toLowerCase().includes(kw.toLowerCase()) ||
                        event.customers.toString().toLowerCase().includes(kw.toLowerCase()) ||
                        (event.owner && event.owner.includes(kw.toLowerCase()))
                    )
                )
            )
            );
            return acc;
        }, {});
    
        // Ensuring every stage has at least an empty array
        stages.forEach(stage => {
            if (!stageEvents[stage]) {
                stageEvents[stage] = [];
            }
        });
        
        setFilteredEvents(stageEvents);
    }

    useEffect(() => { 
        postEventUpdates();
    }, [unit, volumeSetting]);  

    useEffect(() => { 
        // hideCards indicates this is embedded in another page
        // only refresh data if the passed in products/customers change when embedded
        if (!hideCards) return;

        postEventUpdates();
    }, [productIds, customerIds]);  

    useEffect(() => { resetEvents()}, [dataSource]);  
    // filtering of local event store on criteria change
    useEffect(() => { filterEvents()}, [keywords, selectedStages, localEvents]); 

    const onEdit = (record) => {
        setPrepopulateEvent(record ? record : null);
        setIsModalVisible(true);
    };

    useEffect(() => { 
        if (localUpdates.length == 0) return;

        console.log('localupdates')

        postEventUpdates().then(() => {
            setLocalUpdates([]);
        });
    }, [localUpdates]);

    if (!dataSource) return <Loading/>

    const volumeName = dataSource['products'].length > 0  ? (volumeSetting ? dataSource['products'][0]['po_unit'] : dataSource['products'][0]['agg_unit']) : 'units';
    const staticSuffix = (unit === 'revenue' ? '' : volumeName)
    //const rateSuffix = (unit === 'revenue' ? '' : volumeName) + ' / ' + (aggPeriod === 'MS' ? 'month' : (aggPeriod === 'QS' ? 'quarter' : 'week'));
    const rateSuffix = (unit === 'revenue' ? '' : volumeName) + ' / month';
    const ratePrefix = (unit === 'revenue' ? '$' : '');

    return (
            <>
            { dataSource &&
                <AddEvent
                    userData={userData}
                    localEvents={localEvents}
                    setLocalEvents={setLocalEvents}
                    localUpdates={localUpdates}
                    setLocalUpdates={setLocalUpdates}
                    isModalVisible={isModalVisible} 
                    setIsModalVisible={setIsModalVisible}
                    prepopulateEvent={prepopulateEvent}
                    setPrepopulateEvent={setPrepopulateEvent}
                    dataSource={dataSource}
                    volumeSetting={volumeSetting}
                    unit={unit}
                >
                </AddEvent>
            }
            <Row>
                <Col flex={'92px'}>
                    <Radio.Group onChange={handleViewModeChange} value={viewMode} style={{ marginBottom: '20px' }}>
                        <Radio.Button value="row">
                            <MenuOutlined />
                        </Radio.Button>
                        <Radio.Button value="column">
                            <MenuOutlined style={{ transform: "rotate(90deg)" }} />
                        </Radio.Button>
                    </Radio.Group>
                </Col>
                <Col flex={'15px'}>
                </Col>
                <Col flex={'250px'}>
                    { dataSource &&
                        <div>
                            <Select
                                style={{width: '100%'}}
                                optionFilterProp="children"
                                defaultValue={unit === 'revenue' ? 'revenue' : (volumeSetting ? 'volume_unit' : 'volume_case')}
                                onChange={(value, event) => {
                                    if (value === 'volume_unit' || value === 'volume_case') {
                                        setUnit('volume');
                                        if (value === 'volume_unit')
                                            setVolumeSetting(true);
                                        else
                                            setVolumeSetting(false);
                                    } else {
                                        setUnit('revenue');
                                        setVolumeSetting(true);
                                    }
                                }}
                                suffixIcon={saving && <LoadingOutlined style={{color: 'black'}}/>}
                                options={
                                    nullSafeConcat(
                                    [
                                        {label: `Volume (${dataSource['products'].length > 0 ? dataSource['products'][0]['po_unit'] : 'units'})`, value: 'volume_unit', key: 'volume_unit'},
                                    ], dataSource['products'].length > 0 && dataSource['products'][0]['agg_unit'] === null ? null : [
                                        {label: `Volume (${dataSource['products'].length > 0 ? dataSource['products'][0]['agg_unit'] : 'cases'})`, value: 'volume_case', key: 'volume_case'},
                                    ]).concat([
                                        {label: 'Revenue', value: 'revenue', key: 'revenue'},
                                    ])
                                }
                            />
                        </div>
                    }
                </Col>
                <Col flex={'15px'}>
                </Col>
                <Col flex={'254px'}>
                <Select
                    style={{ width: '100%'}}
                    mode={"tags"}
                    value={keywords}
                    placeholder="Search Events (e.g. Promo)"
                    notFoundContent={null}
                    onChange={handleFilterChange}
                >
                    {keywords.map(keyword => (
                        <Select.Option key={keyword}>{keyword}</Select.Option>
                    ))}
                </Select>
                </Col>
                <Col flex={'15px'}>
                </Col>
                <Col flex={'254px'}>
                    <Select
                        style={{ width: '100%', fontSize: 8}}
                        mode={"multiple"}
                        defaultValue={stages}
                        value={isFocused ? selectedStages : 'Selected Stages'} // Show value only when focused
                        placeholder="Select Stage"
                        onChange={handleStageChange}
                        options={stages.map(stage => ({ value: stage, label: stage }))}
                        onBlur={() => setIsFocused(false)}
                        onFocus={() => setIsFocused(true)}
                    />
                </Col>
                <Col flex={'auto'}>
                </Col>
                <Col>
                    <Button type="primary" disabled={!volumeSetting || unit === 'revenue'} onClick={() => onEdit()}>
                        Add New Event
                    </Button>
                </Col>
            </Row>
            { viewMode === 'row' && !hideCards &&
                <>
                <br/> 
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Card bordered={false}>
                            {(saving) && <Skeleton active paragraph={{ rows: 1 }} />}
                            {!(saving) && <Statistic
                                title="Weighted Recurring Business"
                                value={filteredEvents !== null ? Object.values(filteredEvents).reduce((acc, curr) => acc.concat(curr), []).map((e) => e.ongoing_add.reduce((a, b) => a + b, 0) * stageWeights[e.stage]).reduce((partialSum, a) => partialSum + a, 0) : 0}  
                                precision={0} 
                                prefix={ratePrefix}
                                suffix={rateSuffix}
                            />}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                            {(saving) && <Skeleton active paragraph={{ rows: 1 }} />}
                            {!(saving) && <Statistic
                                title="Weighted Initial Order Volume"
                                value={filteredEvents !== null ? Object.values(filteredEvents).reduce((acc, curr) => acc.concat(curr), []).filter((e) => !e.event_type.toLowerCase().includes('promo')).map((e) => e.volume_lift.reduce((a, b) => a + b, 0) * stageWeights[e.stage]).reduce((partialSum, a) => partialSum + a, 0) : 0}  
                                precision={0}
                                prefix={ratePrefix}
                                suffix={staticSuffix}
                            />}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                            {(saving) && <Skeleton active paragraph={{ rows: 1 }} />}
                            {!(saving) && <Statistic
                                title="Weighted Promotional Volume"
                                value={filteredEvents !== null ? Object.values(filteredEvents).reduce((acc, curr) => acc.concat(curr), []).filter((e) => e.event_type.toLowerCase().includes('promo')).map((e) => e.volume_lift.reduce((a, b) => a + b, 0) * stageWeights[e.stage]).reduce((partialSum, a) => partialSum + a, 0) : 0}  
                                precision={0}
                                prefix={ratePrefix}
                                suffix={staticSuffix}
                            />}
                        </Card>
                    </Col>
                </Row>
                <br/>
                </>
            }
            <Row>
                <EventList saving={saving} ratePrefix={ratePrefix} rateSuffix={rateSuffix} staticSuffix={staticSuffix} mode={viewMode} filteredEvents={filteredEvents} localEvents={localEvents} setLocalEvents={setLocalEvents} localUpdates={localUpdates} setLocalUpdates={setLocalUpdates} onEdit={onEdit} aggPeriod={'MS'} volumeSetting={volumeSetting} unit={unit}/>
            </Row>
        </>
    )
}

export {getStages, stages, stageDescriptions, stageWeights};
export default Inputs;