import {
    Button,
    Card,
    Col,
    Divider,
    Statistic,
    Skeleton,
    Modal, Drawer, Input,
    Row,
    Select, Switch,
    Table,
    Dropdown,
    Tag, Tabs, Radio,
    Typography 
} from "antd";
import React, {useState, useEffect, useRef, useContext} from "react";
import {fetchCustomer} from "../../endpoints/customer";
import {LoadingOutlined, ArrowDownOutlined, ArrowUpOutlined, RobotOutlined, DownOutlined, SlidersOutlined, DownloadOutlined} from "@ant-design/icons";
import CombinationChart from "../charts/Chart";
import { DatePicker } from 'antd';
import moment from "moment";
import {currencyFormatter, volumeFormatter, getNextPeriod, parseTimeKey, buildTimeKey, getPeriodStart, exportToExcel, nullSafeConcat} from "../utils";
import {stages, stageWeights} from "./Pipeline/Inputs"
import Inputs from "./Pipeline/Inputs";
import Loading from "../Loading";
import MultiSelect from '../ui/MultiSelect';
import {useSearchParams} from 'react-router-dom';
import OrderExplorer from "../ui/OrderExplorer";

const colorPrimary = '#4fad96';

const CustomerExplorer = (props) => {
    const {userData, setHasError} = props;
    const {accessToken} = userData;

    const [searchParams, setSearchParams] = useSearchParams();

    const [volumeSetting, setVolumeSetting] = useState(true);
    const [aggPeriod, setAggPeriod] = useState('MS');
    const [unit, setUnit] = useState('volume');

    const [customerIds, setCustomerIds] = useState(searchParams.get('ids') ? searchParams.get('ids').split(',').map((id) => parseInt(id)) : []);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [customerOptions, setCustomerOptions] = useState([]);
    const [pulling, setPulling] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [exportData, setExportData] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [stats, setStats] = useState(null);
    const [auomaticForecastVisible, setAuomaticForecastVisible] = useState(false);
    const [auomaticForecastEnabled, setAuomaticForecastEnabled] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [actualsVisible, setActualsVisible] = useState(false);
    const [actualsRange, setActualsRange] = useState([]);

    const [projData, setProjData] = useState(null);
    const [updates, setUpdates] = useState([]);

    const abortControllerRef = useRef(null);

    const sixMonthsAgo = moment().subtract(6, "months").startOf("month");
    const sixMonthsLater = moment().add(6, "months").startOf("month");
    const [trange, setTrange] = useState([sixMonthsAgo, sixMonthsLater]);
    const minX = Date.parse(trange[0]);
    const maxX = Date.parse(trange[1]);

    const [properties, setProperties] = useState({});
    const [propertyValueCounts, setPropertyValueCounts] = useState({});
    const [isChanged, setIsChanged] = useState(false);

    const handleInputChange = (name) => (e) => {
        setProperties(prevProps => ({
          ...prevProps,
          [name]: e.target.value
        }));
        setIsChanged(true);
      };

    const handlePropSave = () => {
        const updatesToApply = Object.keys(properties).map(key => ({
            'action': 'property_update',
            'key': key,
            'value': properties[key],
        })).filter((u) => u.value !== '' && u.value);
        
        setUpdates(updates.concat(updatesToApply));
        setIsChanged(false);
    };

    const getMaxCount = (valueCounts) => {
        return Math.max(...Object.values(valueCounts));
    };

    const aggregatePropertyValues = (props) => {
        // Initialize an object to hold the counts
        const counts = {};
      
        // Iterate over each customer
        props.forEach(customer => {
          // For each property in the customer object, increment the count
          Object.entries(customer).forEach(([i, entry]) => {
            const key = entry.name;
            const value = entry.value !== '' ? entry.value : 'No Value';

            counts[key] = counts[key] || {};
            counts[key][value] = (counts[key][value] || 0) + 1;
          });
        });
      
        return counts;
    };

    const preprocessData = (timeseries, fcast_series, actual_series, products) => {
        let seriesData = [];
        let tableDataList = [];
        let newBusinessSeries = [];
        let discontinuationSeries = [];
        let totalActualSeries = [];
        let totalForecastSeries = [];
        let lastYearSeries = [];
        const currentDate = getPeriodStart(new Date(), aggPeriod);
        
        for (let productId in timeseries) {
            let actualData = [];
            let forecastData = [];

            let productName = productId;
            if (productId !== 'total' && productId !== 'pos_event' && productId !== 'neg_event' && productId !== 'last_year') {
                const productMatch = products.find(entry => entry.product_id === parseInt(productId));
                productName = productMatch ? productMatch.name : 'Unknown Product';
            }
    
            let productTableData = { key: productId, product: productName }; 
    
            let dates = Object.keys(timeseries[productId]).sort((a, b) => parseTimeKey(a, aggPeriod) - parseTimeKey(b, aggPeriod));
            for (let date of dates) {
                let dateObj = parseTimeKey(date, aggPeriod);
                if (dateObj.getTime() >= minX && dateObj.getTime() <= maxX) {
                    let salesVolume = timeseries[productId][date];
                    if (dateObj.getTime() < currentDate) {
                        actualData.push({ x: dateObj.getTime(), y: salesVolume });
                        // add forecast here so we can look back in UI and see how actuals did vs previous pipeline estimates
                        forecastData.push({ x: dateObj.getTime(), y: salesVolume });
                    } else {
                        forecastData.push({ x: dateObj.getTime(), y: salesVolume });
                    }
                    productTableData[date] = salesVolume; // Store each date's volume for the table
                }
            }
    
            if (productId === 'last_year') {
                lastYearSeries.push({
                    name: 'Last Year',
                    data: actualData.concat(forecastData).sort((a, b) => a.x - b.x),
                    color: '#FFA726',
                    type: 'line',
                    zIndex: 2,
                    lineWidth: 1,
                    connectNulls: true,
                });
            } else if (productId === 'pos_event') {
                newBusinessSeries.push({
                    name: 'Pipeline',
                    data: forecastData,
                    color: 'rgb(79, 173, 150)'
                });
            } else if (productId === 'neg_event') {
                discontinuationSeries.push({
                    name: 'Discontinuation',
                    data: forecastData,
                    color: '#A00054'
                });
            } else {
                if (productId !== 'total')
                    tableDataList.push(productTableData);
            }
        }

        Object.keys(actual_series).forEach(date => {
            let dateObj = parseTimeKey(date, aggPeriod);
            if (dateObj.getTime() >= minX && dateObj.getTime() <= maxX) {
                totalActualSeries.push(
                    { x: dateObj.getTime(), y: actual_series[date] },
                );
            }
        });
    
        Object.keys(fcast_series).forEach(date => {
            let dateObj = parseTimeKey(date, aggPeriod);
            if (dateObj.getTime() >= minX && dateObj.getTime() <= maxX) {
                totalForecastSeries.push(
                    { x: dateObj.getTime(), y: fcast_series[date] },
                );
            }
        });
        
        seriesData = newBusinessSeries.concat(
            discontinuationSeries, 
            {
                id: 'Existing Business Forecast',
                name: 'Existing Business Forecast',
                data: totalForecastSeries,
                color: 'rgb(64, 145, 203)',
            },
            lastYearSeries,
            {
                id: 'Actuals',
                name: 'Actuals',
                data: totalActualSeries,
                color: '#FFA726',
            }, 
        );
        
        setTableData(tableDataList); // Update the state with the table data
        setExportData(tableDataList);
        
        return seriesData;
    }

    const fetchProjection = async () => {

        if (abortControllerRef.current && stats !== null) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController
        abortControllerRef.current = new AbortController();    

        setPulling(true);
        let projData;
        let wasAborted;
        try {
            projData = await fetchCustomer(
                customerIds, trange, accessToken, !volumeSetting, aggPeriod, unit === 'revenue', updates, selectedProducts,
                { signal: abortControllerRef.current.signal } 
            );
        } catch (e) {
            if (e.name === 'AbortError') {
                console.log('Fetch aborted');
                wasAborted = true;
            } else {
                setHasError(e);
                wasAborted = false;
            }
        }

        if (!projData) return;
        console.log(projData)
    
        setCustomerOptions(projData['customers']);
        setProducts(projData['products']);

        // clear previous values
        setProperties({});

        if (customerIds.length === 0) {
            setCustomerIds([projData['customers'][0]['id']]);
        }

        if (projData['stats'] !== undefined) {
            setStats(projData['stats']);
            setAuomaticForecastEnabled(projData['props']['auto_forecast']);
            setChartData(preprocessData(projData['timeseries'], projData['fcast_timeseries'], projData['actual_timeseries'], projData['products']));
            setProjData(projData);

            if (customerIds.length === 1) {
                const initialProps = {};

                projData['properties'][0].forEach(prop => {
                    initialProps[prop.name] = prop.value;
                });
                setProperties(initialProps);
            } else {
                const aggregatedValues = aggregatePropertyValues(projData['properties']);
                setPropertyValueCounts(aggregatedValues);
            }
        }
        if (!wasAborted) setPulling(false);
    }

    useEffect(() => {
        // TODO(arlo) encode full page query state in params one day
        if (customerIds.length < 500)
            setSearchParams({...searchParams, ids: customerIds.join(",")})
    }, [customerIds])

    useEffect(() => {
        (async () => {
            if (customerIds.length === 0 && customerOptions.length > 0) 
                // User cleared selections, no need to fetch
                return;

            await fetchProjection();

        })();
    }, [customerIds, selectedProducts, volumeSetting, trange, unit, aggPeriod]);

    useEffect(() => { 
        if (pulling || updates.length == 0) return;

        fetchProjection().then(() => setUpdates([]));
    }, [updates]);

    if (stats === null)
        return <Loading/>

    const volumeName = volumeSetting ? stats['po_unit'] : stats['agg_unit'];
    const staticSuffix = (unit === 'revenue' ? '' : volumeName)
    const timeDenom = (aggPeriod === 'MS' ? 'Month' : (aggPeriod === 'QS' ? 'Quarter' : 'Week')) ;
    const rateSuffix = (unit === 'revenue' ? '' : volumeName) + ' / ' + timeDenom;
    const ratePrefix = (unit === 'revenue' ? '$' : '');

    function onTimePickChange(value, dateString) {
        const [startMonth, endMonth] = dateString;

        if (startMonth === '') {
            const sixMonthsAgo = moment().subtract(6, "months").startOf("month");
            const sixMonthsLater = moment().add(6, "months").startOf("month");
            setTrange([sixMonthsAgo, sixMonthsLater]);
        }
        else
            setTrange([moment(startMonth), moment(endMonth)]);
    }

    function handleColumnClick(event) {
        const column = event.point;
        const periodStart = getPeriodStart(new Date(column.category), aggPeriod);
        const periodEnd = getNextPeriod(periodStart, aggPeriod);

        const seriesName = event.point.series.name;

        if ('Last Year' === seriesName) {
            periodStart.setFullYear(periodStart.getFullYear() - 1);
            periodEnd.setFullYear(periodEnd.getFullYear() - 1);
        }

        if (['Actuals', 'Last Year'].includes(seriesName)) {
            setActualsRange([periodStart, periodEnd]);
            setActualsVisible(true);
        }
    }

    const aggPeriodMapping = {
        'MS': { pointWidth: 20, timeShift: 5 * 24 * 3600 * 1000 }, // for Monthly
        'QS': { pointWidth: 60, timeShift: 15 * 24 * 3600 * 1000 }, // for Quarterly
        'W': { pointWidth: 5, timeShift: 24 * 3600 * 1000 } // for Weekly
    };

    const { pointWidth, timeShift } = aggPeriodMapping[aggPeriod];

    const options = {
        chart: {
            type: 'column'
        },
        title: undefined,
        tooltip: {
            shared: false,
            formatter: function() {
                let message;
                if (unit === 'revenue') {
                    message = `${this.series.name}: ${currencyFormatter.format(this.y.toFixed(2))}</b>`;
                } else {
                    message = `${this.series.name}: ${volumeFormatter.format(this.y.toFixed(2))}</b>`;
                }
                
                if (this.series.name === 'Actuals' || this.series.name === 'Last Year') {
                    return `${message} - Click for more`;
                } 
                
                return message;
            }
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                month: '%b %Y',
            },
            title: {
                text: 'Date'
            },
            min: minX,
            max: maxX,
            plotLines: [{
                color: 'black', // Color of the line
                width: 1, // Width of the line
                value: new Date().getTime(), // Today's date in milliseconds
                zIndex: 1,
                dashStyle: 'dash', // Add this property to make the line dashed
                label: {
                    text: 'Today', // The label text
                    align: 'left', // Alignment of the label
                    y: -5, // Vertical position of the label
                    x: 5, // Horizontal position of the label
                    style: {
                        color: 'black', // Color of the label text
                    },
                },
            }]
        },
        yAxis: {
            title: {
                text: 'Totals'
            },
            stackLabels: {
                enabled: false,
                    style: {
                    fontWeight: 'bold',
                }
            },
            labels: {
                formatter: function() {
                    if (unit === 'revenue') {
                        return currencyFormatter.format(this.value.toFixed(2));
                    } else {
                        return volumeFormatter.format(this.value.toFixed(2));
                    }
                }
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                    dataLabels: {
                    enabled: false,
                },
            },
            series: {
                cursor: 'pointer',
                events: {
                  click: function (event) {
                    handleColumnClick(event);
                  },
                },
            },
        },
        series: [
            //...chartData, // Your actual data series
            { ...chartData[0], pointPadding: 0.0, pointWidth: pointWidth, data: chartData[0].data.map(({x, y}) => [x - timeShift, y]) },
            { ...chartData[1], pointPadding: 0.0, pointWidth: pointWidth, data: chartData[1].data.map(({x, y}) => [x - timeShift, y]) },
            { ...chartData[2], pointPadding: 0.0, pointWidth: pointWidth, data: chartData[2].data.map(({x, y}) => [x - timeShift, y]) },
            chartData[3],
            {...chartData[4], pointPadding: 0.0, pointWidth: pointWidth, data: chartData[4].data.map(({x, y}) => [x + timeShift, y])}
        ],
    }

    const timePeriods = tableData.length > 0 ? Object.keys(tableData[0]).filter(k => k !== 'product' && k !== 'key') : [];

    const columns = [
        {
            title: 'Product',
            dataIndex: 'product',
            width: 200,
            key: 'product',
        },
    ].concat(
        timePeriods.map((m, i) => {
            //const dateStr = buildTimeKey(m, aggPeriod);

            return {
                title: m,
                dataIndex: m,
                key: m,
                align: 'right',
                width: 110,
                className: m === buildTimeKey(getPeriodStart(new Date(), aggPeriod), aggPeriod) ? 'bold-border' : undefined,
                defaultSortOrder: i === 0 ? 'ascend' : undefined,
                sortDirections: ['ascend', 'descend'],
                sorter: (a, b) => {
                    return (isNaN(b[m]) ? 0 : b[m])  - (isNaN(a[m]) ? 0 : a[m])
                },
                render: (val, row) => {
                    // const userEntry = row[mstr + 'user_input']
                    // const base = row[mstr + 'baseline']
                    // const pos = row[mstr + 'positive_events'] ? row[mstr + 'positive_events'] : 0
                    // const neg = row[mstr + 'negative_events'] ? row[mstr + 'negative_events'] : 0

                    var cleaned = isNaN(val) ? 0 : val;
                    if (unit === 'revenue' || unit === 'cost' || unit === 'profit') {
                        cleaned = currencyFormatter.format(cleaned)
                    } else {
                        cleaned = volumeFormatter.format(cleaned)
                    }

                    return cleaned
                }

            }
        })
    );

    const items = [
        {
          key: '1',
          icon: <RobotOutlined />,
          label: 'Adjust Forecast Settings',
        },
        // {
        //   key: '2',
        //   label: (
        //     <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        //       2nd menu item
        //     </a>
        //   ),
        // },
        // {
        //   key: '3',
        //   label: (
        //     <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        //       3rd menu item
        //     </a>
        //   ),
        // },
      ];

    const handleActionsClick = (e) => {
        if (e.key === '1') {
            setAuomaticForecastVisible(true);
        }
    };

    const handleEventSave = async () => {
        // When an event is added, we need to re-fetch data
        await fetchProjection();
    }

    const handleSetAutomaticForecast = (auto_forecast) => {
        setAuomaticForecastEnabled(auto_forecast)
        setUpdates([...updates, {'action': 'update', 'field': 'forecasting_enabled', 'value': auto_forecast}])
    }

    return (
        <div>
            <Modal
                title={`Forecast Settings for ${customerIds.length === 0 ? '' : customerOptions.find((c) => c.id === customerIds[0]).name}`}
                open={auomaticForecastVisible}
                onCancel={() => {
                    setAuomaticForecastVisible(false);
                    setAuomaticForecastEnabled(projData['props']['auto_forecast']);
                }}
                // onOk={() => {
                //     setUpdates([...updates, {'action': 'update', 'field': 'forecasting_enabled', 'value': auomaticForecastEnabled}])
                //     setAuomaticForecastVisible(false);
                // }}
                // okButtonProps={{ disabled: false }}
                // okText={'Save'}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <Row><Typography.Text>AI forecasts are generated nightly based on the latest purchase order data connected with Pantry. By default, customers are omitted as input to AI training models if they have no orders in the last <b>six months</b>.<br></br><br></br>If a customer is no longer active or orders on a case-by-case basis automatic forecasts can be turned off. In this case, use pipeline to add to the forecast. If you would like to adjust a top-line forecast for a product this can be done with a forecast override on the product object.</Typography.Text><Typography.Text/></Row>
                <br/>
                <Row>
                    <Button
                        // icon={<SlidersOutlined />}
                        onClick={() => handleSetAutomaticForecast(null)}
                        ghost={auomaticForecastEnabled === null ? true : false}
                        type={auomaticForecastEnabled === null ? 'primary' : 'default'}
                        block={true}
                    >
                        Default (let Pantry decide whether to generate a forecast)
                    </Button>
                    <Button
                        // icon={<SlidersOutlined />}
                        onClick={() => handleSetAutomaticForecast(true)}
                        ghost={auomaticForecastEnabled === true ? true : false}
                        type={auomaticForecastEnabled === true ? 'primary' : 'default'}
                        block={true}
                    >
                        Generate Auto-Forecast
                    </Button>
                    <Button
                        // icon={<SlidersOutlined />}
                        onClick={() => handleSetAutomaticForecast(false)}
                        ghost={auomaticForecastEnabled === false ? true : false}
                        type={auomaticForecastEnabled === false ? 'primary' : 'default'}
                        block={true}
                    >
                        Don't Generate Auto-Forecast
                    </Button>
                </Row>
            </Modal>
            <OrderExplorer
                isOpen={actualsVisible}
                setIsOpen={setActualsVisible}
                trange={actualsRange}
                aggPeriod={aggPeriod}
                customerIds={customerIds}
                productIds={selectedProducts}
                userData={userData}
            />
            <Row gutter={15}>
                <Col flex={'280px'}>
                    <MultiSelect
                        selected={customerIds}
                        selectOptions={customerOptions}
                        setSelected={setCustomerIds}
                        loading={pulling}
                        objectName={'Customer'}
                    />
                </Col>
                <Col flex={'270px'}>
                    { customerOptions.length > 0 &&
                        <div>
                            <Select
                                style={{width: '100%', maxWidth: '275px'}}
                                optionFilterProp="children"
                                defaultValue={unit === 'revenue' ? 'revenue' : (volumeSetting ? 'volume_unit' : 'volume_case')}
                                onChange={(value, event) => {
                                    if (value === 'volume_unit' || value === 'volume_case') {
                                        setUnit('volume');
                                        if (value === 'volume_unit')
                                            setVolumeSetting(true);
                                        else
                                            setVolumeSetting(false);
                                    } else {
                                        setUnit('revenue');
                                        setVolumeSetting(true);
                                    }
                                }}
                                options={
                                    nullSafeConcat(
                                    [
                                        {label: `Volume (${stats['po_unit']})`, value: 'volume_unit'},
                                    ], stats['agg_unit'] === null ? null : [
                                        {label: `Volume (${stats['agg_unit']})`, value: 'volume_case'},
                                    ]).concat([
                                        {label: 'Revenue', value: 'revenue'},
                                    ])
                                }
                            />
                        </div>
                    }
                </Col>
                <Col flex={'200px'}>
                    <DatePicker.RangePicker
                        format="YYYY-MM"
                        picker="month"
                        allowClear
                        placeholder={[trange[0].format("YYYY-MM"), trange[1].format("YYYY-MM")]}
                        onChange={onTimePickChange}
                    />
                </Col>
                <Col flex='200px'>
                    <Button
                        icon={<SlidersOutlined />}
                        onClick={() => setDrawerVisible(!drawerVisible)}
                        ghost={selectedProducts.length > 0 ? true : false}
                        type={selectedProducts.length > 0 ? 'primary' : 'default'}
                    >
                        Advanced Filters {selectedProducts.length > 0 ? '(1)' : ''}
                    </Button>
                </Col>
                <Col flex={'auto'}>
                </Col>
                <Col flex={'100px'}>
                    { customerIds.length === 1 &&
                        // Customer management only makes sense for a single customer until we allow for bulk edit
                        <Dropdown menu={{items, onClick: handleActionsClick}} placement="bottomLeft">
                            <Button>Actions<DownOutlined/></Button>
                        </Dropdown>
                    }
                </Col>
            </Row>
            <Tabs defaultActiveKey="1" style={{ marginBottom: 32, fontSize: 18}}>
                <Tabs.TabPane tab={<span style={{fontSize:18}}>Reporting</span>} key="1">     
                    <Row gutter={16}>
                    <Col span={8}>
                        <Card bordered={false}>
                        {(pulling || customerIds.length === 0) && <Skeleton active paragraph={{ rows: 1 }} />}
                        {!(pulling || customerIds.length === 0) && <>
                            <Statistic
                                title={`Average Velocity Actual (${stats.velocity_range})`}
                                value={stats.velocity}
                                precision={0}
                                prefix={ratePrefix}
                                suffix={rateSuffix}
                            />
                            <Row>
                            { [stats.velocity_delta * 100].map((val) => {
                                return (
                                <>
                                    <Statistic
                                    value={val}
                                    precision={1}
                                    valueStyle={{ color: val === 0 ? 'black' : val > 0 ? '#3f8600' : '#cf1322', fontSize: '14px' }}
                                    prefix={val === 0 ? '' : val > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                                    suffix="%"
                                    />
                                    <Typography.Text>&nbsp;vs same period last year</Typography.Text>
                                </>
                                )
                            })}
                            </Row>
                        </>}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                        {(pulling || customerIds.length === 0) && <Skeleton active paragraph={{ rows: 1 }} />}
                        {!(pulling || customerIds.length === 0) && <>
                            <Statistic
                                title={`Total Actual (${stats.sum_range})`}
                                value={stats.sum}
                                precision={0}
                                prefix={ratePrefix}
                                suffix={staticSuffix}
                            />
                            <Row>
                            { [stats.sum_delta * 100].map((val) => {
                                return (
                                <>
                                    <Statistic
                                        value={val}
                                        precision={1}
                                        valueStyle={{ color: val === 0 ? 'black' : val > 0 ? '#3f8600' : '#cf1322', fontSize: '14px' }}
                                        prefix={val === 0 ? '' : val > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                                        suffix="%"
                                    />
                                    <Typography.Text>&nbsp;vs same period last year</Typography.Text>
                                </>
                                )
                            })}
                            </Row>
                        </>}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                        {(pulling || customerIds.length === 0) && <Skeleton active paragraph={{ rows: 1 }} />}
                        {!(pulling || customerIds.length === 0) && <>
                            <Statistic
                                title={`Total Forecasted (${stats.sum_range})`}
                                value={stats.fcast}
                                precision={0}
                                prefix={ratePrefix}
                                suffix={staticSuffix}
                            />
                            <Row>
                            { [stats.fcast_delta * 100].map((val) => {
                                return (
                                <>
                                    <Statistic
                                        value={val}
                                        precision={1}
                                        valueStyle={{ fontSize: '14px' }}
                                        suffix="%"
                                    />
                                    <Typography.Text>&nbsp; progress to forecast</Typography.Text>
                                </>
                                )
                            })}
                            </Row>
                        </>}
                        </Card>
                    </Col>
                    </Row>
                    <br/>       
                    <CombinationChart options={options}/>
                    {/* { 
                        (pulling || customerIds.length === 0 ? <Skeleton active paragraph={{ rows: 5 }} /> : <Table
                            dataSource={events} 
                            columns={eventColumns}
                            rowKey="id"
                            pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ['25', '50', '100'],
                            }}
                        />)
                    } */}
                    <br/>
                    <Row>
                        <Col flex={'auto'}>
                            <Typography.Title level={4}>Product Breakdown (Actuals & Forecast)</Typography.Title>
                        </Col>
                        <Col flex={'auto'}>
                        </Col>
                        <Col flex={'100px'}>
                            {exportData && 
                                <Button style={{marginTop: 20}} type={"primary"} onClick={() => exportToExcel(exportData, 'PantryBreakdown')}>
                                    <DownloadOutlined style={{paddingRight: 5}}/>
                                    Export
                                </Button>
                            }
                        </Col>
                    </Row>
                    <div style={{overflow: "auto"}}>
                    {
                        (pulling || customerIds.length === 0) ? <Skeleton active paragraph={{ rows: 5 }} /> : <Table 
                            style={{minWidth: aggPeriod === 'QS' ? 1000 : 2000}} 
                            dataSource={tableData} 
                            columns={columns}
                            pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ['25', '50', '100'],
                                style: {float: 'left'}
                            }}               
                        />
                    }
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span style={{fontSize:18}}>Record</span>} key="2">
                    <Row>
                        <Col span={18}>
                        </Col>
                        <Col span={6}>
                            <Card title='Properties' extra={
                                (isChanged && <Button type='primary' onClick={handlePropSave}>Save</Button>) ||
                                (pulling && <LoadingOutlined/>)
                            }>
                                {
                                    customerIds.length === 1 ? (
                                        Object.entries(properties).map(([key, value]) => (
                                            <Row style={{ marginBottom: '15px' }} key={key}>
                                                {!pulling ? 
                                                    <div>
                                                    <Typography.Text>{key.charAt(0).toUpperCase() + key.slice(1)}</Typography.Text>
                                                    <Input 
                                                        placeholder={`Enter property...`} 
                                                        value={value} 
                                                        disabled={customerIds.length !== 1}
                                                        onChange={handleInputChange(key)}
                                                    /> </div>: <Skeleton.Input active/>
                                                }
                                        </Row>
                                        ))
                                    ) : (
                                        Object.entries(propertyValueCounts).map(([propertyName, valueCounts]) => {
                                            const maxCount = getMaxCount(valueCounts);
                                            
                                            // Create an array of [value, count] pairs and sort it in descending order by count
                                            const sortedValueCounts = Object.entries(valueCounts).sort((a, b) => b[1] - a[1]);
                                      
                                            return (
                                              <div key={propertyName} style={{ display: 'block', marginBottom: '15px' }}>
                                                <Typography.Text strong style={{ display: 'block', marginBottom: '5px' }}>
                                                  {propertyName.charAt(0).toUpperCase() + propertyName.slice(1)}
                                                </Typography.Text>
                                                {!pulling ? 
                                                    <div style={{ marginBottom: '5px' }}>
                                                    <Input
                                                        placeholder={`Bulk edit (${customerIds.length}) properties...`} 
                                                        onChange={handleInputChange(propertyName)}
                                                    /> </div>: <Skeleton.Input active/>
                                                }
                                                {sortedValueCounts.map(([value, count]) => {
                                                  const barWidth = `${(count / maxCount) * 100}%`;
                                                  return (
                                                    <div key={value} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                      <div style={{ width: barWidth, background: colorPrimary, padding: '5px', color: 'white', textAlign: 'left', overflow: 'hidden' }}>
                                                        {value} ({count})
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            );
                                          })
                                    )
                                }
                            </Card>
                        </Col>
                    </Row>
                </Tabs.TabPane>
            </Tabs>
            <Drawer
                title="Advanced Filters"
                placement="right"
                closable={true}
                onClose={() => setDrawerVisible(!drawerVisible)}
                open={drawerVisible}
                width={320}
            >
                <Row>
                    <Radio.Group onChange={(val) => setAggPeriod(val.target.value)} value={aggPeriod}>
                        <Radio.Button value="W">Weekly</Radio.Button>
                        <Radio.Button value="MS">Monthly</Radio.Button>
                        <Radio.Button value="QS">Quarterly</Radio.Button>
                    </Radio.Group>
                </Row>
                <Divider/>
                <Row>
                    <MultiSelect
                        selected={selectedProducts}
                        selectOptions={products.map((p) => {return {...p, id: p.product_id}})}
                        setSelected={setSelectedProducts}
                        loading={pulling}
                        objectName={'Product'}
                    />
                </Row>
            </Drawer>
        </div>
        
    )
}

export default CustomerExplorer;