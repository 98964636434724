import { useAuth0 } from "@auth0/auth0-react"; 
const serverUrl = process.env.REACT_APP_API_URL;


const fetchCustomerIndex = async (trange, accessToken, convertUnit, revenue, groupBy, options = {}) => {
    // send customerIDs in body to avoid potential http 414 on too many query params if many are selected
    let body = {'foo': 'bar',}
    let headers = {
        Authorization: `Bearer ${accessToken}`,
    }
    const response = await fetch(
        `${serverUrl}/customer_index?` + new URLSearchParams({
            trange: trange,
            groupBy: groupBy,
            convertUnit: convertUnit === undefined ? false : convertUnit,
            revenue: revenue === undefined ? false : revenue}),
        {
            headers: headers,
            body: JSON.stringify(body),
            method: "POST",
            ...options
        },
    );

    if (response.status == 403) {
        throw new Error('Not Found');
    }

    return await response.json();
};

export {fetchCustomerIndex};
