import {
    Statistic,
    Card,
    Col,
    Skeleton,
    Tooltip, Radio,
    Row,
    Typography,
    Tag,
    Dropdown,
    Button,
    Space,
    Select,
    DatePicker,
    Table,
} from "antd";
import React, {useState, useEffect, useRef, useContext} from "react";
import {ToolOutlined, ArrowDownOutlined, ArrowUpOutlined, DownOutlined, StopOutlined, WarningOutlined, EditOutlined} from "@ant-design/icons";
import CombinationChart from "../charts/Chart";
import moment from "moment";
import {parseTimeKey, getNextPeriod} from "../utils";
import {stages, stageWeights} from "./Pipeline/Inputs";
import AdjustForecastModal from "./AdjustForecast";
import ArchiveProduct from "./ArchiveProduct";
import ChangeName from "./ChangeName";
import IgnoreProduct from "./IgnoreProduct";
import {currencyFormatter, volumeFormatter, nullSafeConcat} from '../utils'
import OrderExplorer from "../ui/OrderExplorer";

const colorPrimary = '#4fad96';

const SkuExplorer = (props) => {
    const {userData, trange, setTrange, aggPeriod, setAggPeriod, selectedSku, setSelectedSku, dataSource, setSkuSelect, setHasError, validating, savePlan, volumeSetting, setVolumeSetting, unit, setUnit} = props;

    const {accessToken} = userData;
    const [options, setOptions] = useState(null);
    const skuIndex = parseInt(dataSource['plan'].find(entry => entry.sku === selectedSku).key);
    const volumeName = volumeSetting ? dataSource['settings'][0]['po_unit'] : dataSource['settings'][0]['agg_unit'];
    const staticSuffix = (unit === 'revenue' ? '' : volumeName)
    const timeDenom = (aggPeriod === 'MS' ? 'Month' : (aggPeriod === 'QS' ? 'Quarter' : 'Week')) ;
    const rateSuffix = (unit === 'revenue' ? '' : volumeName) + ' / ' + timeDenom;
    const ratePrefix = (unit === 'revenue' ? '$' : '');

    const [adjustVisible, setAdjustVisible] = useState(false);
    const [archiveVisible, setArchiveVisible] = useState(false);
    const [ignoredVisible, setIgnoredVisible] = useState(false);
    const [changeNameVisible, setChangeNameVisible] = useState(false);
    const [actualsVisible, setActualsVisible] = useState(false);
    const [actualsRange, setActualsRange] = useState([]);

    const productIdsForInputs = [parseInt(dataSource['plan'][skuIndex].product_id)];
    const customerIdsForInputs = [];

    const setSku = (value) => {
        setSkuSelect(value);
        setSelectedSku(value);
    }

    const minX = Date.parse(trange[0]);
    const maxX = Date.parse(trange[1]);

    function disabledDate(current) {
        const today = new Date();
        const selectedDate = current.toDate();
        const diff = (today.getFullYear() - selectedDate.getFullYear()) * 12 + (today.getMonth() - selectedDate.getMonth());
        return diff > 18 || diff < -18;
    }

    function onTimePickChange(value, dateString) {
        const [startMonth, endMonth] = dateString;

        if (startMonth === '') {
            const sixMonthsAgo = moment().subtract(6, "months").startOf("month");
            const sixMonthsLater = moment().add(6, "months").startOf("month");
            setTrange([sixMonthsAgo, sixMonthsLater]);
        }
        else
            setTrange([moment(startMonth), moment(endMonth)]);
    }

    const columns = [
        {
            title: 'Event',
            dataIndex: 'note',
            key: 'note',
            render: (note, event_row) => (
              <Tooltip title={note} >
                <div
                  //onClick={() => onEdit(event_row)}
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                   // color: "#0091AE", // Set the text color to blue
                    //textDecoration: 'underline', // Underline the text
                    cursor: 'pointer', // Change cursor to pointer when hovering
                  }}
                >
                  {note}
                </div>
              </Tooltip>
            )
        },
        {
          title: 'Stage',
          dataIndex: 'stage',
          key: 'stage',
          sorter: (a, b) => stages.indexOf(a) >= stages.indexOf(b),
          render: (s) => {
            return s + ((s !== 'Complete') ? (' - ' + stageWeights[s]*100 + '%') : '');
          }
        },
        {
            title: 'Start',
            //defaultSortOrder: 'ascend',
            //sorter: (a, b) => Date.parse(a.start_date) - Date.parse(b.start_date),
            dataIndex: 'start_date',
            key: 'start_date',
            render: (val, row) => {
              return {
                  children: (<Typography.Text>{moment(val).format('MMM D YYYY')}</Typography.Text>)                
                }
            }
        },
        {
            title: 'End',
            dataIndex: 'end_date',
            key: 'end_date',
            render: (val, row) => {
                return {
                    props: {
                        style: {
                        }
                    },
                    children: (<Typography.Text>{val ? moment(val).format('MMM D YYYY') : undefined}</Typography.Text>)                }
            }
        },
        {
            title: 'Initial',
            dataIndex: 'volume_lift',
            key: 'volume_lift',
            render: (val, row) => {
                return {
                    props: {
                        style: {
                            //borderRight: '1px solid',
                            background: val === 0 ? undefined : (val > 0 ? 'rgba(79,173,150,0.56)' : 'rgba(160,0,84,0.55)')
                        }
                    },
    
                    children: (<Typography.Text>{unit !== 'revenue' ? volumeFormatter.format(val) : currencyFormatter.format(val)}</Typography.Text>)
                }
            }
        },
        {
            title: 'Recurring',
            dataIndex: 'ongoing_add',
            key: 'ongoing_add',
            render: (val, row) => {
                return {
                    props: {
                        style: {
                            //borderRight: '1px solid',
                            background: val === 0 ? undefined : (val > 0 ? 'rgba(79,173,150,0.56)' : 'rgba(160,0,84,0.55)')
                        }
                    },
    
                    children: (<Typography.Text>{unit !== 'revenue' ? volumeFormatter.format(val) : currencyFormatter.format(val)}</Typography.Text>)
                }
            }
        },
        // {
        //   title: 'Product',
        //   dataIndex: 'productName',
        //   key: 'productName',
        //   render: (product) => (
        //     <Tag key={product}>
        //         {product}
        //     </Tag>
        //   ),
        // },
        {
          title: 'Customers',
          dataIndex: 'customers',
          key: 'customers',
          width: 300,  // Set the width to 300px
          render: (customers) => (
              <Tooltip title={customers.join(', ')}>
                  <div style={{ maxWidth: '290px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {customers.map((customer, index) => (
                          <span key={index}>
                              <Tag>
                                  {customer ? customer : '(All Customers)'}
                              </Tag>
                          </span>
                      ))}
                  </div>
              </Tooltip>
          ),
      }
    ];

    function handleColumnClick(event) {
        const column = event.point;
        const periodStart = new Date(column.category);
        const periodEnd = getNextPeriod(periodStart, aggPeriod);

        const seriesName = event.point.series.name;

        if ("Last Year" === seriesName) {
            periodStart.setFullYear(periodStart.getFullYear() - 1);
            periodEnd.setFullYear(periodEnd.getFullYear() - 1);
        }

        if (['Actuals', "Last Year" ].includes(seriesName)) {
            setActualsRange([periodStart, periodEnd]);
            setActualsVisible(true);
        }
    }

    useEffect(() => {
        const history = dataSource['history'][skuIndex];
        const futureData = dataSource['forecasts'][skuIndex];
    
        // pulled separately, this has values than can be edited by user in forecasts tab
        const actuals = dataSource['actuals']['row'][skuIndex].map((innerRow) => {
            //const price = dataSource['settings'][0]['price'];
            const mutated = Object.fromEntries(Object.entries(innerRow).filter((kv) => {
                const [k, v] = kv;
    
                return !k.includes('user_input');
            }));
    
            return mutated;
        });
    
        const future = {yupper: futureData[0], ylower: futureData[2], yhat: actuals[0]};
    
        const posEvent = dataSource['actuals']['data'][skuIndex]['pos_event'];
        const negEvent = dataSource['actuals']['data'][skuIndex]['neg_event'];

        const positiveEventContributionSeries = {
            name: 'Pipeline',
            data: Object.entries(posEvent)
                .map(([date, value]) => {
                    //const [year, month] = date.split('-').map(Number);
                    //return [Date.UTC(year, month), parseFloat(value)];
                    return [parseTimeKey(date, aggPeriod), value];
                })
                .sort((a, b) => a[0] - b[0]),
            type: 'column',
            zIndex: 2,
            color: '#4fad96',
            //linkedTo: 'futureBarDataGroup',
            lcolor: '#4fad96' // only for legend
        };
    
        const negativeEventContributionSeries = {
            name: 'Discontinuation',
            data: Object.entries(negEvent)
                .map(([date, value]) => {
                    //const [year, month] = date.split('-').map(Number);
                    //return [Date.UTC(year, month), -1 * parseFloat(value)];
                    return [parseTimeKey(date, aggPeriod), value]
                })
                .sort((a, b) => a[0] - b[0]),
            type: 'column',
            zIndex: 2,
            color: {
                pattern: {
                    path: {
                        d: 'M 0 0 L 10 10 M -1 9 L 9 19 M 9 -1 L 19 9',
                        strokeWidth: 3
                    },
                    width: 10,
                    height: 10,
                    color: '#A00054',
                    opacity: 1
                },
            },
            //linkedTo: 'futureBarDataGroup',
            lcolor: '#A00054' // only for legend
        };
    
        const lastYearData = dataSource['actuals']['data'][skuIndex]['last_year'];
        const lastYear = {
            name: "Last Year",
            data: Object.keys({ ...lastYearData })
                .map(date => {
                    const val = lastYearData[date];
                    return [parseTimeKey(date, aggPeriod).getTime(), parseFloat(val)];
                })
                .filter(dataPoint => !dataPoint.some(value => isNaN(value) || value === 0)) // Exclude data points where the value is 0
                .sort((a, b) => a[0] - b[0]),
            type: 'line',
            zIndex: 2,
            lineWidth: 1,
            connectNulls: true,
            fillOpacity: 0.5,
            color: '#FFA726',
            id: 'lastyear',
        };
    
        const futureBarData = {
            name: 'Existing Business Forecast',
            data: Object.entries(future.yhat)
                .map(([dateKey, yhatValue]) => {
                    const userEdited = dataSource['actuals']['row'][skuIndex][0][dateKey+'user_input'];
                    const posEvent = dataSource['actuals']['data'][skuIndex]['pos_event'][dateKey];
                    const negEvent = dataSource['actuals']['data'][skuIndex]['neg_event'][dateKey];
                    const adjustedYhat = yhatValue + negEvent;

                    return {
                        x: parseTimeKey(dateKey, aggPeriod),
                        y: parseFloat(adjustedYhat),
                        color: userEdited ? 'rgb(191 216 237)' : 'rgba(64,145,203)',
                        userEdited: userEdited
                    };
                })
                .sort((a, b) => a.x - b.x),
            type: 'column',
            zIndex: 1,
            id: 'futureBarDataGroup',
            //color: 'rgba(64,145,203)'
        };
        
        const aggPeriodMapping = {
            'MS': { pointWidth: 20, timeShift: 5 * 24 * 3600 * 1000 }, // for Monthly
            'QS': { pointWidth: 60, timeShift: 15 * 24 * 3600 * 1000 }, // for Quarterly
            'W': { pointWidth: 5, timeShift: 24 * 3600 * 1000 } // for Weekly
        };
    
        const { pointWidth, timeShift } = aggPeriodMapping[aggPeriod];
    
        const updatedChartData = {
            name: 'Actuals',
            data: Object.entries(history.actual)
                .map(([date, volume]) => {
                    return [parseTimeKey(date, aggPeriod).getTime(), parseFloat(volume)];
                })
                .sort((a, b) => a[0] - b[0]),
            type: 'column',
            zIndex: 1,
            color: '#FFA726', // Change color as per requirement
        };
    
        const updatedChartDataWithPlacement = {
            ...updatedChartData,
            pointPadding: 0.0,
            pointWidth: pointWidth,
            data: updatedChartData.data.map(([x, y]) => [x + timeShift, y])
        };
    
        const newOptions = {
            chart: {
                zoomType: 'xy',
            },
            legend: {
                useHTML: true,
                symbolWidth: 0,
                symbolHeight: 0,
                labelFormatter: function () {
                    const vis = this.visible;
                    const grey = '#cccccc';
    
                    if (this.userOptions.id === 'customerGroup') {
                        // return  [...largestCustomersWithColors].map(customer => {
                        //     return `<span style="display:inline-block; width: 12px; height: 12px; margin-top: 1px; margin-left: 5px; border-radius: 50%; background-color: ${vis ? customer.color : grey};"></span>`;
                        // }).join('') + `<span style="color: ${vis ? 'black' : '#cccccc'}"> Customer Actuals</span>`;
                        return `<span style="margin-left: 5px; color: ${vis ? 'black' : '#cccccc'}"> Actuals</span>`;
                    } else if (this.userOptions.id === 'lastyear') {
                        return `<span style="display:inline-block; width: 12px; height: 2px; margin-bottom: 2px; margin-left: 5px; border-radius: 2px; background-color: ${vis ? '#FFA726' : grey};"></span>` + `<span style="margin-left: 5px; color: ${vis ? 'black' : '#cccccc'}"> Last Year</span>`;
                    } else if (this.userOptions.id === 'futureBarDataGroup') {
                        return `<span style="margin-left: 5px; color: ${vis ? 'black' : '#cccccc'};">Existing Business Forecast</span>`;
                        //return `<span style="margin-left: 5px; color: ${vis ? 'black' : '#cccccc'};"> ${this.name}</span>`
                    } else {
                        return `<span style="margin-left: 5px;"> ${this.name}</span>`;
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                min: minX,
                max: maxX,
                plotLines: [{
                    color: 'black', // Color of the line
                    width: 1, // Width of the line
                    value: new Date().getTime(), // Today's date in milliseconds
                    zIndex: 1,
                    dashStyle: 'dash', // Add this property to make the line dashed
                    label: {
                        text: 'Today', // The label text
                        align: 'left', // Alignment of the label
                        y: -5, // Vertical position of the label
                        x: 5, // Horizontal position of the label
                        style: {
                            color: 'black', // Color of the label text
                        },
                    },
                }]
            },
            yAxis: {
                title: {
                    text: unit !== 'revenue' ? `Volume [${!volumeSetting ? 'secondary unit' : 'primary unit' }]` : `Revenue`
                },
                labels: {
                    formatter: function() {
                        if (unit === 'revenue') {
                            return currencyFormatter.format(this.value.toFixed(2));
                        } else {
                            return volumeFormatter.format(this.value.toFixed(2));
                        }
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    grouping: false,
                    groupPadding: 0,
                    pointPadding: 0
                },
                series: {
                    cursor: 'pointer',
                    events: {
                      click: function (event) {
                        handleColumnClick(event);
                      },
                    },
                },
            },
            tooltip: {
                shared: false,
                formatter: function() {
                    let message;
                    if (unit === 'revenue') {
                        message = `${this.series.name}: ${currencyFormatter.format(this.y.toFixed(2))}</b>`;
                    } else {
                        message = `${this.series.name}: ${volumeFormatter.format(this.y.toFixed(2))}</b>`;
                    }

                    if (this.point.userEdited) {
                        return `[User Edited Override] ${this.series.name}: <b>${volumeFormatter.format(this.y.toFixed(2))}</b>`;
                    }
                    
                    if (this.series.name === 'Actuals' || this.series.name === 'Last Year') {
                        return `${message} - Click for more`;
                    } 
                    
                    return message;
                }
            },
            series: [
                { ...positiveEventContributionSeries, pointPadding: 0.0, pointWidth: pointWidth, data: positiveEventContributionSeries.data.map(([x, y]) => [x - timeShift, y]) },
                { ...futureBarData, pointPadding: 0.0, pointWidth: pointWidth, data: futureBarData.data.map(({x, y, color, userEdited}) => ({x: x - timeShift, y, color, userEdited})) },
                lastYear,
                { ...negativeEventContributionSeries, pointPadding: 0.0, pointWidth: pointWidth, data: negativeEventContributionSeries.data.map(([x, y]) => [x - timeShift, y]) },
                updatedChartDataWithPlacement
            ],
        };
        setOptions(newOptions);

    }, [selectedSku, dataSource, trange]);

    const items = [
        {
          key: '1',
          icon: <ToolOutlined />,
          label: 'Adjust Forecast',
        },
        {
          key: '2',
          icon: <WarningOutlined />,
          label: dataSource['plan'][skuIndex].archived ? 'Un-archive Product': 'Archive Product',
        },
        {
          key: '3',
          icon: <StopOutlined />,
          label: 'Remove Product',
        },
        {
            key: '4',
            icon: <EditOutlined/>,
            label: 'Change Product Name'
        }
    ];

    const handleMenuClick = (e) => {
        if (e.key === '1') {
            setAdjustVisible(true);
        } else if (e.key === '2') {
            setArchiveVisible(true);
        } else if (e.key === '3') {
            setIgnoredVisible(true);
        } else if (e.key === '4') {
            setChangeNameVisible(true);
        }
    };

    return (
        <div>
            <Row>
                <Col flex={'270px'}>
                    <Select
                        showSearch
                        style={{width: '100%'}}
                        optionFilterProp="children"
                        value={selectedSku}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value) => setSku(value)}
                        options={dataSource['plan'].map((sku) => {
                            var suffix = (sku['archived'] === true) ? ' (Archived)' : '';
                            return {
                                label: sku['name'] + suffix,
                                value: sku['sku'],
                                key: sku['sku'] 
                            }})}
                    />
                </Col>
                <Col flex={'15px'}>
                </Col>
                <Col flex={'200px'}>
                    { dataSource &&
                        <div>
                            <Select
                                style={{width: '100%'}}
                                optionFilterProp="children"
                                defaultValue={volumeSetting ? 'volume_unit' : 'volume_case'}
                                onChange={(value, event) => {
                                    if (value === 'volume_unit' || value === 'volume_case') {
                                        setUnit('volume');
                                        if (value === 'volume_unit')
                                            setVolumeSetting(true);
                                        else
                                            setVolumeSetting(false);
                                    } else {
                                        setUnit('revenue');
                                        setVolumeSetting(true);
                                    }
                                }}
                                options={
                                    nullSafeConcat(
                                    [
                                        // {label: 'Profit/Loss (Plan)', value: 'profit'},
                                        // {label: 'Cost (Plan)', value: 'cost'},
                                        {label: `Volume (${dataSource['settings'][0]['po_unit']})`, value: 'volume_unit'},
                                    ], dataSource['settings'][0]['agg_unit'] === null ? null : [
                                        {label: `Volume (${dataSource['settings'][0]['agg_unit']})`, value: 'volume_case'},
                                    ]).concat([
                                        {label: 'Revenue', value: 'revenue'},
                                    ])
                                }
                            />
                        </div>
                    }
                </Col>
                <Col flex={'15px'}>
                </Col>
                <Col flex={'254px'}>
                    <Radio.Group onChange={(val) => setAggPeriod(val.target.value)} value={aggPeriod}>
                        <Radio.Button value="W">Weekly</Radio.Button>
                        <Radio.Button value="MS">Monthly</Radio.Button>
                        <Radio.Button value="QS">Quarterly</Radio.Button>
                    </Radio.Group>
                </Col>
                <Col flex={'15px'}>
                </Col>
                <Col flex={'200px'}>
                    <DatePicker.RangePicker
                        format="YYYY-MM"
                        picker="month"
                        allowClear
                        placeholder={[trange[0].format("YYYY-MM"), trange[1].format("YYYY-MM")]}
                        disabledDate={disabledDate}
                        onChange={onTimePickChange}
                    />
                </Col>
                <Col flex={'15px'}>
                </Col>
                <Col flex={'auto'}>
                </Col>
                <Col flex={'100px'}>
                    <Dropdown menu={{items, onClick: handleMenuClick}} placement="bottomLeft">
                        <Button>Actions<DownOutlined/></Button>
                     </Dropdown>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Card bordered={false}>
                    {validating && <Skeleton active paragraph={{ rows: 1 }} />}
                    {!validating && <>
                        <Statistic
                        title={`Average Velocity Actual (${dataSource['settings'][parseInt(dataSource['plan'].find(entry => entry.sku === selectedSku).key)].velocity_range})`}
                        value={dataSource['settings'][parseInt(dataSource['plan'].find(entry => entry.sku === selectedSku).key)].velocity}  
                        precision={0} 
                        prefix={ratePrefix}
                        suffix={rateSuffix}
                        />
                        <Row>
                        { [dataSource['settings'][parseInt(dataSource['plan'].find(entry => entry.sku === selectedSku).key)].velocity_delta * 100].map((val) => {
                            return (
                            <>
                                <Statistic
                                value={val}
                                precision={1}
                                valueStyle={{ color: val === 0 ? 'black' : val > 0 ? '#3f8600' : '#cf1322', fontSize: '14px' }}
                                prefix={val === 0 ? '' : val > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                                suffix="%"
                                />
                                <Typography.Text>&nbsp;vs same period last year</Typography.Text>
                            </>
                            )
                        })}
                        </Row>
                    </>}
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                    {validating && <Skeleton active paragraph={{ rows: 1 }} />}
                    {!validating && <>
                        <Statistic
                        title={`Total Actual (${dataSource['settings'][parseInt(dataSource['plan'].find(entry => entry.sku === selectedSku).key)].velocity_range})`}
                        value={dataSource['settings'][parseInt(dataSource['plan'].find(entry => entry.sku === selectedSku).key)].sum}  
                        precision={0} 
                        prefix={ratePrefix}
                        suffix={staticSuffix}
                        />
                        <Row>
                        { [dataSource['settings'][parseInt(dataSource['plan'].find(entry => entry.sku === selectedSku).key)].sum_delta * 100].map((val) => {
                            return (
                            <>
                                <Statistic
                                value={val}
                                precision={1}
                                valueStyle={{ color: val === 0 ? 'black' : val > 0 ? '#3f8600' : '#cf1322', fontSize: '14px' }}
                                prefix={val === 0 ? '' : val > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                                suffix="%"
                                />
                                <Typography.Text>&nbsp;vs same period last year</Typography.Text>
                            </>
                            )
                        })}
                        </Row>
                    </>}
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                    {validating && <Skeleton active paragraph={{ rows: 1 }} />}
                    {!validating && <>
                        <Statistic
                            title={`Total Forecasted (${dataSource['settings'][parseInt(dataSource['plan'].find(entry => entry.sku === selectedSku).key)].fcast_range})`}
                            value={dataSource['settings'][parseInt(dataSource['plan'].find(entry => entry.sku === selectedSku).key)].fcast}  
                            precision={0} 
                            prefix={ratePrefix}
                            suffix={staticSuffix}
                        />
                        <Row>
                        { [dataSource['settings'][parseInt(dataSource['plan'].find(entry => entry.sku === selectedSku).key)].fcast_delta * 100].map((val) => {
                            return (
                            <>
                                <Statistic
                                    value={val}
                                    precision={1}
                                    valueStyle={{ fontSize: '14px' }}
                                    suffix="%"
                                />
                                <Typography.Text>&nbsp; of forecasted orders confirmed</Typography.Text>
                            </>
                            )
                        })}
                        </Row>
                    </>}
                    </Card>
                </Col>
                </Row>
            <br/>
            {!validating && <CombinationChart options={options}/>}
            {/* { 
                (validating ? <Skeleton active paragraph={{ rows: 10 }} /> : <Table
                    dataSource={dataSource['events'].flat().flat().filter((e) => parseInt(e.product_id) === parseInt(dataSource['plan'][skuIndex].product_id))} 
                    columns={columns}
                    rowKey="id"
                    pagination={{
                    defaultPageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ['25', '50', '100'],
                    }}
                />)
            } */}
            <OrderExplorer
                isOpen={actualsVisible}
                setIsOpen={setActualsVisible}
                trange={actualsRange}
                aggPeriod={aggPeriod}
                customerIds={[]}
                productIds={[parseInt(dataSource['plan'][skuIndex].product_id)]}
                userData={{accessToken: accessToken}}
            />
            <AdjustForecastModal
                visible={adjustVisible}
                setVisible={setAdjustVisible}
                forecast={dataSource['actuals']['data'][skuIndex]['units']}
                lastYear={dataSource['actuals']['data'][skuIndex]['last_year']}
                savePlan={savePlan}
                productId={selectedSku}
            />
            <ArchiveProduct
                visible={archiveVisible}
                setVisible={setArchiveVisible}
                setSelectedSku={setSku}
                savePlan={savePlan}
                productId={selectedSku}
                dispName={dataSource['plan'][skuIndex].name}
                otherProductSku={dataSource['plan'].length > 1 ? (skuIndex !== 0 ? dataSource['plan'][0].sku : dataSource['plan'][1].sku) : null}
                archived={dataSource['plan'][skuIndex].archived}
            />
            <IgnoreProduct
                visible={ignoredVisible}
                setVisible={setIgnoredVisible}
                setSelectedSku={setSku}
                savePlan={savePlan}
                productId={selectedSku}
                dispName={dataSource['plan'][skuIndex].name}
                otherProductSku={dataSource['plan'].length > 1 ? (skuIndex !== 0 ? dataSource['plan'][0].sku : dataSource['plan'][1].sku) : null}
            />
            <ChangeName
                visible={changeNameVisible}
                setVisible={setChangeNameVisible}
                setSelectedSku={setSku}
                savePlan={savePlan}
                productId={selectedSku}
            />
        </div>
    )
}

export default SkuExplorer;