const serverUrl = process.env.REACT_APP_API_URL;

const updateSkuPlan = async (email, toSave, accessToken, initial, convertUnit, aggPeriod, revenue, trange, agg_unit_enabled) => {
    let headers = {
        Authorization: `Bearer ${accessToken}`,
    }
    if (agg_unit_enabled !== null) {
        headers['x-pantry-ai-agg-unit-enabled'] = agg_unit_enabled;
    }
    const response = await fetch(
        `${serverUrl}/save_sku_plan?` + new URLSearchParams({
            initial: initial === undefined ? false : initial,
            convertUnit: convertUnit === undefined ? false : convertUnit,
            aggPeriod: aggPeriod === undefined ? 'MS' : aggPeriod,
            revenue: revenue === undefined ? false : revenue,
            trange: trange
        }),
        {
            headers: headers,
            body: JSON.stringify(toSave),
            method: "POST"
        },
    );

    const responseData = await response.json();
    console.log(responseData)

    return responseData

};

export {updateSkuPlan};