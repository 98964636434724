import { Table, AutoComplete, Row, Input, Col, Spin, Button, Typography, Modal, Select, Tag, Checkbox, Form, Skeleton, Tooltip, Tabs, InputNumber } from 'antd';
import { Link, useNavigation, useLoaderData } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import { DeleteOutlined, SearchOutlined, LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { currencyFormatter, detailedCurrencyFormatter, volumeFormatter } from "../utils";
import Loading from "../Loading";
import { useSearchParams, useOutletContext } from 'react-router-dom';
import dayjs from "dayjs";

const { Text } = Typography;
const { TabPane } = Tabs;

const fobDisplayLabels = {
    destination: 'DAP (Delivery)',
    origin: 'FOB (Pickup)',
};

const unitDisplayLabels = {
    eaches: 'Units',
    cases: 'Cases',
    pallets: 'Pallets',
};

const Settings = (props) => {
    const { userData, setHasError } = props;
    const { accessToken } = userData;

    const [forReviewCount, setForReviewCount, products, setProducts, orderUnit, setOrderUnit, custConfigs, accountConfig, setAccountConfig] = useOutletContext();
    const [filterText, setFilterText] = useState('');
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState(''); 
    const [isFocused, setIsFocused] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingProduct, setEditingProduct] = useState(null);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [updatingProducts, setUpdatingProducts] = useState([]);
    const [customerConfigs, setCustomerConfigs] = useState(null);
    const [automationSettings, setAutomationSettings] = useState(null);
    const [editingCustomerConfig, setEditingCustomerConfig] = useState(null);
    const [isCustomerModalVisible, setIsCustomerModalVisible] = useState(false);
    const [isAddingNewCustomer, setIsAddingNewCustomer] = useState(false);

    const abortControllerRef = useRef(null);
    const searchInputRef = useRef(null);

    useEffect(() => {
        // handle initial config state
        if (customerConfigs === null) setCustomerConfigs(custConfigs)
        if (automationSettings === null) {
            setAutomationSettings(accountConfig) 
        }

    }, [custConfigs, accountConfig]);

    const handleCheckboxChange = async (e) => {
        const newConf = {
            ...automationSettings,
            [e.target.name]: e.target.checked,
        };

        if (e.target.type === 'checkbox') {
            setAutomationSettings(automationSettings => newConf);
        }
        
        const response = await fetchThreadsData(
            accessToken,
            { 'info_type': 'account_config_update', 'entity': newConf },
        )
    };

    const handleSearch = (value) => {
        setOptions([]);
        searchCustomers(value.trim());
    };

    const handleSave = async () => {
        setIsModalVisible(false);
        const copy = editingProduct;

        setUpdatingProducts([...updatingProducts, copy.uuid]);
        const uuid = copy.uuid;
        await fetchThreadsData(
            accessToken,
            { 'info_type': 'product_update', 'entity': copy },
        ).then(() => {
            setUpdatingProducts(updatingProducts.filter(p => p !== uuid));
        });
        setProducts(products.map((p) => p.uuid === uuid ? copy : p));
    };

    const handleDelete = async () => {
        setIsModalVisible(false);
        const copy = editingProduct;

        setUpdatingProducts([...updatingProducts, copy.uuid]);
        const uuid = copy.uuid;
        await fetchThreadsData(
            accessToken,
            { 'info_type': 'product_delete', 'entity': copy },
        ).then(() => {
            setUpdatingProducts(updatingProducts.filter(p => p !== uuid));
        });
        setProducts(products.filter((p) => p.uuid !== uuid));
    };

    const handleAddNewCustomerConfig = () => {
        const newConfig = {
            uuid: null,
            FOB: '',
            terms: '',
            name: '',
        };
        setEditingCustomerConfig(newConfig);
        setIsCustomerModalVisible(true);
        setIsAddingNewCustomer(true);
    };

    const handleEditCustomerConfig = (config) => {
        setEditingCustomerConfig(config);
        setIsCustomerModalVisible(true);
        setIsAddingNewCustomer(false);
    };

    const handleSaveCustomerConfig = async () => {
        setIsCustomerModalVisible(false);
        const copy = { ...editingCustomerConfig };
        const newCustomerConfigs = { ...customerConfigs, [copy.uuid]: { ...copy, isGhost: true } };

        setCustomerConfigs(newCustomerConfigs);
        
        await fetchThreadsData(
            accessToken,
            { 'info_type': 'customer_config_update', 'entity': copy },
        ).then((r) => {
            // Update the state with the new object to force a re-render
            setCustomerConfigs({ ...newCustomerConfigs, [copy.uuid]: { ...copy, isGhost: false, web_store_key: r['web_store_key'] } });
        });
    
        setEditingCustomerConfig(null);
    };
    
    const handleCancelCustomerConfig = () => {
        setIsCustomerModalVisible(false);
        setEditingCustomerConfig(null);
    };
    const filteredCustomerConfigs = Object.values(customerConfigs ? customerConfigs : {}).filter(
        (config) =>
            config.name !== 'default' &&
            (config.name.toLowerCase().includes(filterText.toLowerCase()))
    );

    const handleCaseSizeChange = (e) => {
        setEditingProduct({ ...editingProduct, case_size: e.target.value });
    };

    const handlePalletSizeChange = (e) => {
        setEditingProduct({ ...editingProduct, pallet_size: e.target.value });
    };

    const handleAddNew = () => {
        const newProduct = {
            uuid: crypto.randomUUID(), name: '', keywords: '', web_store: false, isGhost: true,
            case_size: 1, pallet_size: 1, price: 0, archived: false, web_store_key: null,
        };
        setProducts([...products, newProduct]);
        setEditingProduct(newProduct);
        setIsModalVisible(true);
        setIsAddingNew(true);
    };

    const parseKeywords = (keywords) => {
        return keywords.split(/\*|\*/).reduce((acc, item, index) => {
            if (index % 2 === 0) {
                // Plain text between tags
                //acc.push(<span key={`text-${index}`}>{item}</span>);
            } else {
                const attrib = item.split(':')
                // Tag
                acc.push(<><Tag key={`tag-${index}`} color="blue">{attrib[0]}: {attrib[1]}</Tag></>);
            }
            return acc;
        }, []);
    };

    const handleEdit = (product) => {
        setEditingProduct(product);
        setIsModalVisible(true);
        setIsAddingNew(false);
    };

    const searchCustomers = async (value) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        setLoading(true);
        setOptions([]);

        try {
            const results = await fetchThreadsData(
                userData.accessToken,
                { info_type: 'search_customer', text: value },
                { signal: abortControllerRef.current.signal }
            );

            const formattedOptions = results['results'].map((item) => ({
                value: item.id,
                key: item.name,
                label: (
                    <div key={item.id}>
                        <Text>{item.name}</Text>
                    </div>
                ),
            }));

            setOptions(formattedOptions.length ? formattedOptions : [{ value: 'no-results', label: 'No results found', disabled: true }]);
            setIsDropdownOpen(formattedOptions.length > 0);
            setLoading(false);

        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
            } else {
                console.error("Failed to fetch search results:", error);
                setOptions([{ value: 'error', label: (<div>Error fetching results. Please try again.</div>), disabled: true }]);
                setLoading(false);
            }
        }
    };

    const handleNameChange = (e) => {
        setEditingProduct({ ...editingProduct, name: e.target.value });
    };

    const handleKeywordsChange = (e) => {
        setEditingProduct({ ...editingProduct, keywords: e.target.value });
    };

    const filteredProducts = products.filter(
        (product) =>
            product.name.toLowerCase().includes(filterText.toLowerCase()) ||
            product.keywords.toLowerCase().includes(filterText.toLowerCase())
    );

    const isOkButtonEnabled = () => {
        if (!editingCustomerConfig.FOB) {
            return false;
        }
        for (const price of Object.values(editingCustomerConfig.productOverrides || {})) {
            if (price === undefined || price === null || editingCustomerConfig['uuid'] === null) {
                return false;
            }
        }
        return true;
    };

    const handleAddOverride = () => {
        if (products.length > 0) {
            const firstProductUuid = products[0].uuid;
            const newOverrides = {
                ...editingCustomerConfig.productOverrides,
                [firstProductUuid]: { price: 0 } // Use the first product's UUID and a placeholder price of 0
            };
            setEditingCustomerConfig({ ...editingCustomerConfig, productOverrides: newOverrides });
        }
    };

    const handleRemoveOverride = (productId) => {
        const newOverrides = { ...editingCustomerConfig.productOverrides };
        delete newOverrides[productId];
        setEditingCustomerConfig({ ...editingCustomerConfig, productOverrides: newOverrides });
    };

    const customerColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Web Store',
            key: 'web_store',
            render: (_, record) => record.isGhost ? <Skeleton.Input style={{ width: 100 }} active /> : (
                record.web_store ? (
                    <>
                        <Link 
                            to={{
                                pathname: '/order', 
                                search: `?web_store_key=${record.web_store_key}` 
                            }}
                        >
                        Vist Personalized Store</Link>
                    </>
                ) : <Tag>Disabled</Tag>
            ),
        },
        {
            title: (
                <Tooltip title="Incoterms are standard rules that describe method of goods transport">
                    Incoterms <InfoCircleOutlined />
                </Tooltip>
            ),
            dataIndex: 'FOB',
            key: 'FOB',
            render: (text, record) => record.isGhost ? <Skeleton.Input style={{ width: 100 }} active /> : fobDisplayLabels[text],
        },
        {
            title: 'Product Price Overrides',
            key: 'overrides',
            render: (_, record) => record.isGhost ? <Skeleton.Input style={{ width: 100 }} active /> : `(${Object.keys(record.productOverrides || {}).length}) product price overrides`,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => record.isGhost ? <Skeleton.Button style={{ width: 58, height: 32 }} active /> : (
                <Button type="link" onClick={() => handleEditCustomerConfig(record)}>
                    Edit
                </Button>
            ),
        },
    ];

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
        },
        {
            title: 'Is Active',
            dataIndex: 'archived',
            key: 'archived',
            render: (archived, record) => {
                return updatingProducts.includes(record.uuid) ? <Skeleton.Input style={{ width: 100, height: 25 }} active /> : (
                    archived ? <Tag color='yellow'>Inactive</Tag> : <Tag>Active</Tag>
                )
            },
        },
        {
            title: 'Unit of Measure',
            dataIndex: 'enforce_unit',
            key: 'enforce_unit',
            render: (enforce_unit, record) => (
                updatingProducts.includes(record.uuid) ? <Skeleton.Input style={{ width: 100, height: 25 }} active /> : unitDisplayLabels[enforce_unit]
            ),
        },
        {
            title: 'Case Size (Units)',
            dataIndex: 'case_size',
            key: 'case_size',
            render: (case_size, record) => (
                updatingProducts.includes(record.uuid) ? <Skeleton.Input style={{ width: 100, height: 25 }} active /> : case_size
            ),
        },
        {
            title: 'Pallet Size (Cases)',
            dataIndex: 'pallet_size',
            key: 'pallet_size',
            render: (pallet_size, record) => (
                updatingProducts.includes(record.uuid) ? <Skeleton.Input style={{ width: 100, height: 25 }} active /> : pallet_size
            ),
        },
        {
            title: `Price`,
            dataIndex: 'price',
            key: 'price',
            render: (price, record) => (
                updatingProducts.includes(record.uuid) ? <Skeleton.Input style={{ width: 100, height: 25 }} active /> : detailedCurrencyFormatter.format(price)
            ),
        },
        {
            title: 'Attributes',
            dataIndex: 'keywords',
            key: 'keywords',
            render: (keywords, record) => (
                updatingProducts.includes(record.uuid) ? 
                <div style={{ maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Skeleton.Input style={{ width: 400, height: 25 }} active />
                </div> :
                <div style={{ maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {parseKeywords(keywords)}
                </div>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            render: (_, record) => (
                updatingProducts.includes(record.uuid) ? <Skeleton.Button shape="round" active style={{ width: 58, height: 32 }} /> :
                    <Button type="link" onClick={() => handleEdit(record)}>
                        Edit
                    </Button>
            ),
        },
    ];

    if (!orderUnit) return <Loading style={{paddingTop: 200}}/>;

    return (
        <div style={{ overflow: 'auto', height: 'calc(100vh - 58px)' }}>
            <Tabs defaultActiveKey="1" activeKey={activeTabKey} onChange={setActiveTabKey} style={{ padding: '0 15px' }}>
                <TabPane tab="Product Settings" key="1">
                    <Row>
                        <Col flex={'300px'}>
                            <Form.Item
                                style={{ minWidth: 100, maxWidth: 100, paddingLeft: 15 }}
                                label="Export Unit"
                            >
                                <Select
                                    value={orderUnit}
                                    options={[
                                        {
                                            'label': unitDisplayLabels['eaches'],
                                            'value': 'eaches'
                                        },
                                        {
                                            'label': unitDisplayLabels['cases'],
                                            'value': 'cases'
                                        },
                                        {
                                            'label': unitDisplayLabels['pallets'],
                                            'value': 'pallets'
                                        },
                                    ]}
                                    onChange={async (val) => {
                                        setOrderUnit(val);
                                        await fetchThreadsData(
                                            accessToken,
                                            { 'info_type': 'account_update', 'entity': { 'default_po_unit': val } },
                                        );
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col flex='auto' />
                    </Row>
                    <Row justify="end" align="middle" style={{ margin: '15px' }}>
                        <Col flex="auto">
                            <Input
                                placeholder="Filter products..."
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                                prefix={<SearchOutlined />}
                                allowClear
                                style={{ width: '300px' }}
                            />
                        </Col>
                        <Col>
                            <Button type="primary" onClick={handleAddNew} style={{ marginLeft: '10px' }}>
                                Add New
                            </Button>
                        </Col>
                    </Row>
                    <Table style={{ padding: 15 }} columns={columns} dataSource={filteredProducts} rowKey="uuid" />
                    {editingProduct &&
                        <Modal
                            title={isAddingNew ? 'Add New Product' : 'Edit Product'}
                            open={isModalVisible}
                            footer={
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button danger onClick={handleDelete}>
                                        Delete
                                    </Button>
                                    <div>
                                        <Button type="primary" onClick={handleSave}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            }
                            onCancel={() => {
                                setIsModalVisible(false);
                                setEditingProduct(null);
                                if (isAddingNew) {
                                    setProducts(products.filter(p => !p.isGhost));
                                }
                            }}
                        >
                            <Form layout="vertical">
                                <Form.Item label="Name">
                                    <Input placeholder="Product display name" value={editingProduct?.name} onChange={handleNameChange} />
                                </Form.Item>
                                <Form.Item label="Unit of Measure">
                                    <Select
                                        value={editingProduct?.enforce_unit}
                                        onChange={(value) => setEditingProduct({ ...editingProduct, enforce_unit: value })}
                                        options={Object.entries(unitDisplayLabels).map(([value, label]) => ({ label, value }))}
                                    />
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Case Size (Units)">
                                            <Input
                                                type="number"
                                                min={0}
                                                placeholder="Number of units in a case"
                                                value={editingProduct?.case_size}
                                                onChange={handleCaseSizeChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Pallet Size (Cases)">
                                            <Input
                                                type="number"
                                                min={0}
                                                placeholder="Number of cases in a pallet"
                                                value={editingProduct?.pallet_size}
                                                onChange={handlePalletSizeChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label={`Price (${unitDisplayLabels[orderUnit]})`}>
                                            <InputNumber
                                                type="number"
                                                min={0}
                                                placeholder="Product price"
                                                value={editingProduct?.price}
                                                onChange={(e) => setEditingProduct({ ...editingProduct, price: e })}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Is Active">
                                        <Select
                                            value={editingProduct?.archived ? 'inactive' : 'active'}
                                            onChange={(value) => setEditingProduct({ ...editingProduct, archived: value === 'inactive' })}
                                        >
                                            <Select.Option value="active">Active</Select.Option>
                                            <Select.Option value="inactive">Inactive</Select.Option>
                                        </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Attributes (fuzzy AI match)">
                                    <Input.TextArea
                                        placeholder="Product description and keywords e.g. SKU, flavor, size, variant, product line"
                                        value={editingProduct.keywords}
                                        onChange={handleKeywordsChange}
                                        rows={4}
                                    />
                                </Form.Item>
                            </Form>
                        </Modal>
                    }
                </TabPane>
                <TabPane tab="Customer Settings" key="2">
                    <Text strong>Default Customer Configuration</Text>
                    <Table columns={customerColumns} dataSource={customerConfigs ? [customerConfigs.default] : []} rowKey="name" pagination={false} />
                    <br/>
                    <Row>
                        <Col flex="300px">
                            <Text strong style={{ marginTop: '20px'}}>Customer Specific Overrides</Text>
                        </Col>
                        <Col flex='auto'/>
                    </Row>
                    <Row justify="end" align="middle">
                        <Col flex="auto">
                            <Input
                                placeholder="Filter customer configurations..."
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                                prefix={<SearchOutlined />}
                                allowClear
                                style={{ width: '300px' }}
                            />
                        </Col>
                        <Col>
                            <Button type="primary" onClick={handleAddNewCustomerConfig} style={{ marginLeft: '10px' }}>
                                Add New
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col flex={24}>
                            <Table columns={customerColumns} dataSource={filteredCustomerConfigs} rowKey="uuid" />
                        </Col>
                    </Row>
                    {editingCustomerConfig &&
                        <Modal
                            title={isAddingNewCustomer ? 'Add New Customer Config Override' : (editingCustomerConfig.name !== 'default' ? 'Edit Customer Config Override' : 'Edit Default Customer Config')}
                            open={isCustomerModalVisible}
                            onOk={handleSaveCustomerConfig}
                            onCancel={handleCancelCustomerConfig}
                            okButtonProps={{ disabled: !isOkButtonEnabled() }}
                        >
                            <Form layout="vertical">
                                { editingCustomerConfig.name !== 'default' && editingCustomerConfig.uuid === null &&
                                    <AutoComplete
                                        style={{ width: '100%', marginBottom: '15px' }}
                                        options={options}
                                        notFoundContent={loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
                                        value={isFocused ? inputValue : (editingCustomerConfig ? editingCustomerConfig['name'] : '')}
                                        open={isDropdownOpen}
                                        onSelect={(value, option) => {
                                            setEditingCustomerConfig({ ...editingCustomerConfig, uuid: value, name: options.find((r) => r.value === value)?.key })
                                            setInputValue('');
                                            setIsFocused(false);
                                            setIsDropdownOpen(false);
                                        }}
                                        onChange={(value) => {
                                            if (isFocused) {
                                                setInputValue(value);
                                            }
                                        }}
                                        onFocus={() => {
                                            setIsFocused(true);
                                            setIsDropdownOpen(true);
                                        }}
                                        onBlur={() => {
                                            setIsFocused(false);
                                            setIsDropdownOpen(false);
                                        }}
                                    >
                                        <Input.Search
                                            ref={searchInputRef}
                                            placeholder="Search for customer..."
                                            enterButton
                                            size="medium"
                                            loading={loading}
                                            onSearch={handleSearch}
                                        />
                                    </AutoComplete>
                                }
                                { editingCustomerConfig.uuid !== 'default' && editingCustomerConfig.uuid !== null &&
                                    <><Typography.Text>{editingCustomerConfig.name}</Typography.Text><br/><br/></>
                                }
                                { editingCustomerConfig.uuid !== 'default' &&
                                    <Form.Item label="Web Store">
                                        <Checkbox
                                            name="web_store"
                                            checked={editingCustomerConfig.web_store}
                                            onChange={(e) => setEditingCustomerConfig({ ...editingCustomerConfig, web_store: e.target.checked })}
                                        >
                                            Enabled
                                        </Checkbox>
                                    </Form.Item>
                                }
                                <Form.Item label="Incoterm">
                                    <Select
                                        placeholder="Select Incoterm"
                                        value={editingCustomerConfig.FOB}
                                        onChange={(value) => setEditingCustomerConfig({ ...editingCustomerConfig, FOB: value })}
                                        options={[{label: fobDisplayLabels['destination'], value: 'destination'}, {label: fobDisplayLabels['origin'], value: 'origin'}]}
                                    />
                                </Form.Item>
                                { editingCustomerConfig.uuid === 'default' &&
                                    <Typography.Text>
                                        Edit default product prices on the&nbsp; 
                                        <a 
                                            onClick={() => {
                                                handleCancelCustomerConfig();
                                                setActiveTabKey('1');
                                            }} 
                                            style={{ color: '#1677ff', textDecoration: 'underline' }}
                                        >
                                            Product Settings
                                        </a>
                                        &nbsp;tab
                                    </Typography.Text>
                                }
                                { editingCustomerConfig.name !== 'default' &&
                                    <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>Product Price Overrides</div>
                                        <Button type="primary" onClick={handleAddOverride}>Add Price Override</Button>
                                    </div>
                                    <Table
                                        dataSource={Object.entries(editingCustomerConfig.productOverrides || {}).map(([productId, override]) => ({ productId, price: override.price }))}
                                        columns={[
                                            {
                                                title: 'Product',
                                                dataIndex: 'productId',
                                                key: 'productId',
                                                render: (id, record) => (
                                                    <Select
                                                        value={record.isNew ? undefined : id}
                                                        onChange={(value) => {
                                                            const newOverrides = { ...editingCustomerConfig.productOverrides };
                                                            if (record.isNew) {
                                                                delete newOverrides[undefined];
                                                            } else {
                                                                delete newOverrides[id];
                                                            }
                                                            newOverrides[value] = record.price;
                                                            setEditingCustomerConfig({ ...editingCustomerConfig, productOverrides: newOverrides });
                                                        }}
                                                        style={{ width: 340 }}
                                                        placeholder="Select a product"
                                                        options={products.map(product => {return {
                                                            value: product.uuid,
                                                            label: product.name
                                                        }})}
                                                    />
                                                ),
                                            },
                                            {
                                                title: `Override Price (${unitDisplayLabels[orderUnit]})`,
                                                dataIndex: 'price',
                                                key: 'price',
                                                render: (price, record) => (
                                                    <InputNumber
                                                        value={price}
                                                        onChange={(value) => {
                                                            const newOverrides = { ...editingCustomerConfig.productOverrides };
                                                            newOverrides[record.productId] = { price: value }; // Update the price
                                                            setEditingCustomerConfig({ ...editingCustomerConfig, productOverrides: newOverrides });
                                                        }}
                                                        min={0}
                                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                        style={{ width: '100%' }}
                                                    />
                                                ),
                                            },
                                            {
                                                title: 'Action',
                                                key: 'action',
                                                render: (_, record) => (
                                                    <Button danger onClick={() => handleRemoveOverride(record.productId)}><DeleteOutlined /></Button>
                                                ),
                                            },
                                        ]}
                                        rowKey="productId"
                                        pagination={false}
                                    />
                                    </>
                                }
                            </Form>
                        </Modal>
                    }
                </TabPane>
                <TabPane tab="Automation Settings" key="3">
                    {automationSettings && <Form layout="vertical">
                        <Row>
                            <Col flex='500px'>
                                <Text style={{ margin: '15px' }}>AI Auto-Fill</Text>
                                <Form layout="vertical" style={{ margin: '15px' }}>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox
                                                name="financialLineItems"
                                                value={automationSettings ? automationSettings.financialLineItems : null}
                                                defaultChecked={automationSettings ? automationSettings.financialLineItems : null}
                                                onChange={handleCheckboxChange}
                                            >
                                                Financial line items (tax, commissions, charges, etc.)
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col flex='auto' />
                        </Row>
                        {/* <Row>
                            <Col flex='800px'>
                                <Text style={{ margin: '15px' }}>Apply these checks in order approvals</Text>
                                <Form layout="vertical" style={{ margin: '15px' }}>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Checkbox
                                                    name="leadTime"
                                                    checked={autoCreateConditions.leadTime}
                                                    onChange={handleCheckboxChange}
                                                >
                                                    Requested order due date needs at least a lead time of 14 days
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Checkbox
                                                    name="inactiveProduct"
                                                    checked={autoCreateConditions.inactiveProduct}
                                                    onChange={handleCheckboxChange}
                                                >
                                                    Can't place an order for an Inactive product
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Checkbox
                                                    name="listedPrice"
                                                    checked={autoCreateConditions.listedPrice}
                                                    onChange={handleCheckboxChange}
                                                >
                                                    Order product prices must match the price configured for that customer (or the default price if no customer price is configured)
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Checkbox
                                                    name="lineMoq"
                                                    checked={autoCreateConditions.lineMoq}
                                                    onChange={handleCheckboxChange}
                                                >
                                                    Each product line item quantity must be a multiple of{' '}
                                                    <InputNumber
                                                        min={1}
                                                        value={autoCreateConditions.lineMoqValue}
                                                        onChange={(value) => setAutoCreateConditions({ ...autoCreateConditions, lineMoqValue: value })}
                                                        style={{ width: '100px', marginLeft: '5px', marginRight: '5px' }}
                                                    />
                                                    <Select
                                                        value={autoCreateConditions.lineMoqUnit}
                                                        options={[
                                                            {
                                                                'label': unitDisplayLabels['eaches'],
                                                                'value': 'eaches'
                                                            },
                                                            {
                                                                'label': unitDisplayLabels['cases'],
                                                                'value': 'cases'
                                                            },
                                                            {
                                                                'label': unitDisplayLabels['pallets'],
                                                                'value': 'pallets'
                                                            },
                                                        ]}
                                                        onChange={(value) => setAutoCreateConditions({ ...autoCreateConditions, lineMoqUnit: value })}
                                                        style={{ width: '100px' }}
                                                    />
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Checkbox
                                                    name="totalMoq"
                                                    checked={autoCreateConditions.totalMoq}
                                                    onChange={handleCheckboxChange}
                                                >
                                                    An order total quantity must be a multiple of{' '}
                                                    <InputNumber
                                                        min={1}
                                                        value={autoCreateConditions.totalMoqValue}
                                                        onChange={(value) => setAutoCreateConditions({ ...autoCreateConditions, totalMoqValue: value })}
                                                        style={{ width: '100px', marginLeft: '5px', marginRight: '5px' }}
                                                    />
                                                    <Select
                                                        value={autoCreateConditions.totalMoqUnit}
                                                        onChange={(value) => setAutoCreateConditions({ ...autoCreateConditions, totalMoqUnit: value })}
                                                        style={{ width: '100px' }}
                                                    >
                                                        <Select.Option value="cases">cases</Select.Option>
                                                        <Select.Option value="pallets">pallets</Select.Option>
                                                    </Select>
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col flex='auto' />
                        </Row> */}
                    </Form>}
                </TabPane>
            </Tabs>
        </div>
    );
};

export { Settings, fobDisplayLabels, unitDisplayLabels};
