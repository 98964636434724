import React, {useState, useEffect, useRef, useContext} from "react";
import { buildTimeKey } from "../utils";
import {
    InputNumber,
    Modal,
    Table,
    Tag,
} from "antd";
import { volumeFormatter } from "../utils";

const ArchiveProduct = ({visible, setVisible, savePlan, productId, dispName, setSelectedSku, otherProductSku, archived}) => {
    const handleSave = () => {
        savePlan(
            [{'type': 'product', 'archived': !archived, 'sku': productId}], 
            false
        );
        if (otherProductSku !== null) {
          setSelectedSku(otherProductSku);
        }
        setVisible(false);
    }

    return (
      <>
        <Modal
          title="Archive Product"
          open={visible}
          onCancel={() => {setVisible(false)}}
          onOk={() => handleSave()}
          okText={'Save'}
        >
          <p>Are you sure you'd like to {archived ? 'un-': ''}archive <Tag>{dispName}</Tag>?</p>
          <p>This product will be {archived ? 'added to': 'removed from'} AI forecasting.</p>
          <p>{archived ? 'Note that adding a product back to AI forecasting will not be complete until after the nightly re-sync.': ''}</p>
          <p>{archived ? 'The forecast page will show the product again and promos will be calculated, but the AI baseline forecasts will remain zero until the nightly AI update.': ''}</p>
        </Modal>
      </>
    );
  };

  
  export default ArchiveProduct;