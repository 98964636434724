import { useSearchParams, Navigate } from 'react-router-dom';

const WithDefaultParam = ({ children, paramName, defaultValue }) => {
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get(paramName);

  if (!paramValue) {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(paramName, defaultValue);
    const newPath = `${window.location.pathname}?${newSearchParams.toString()}`;
    return <Navigate to={newPath} replace />;
  }

  return children;
};

export default WithDefaultParam;