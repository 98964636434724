import React, { useState, useEffect } from "react";
import { Row, Table, Card, Skeleton, Typography, Tag, Tooltip, Col } from 'antd';
import { DeleteOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {currencyFormatter, volumeFormatter} from '../../utils'
import moment from "moment";
import {stages, stageDescriptions, stageWeights} from './Inputs' 


const colorPrimary = '#4fad96';

const EventList = ({saving, validating, ratePrefix, rateSuffix, staticSuffix, mode, localEvents, filteredEvents, setLocalEvents, localUpdates, setLocalUpdates, onEdit, unit}) => {
  const columns = [
    {
        title: 'Event',
        dataIndex: 'note',
        key: 'note',
        render: (note, event_row) => (
          <Tooltip title={note} >
            <div 
              onClick={() => onEdit(event_row)}
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: "#0091AE", // Set the text color to blue
                textDecoration: 'underline', // Underline the text
                cursor: 'pointer', // Change cursor to pointer when hovering
              }}
            >
              {note}
            </div>
          </Tooltip>
        )
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
      sorter: (a, b) => stages.indexOf(a) >= stages.indexOf(b),
      render: (s) => {
        return s + ((s !== 'Complete') ? (' - ' + stageWeights[s]*100 + '%') : '');
      }
    },
    {
        title: 'Start',
        //defaultSortOrder: 'ascend',
        //sorter: (a, b) => Date.parse(a.start_date) - Date.parse(b.start_date),
        dataIndex: 'start_date',
        key: 'start_date',
        render: (val, row) => {
          return {
              props: {
                  style: {
                  }
              },
              children: (<Typography.Text>{moment(val).format('MMM D YYYY')}</Typography.Text>)                
            }
        }
    },
    {
        title: 'End',
        dataIndex: 'end_date',
        key: 'end_date',
        render: (val, row) => {
            return {
                props: {
                    style: {
                    }
                },
                children: (<Typography.Text>{val ? moment(val).format('MMM D YYYY') : undefined}</Typography.Text>)                }
        }
    },
    {
        title: 'Initial',
        dataIndex: 'volume_lift',
        key: 'volume_lift',
        render: (valList, row) => {
            // sum the values for each product into single value
            const val = valList.reduce((a, b) => a + b, 0);
            return {
                props: {
                    style: {
                        //borderRight: '1px solid',
                        background: val === 0 ? undefined : (val > 0 ? 'rgba(79,173,150,0.56)' : 'rgba(160,0,84,0.55)')
                    }
                },

                children: (<Typography.Text>{unit !== 'revenue' ? volumeFormatter.format(val) : currencyFormatter.format(val)}</Typography.Text>)
            }
        }
    },
    {
        title: 'Recurring',
        dataIndex: 'ongoing_add',
        key: 'ongoing_add',
        render: (valList, row) => {
            // sum the values for each product into single value
            const val = valList.reduce((a, b) => a + b, 0);
            return {
                props: {
                    style: {
                        //borderRight: '1px solid',
                        background: val === 0 ? undefined : (val > 0 ? 'rgba(79,173,150,0.56)' : 'rgba(160,0,84,0.55)')
                    }
                },

                children: (<Typography.Text>{unit !== 'revenue' ? volumeFormatter.format(val) : currencyFormatter.format(val)}</Typography.Text>)
            }
        }
    },
    {
      title: 'Products',
      dataIndex: 'productName',
      key: 'productName',
      render: (products) => (
        <Tooltip title={products.join(', ')}>
            <div style={{ maxWidth: '290px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {products.map((product, index) => (
                    <span key={index}>
                        <Tag>
                            {product}
                        </Tag>
                    </span>
                ))}
            </div>
        </Tooltip>
    ),
    },
    {
      title: 'Customers',
      dataIndex: 'customers',
      key: 'customers',
      width: 300,  // Set the width to 300px
      render: (customers) => (
          <Tooltip title={customers.join(', ')}>
              <div style={{ maxWidth: '290px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {customers.map((customer, index) => (
                      <span key={index}>
                          <Tag>
                              {customer ? customer : '(All Customers)'}
                          </Tag>
                      </span>
                  ))}
              </div>
          </Tooltip>
      ),
  }
  ];

  columns.forEach(column => {
    if (['start_date', 'end_date'].includes(column.dataIndex)) {
      column.sorter = (a, b) => Date.parse(a[column.dataIndex]) - Date.parse(b[column.dataIndex]);
    }
    if (['volume_lift', 'ongoing_add'].includes(column.dataIndex)) {
      column.sorter = (a, b) => a[column.dataIndex].reduce((a, b) => a + b, 0) - b[column.dataIndex].reduce((a, b) => a + b, 0);
    }
  });
  
  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId !== destination.droppableId) {
      const sourceEventList = [...filteredEvents[source.droppableId]];
      const [sourceEvent] = sourceEventList.splice(source.index, 1);
      const newSourceEventList = [...localEvents[source.droppableId]].filter((i) => i.id !== sourceEvent.id)

      const destinationEventList = [...localEvents[destination.droppableId]];
      destinationEventList.splice(destination.index, 0, { ...sourceEvent, stage: destination.droppableId });

      console.log(sourceEventList)
      console.log(destinationEventList)
      setLocalEvents({
        ...localEvents,
        [source.droppableId]: newSourceEventList,
        [destination.droppableId]: destinationEventList,
      });

      // Save for backend sync later
      setLocalUpdates([
        ...localUpdates, 
        {
            action: 'update',
            ongoingAdd: sourceEvent.ongoing_add,
            eventSku: sourceEvent.product_id,
            eventId: sourceEvent.id,
            eventCustomers: sourceEvent.customer_ids,
            eventDateRange: sourceEvent.end_date !== null ? [sourceEvent.start_date, sourceEvent.end_date] : [sourceEvent.start_date],
            eventType: sourceEvent.event_type,
            newCust: null,
            owner: sourceEvent.owner,
            eventNote: sourceEvent.note,
            eventStage: destination.droppableId,
            weight: stageWeights[destination.droppableId],
            eventLift: sourceEvent.volume_lift,
            percentLift: sourceEvent.percentage_lift
        }
      ]);
    } else {
      const reorderedEventList = [...localEvents[source.droppableId]];
      const [reorderedItem] = reorderedEventList.splice(source.index, 1);
      reorderedEventList.splice(destination.index, 0, reorderedItem);
      setLocalEvents({
        ...localEvents,
        [source.droppableId]: reorderedEventList,
      });
    }
  };

  const FooterOverlay = ({stage}) => {
    return (
        <Card 
          style={{
            position: 'absolute',
            maxHeight: 90,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            boxShadow: '0px -1px 1px rgba(0, 0, 0, 0.1)', // Adding drop shadow
            borderTopLeftRadius: '0px',  // Make top-left corner sharp
            borderTopRightRadius: '0px',  // Make top-right corner sharp
            borderBottomLeftRadius: '8px',  // Keep rounded bottom-left corner
            borderBottomRightRadius: '8px'  // Keep rounded bottom-right corner
          }}
          bodyStyle={{padding: "8px"}}
        >
          { (validating || saving) &&
            <Skeleton active />
          }
          { !(validating || saving) &&
            <Row justify="center">
              <Row>
                <Typography.Text style={{fontWeight: 'bold', fontSize: 14}}>Recurring Business:&nbsp;</Typography.Text>
                <Typography.Text style={{fontSize: 14}}>
                    {ratePrefix}{filteredEvents !== null ? volumeFormatter.format(Object.values(filteredEvents).reduce((acc, curr) => acc.concat(curr), []).filter((e) => e.stage === stage).map((e) => e.ongoing_add.reduce((a, b) => a + b, 0)).reduce((partialSum, a) => partialSum + a, 0)): 0} {rateSuffix}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text style={{fontWeight: 'bold', fontSize: 14}}>Initial Order:&nbsp;</Typography.Text>                  
                <Typography.Text style={{fontSize: 14}}>
                    {ratePrefix}{filteredEvents !== null ? volumeFormatter.format(Object.values(filteredEvents).reduce((acc, curr) => acc.concat(curr), []).filter((e) => e.stage === stage && !e.event_type.toLowerCase().includes('promo')).map((e) => e.volume_lift.reduce((a, b) => a + b, 0)).reduce((partialSum, a) => partialSum + a, 0)) : 0} {staticSuffix}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text style={{fontWeight: 'bold', fontSize: 14}}>Promotional Volume:&nbsp;</Typography.Text>                  
                <Typography.Text style={{fontSize: 14}}>
                    {ratePrefix}{filteredEvents !== null ? volumeFormatter.format(Object.values(filteredEvents).reduce((acc, curr) => acc.concat(curr), []).filter((e) => e.stage === stage && e.event_type.toLowerCase().includes('promo')).map((e) => e.volume_lift.reduce((a, b) => a + b, 0)).reduce((partialSum, a) => partialSum + a, 0)) : 0} {staticSuffix}
                </Typography.Text>
              </Row>
            </Row>
          }
        </Card>
    );
  }

return (
    <div style={{width: '100%'}}>
    { mode === 'column' && 
      <DragDropContext onDragEnd={onDragEnd}>
      <div style={{ 
            display: 'flex', 
            flexDirection: 'row', 
            overflowX: 'auto',
      }}>
        {filteredEvents !== null && stages.map(stage => (
          <div style={{ margin: '0 16px', flex: '0 0 320px', width: '100%'}}>
            <Row>
              <Col>
              <Typography.Title level={4} style={{textAlign: 'left', display: 'inline-block', marginRight: '8px', marginTop: 0 }}>
                {stage} {((stage !== 'Complete') ? (' - ' + stageWeights[stage]*100 + '%') : '')}
              </Typography.Title>
              </Col>
              <Col>
              <Tooltip title={stageDescriptions[stage]}>
                <InfoCircleOutlined/>
              </Tooltip>
              </Col>
              <Col flex='auto'></Col>
              <Col>
              <Typography.Title level={4} style={{textAlign: 'left', display: 'inline-block', marginRight: '8px', marginTop: 0 }}>
                ({filteredEvents[stage].length})
              </Typography.Title>
              </Col>
            </Row>
            <div style={{ 
                position: 'relative',
                height: '70vh',  // same height as your Droppable area before
                display: 'flex',
                flexDirection: 'column'
            }}>
              <Droppable droppableId={stage}>
                {(provided, snapshot) => (
                  <div 
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      flex: 1,  // make it occupy the remaining space
                      border: `1px solid ${snapshot.isDraggingOver ? colorPrimary : 'lightgray'}`, 
                      borderRadius: '8px', 
                      padding: '16px',
                      paddingBottom: '80px',
                      overflowY: 'auto'
                    }}
                  >
                    {filteredEvents[stage].map((event, index) => (
                      <Draggable key={event.id} draggableId={event.id} index={index}>
                        {(provided) => (
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: 'none',
                              marginBottom: '8px',  // Adjust spacing between cards
                              ...provided.draggableProps.style,
                            }}
                            bodyStyle={{ position: 'relative', padding: 12}}  // Make sure to set position for absolute positioned children
                            headStyle={{ paddingLeft: 8, minHeight: 32}}
                            title={
                              !validating && <Typography.Text
                                  onClick={() => onEdit(event)}
                                  style={{
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    color: "#0091AE", // Set the text color to blue
                                    textDecoration: 'underline', // Underline the text
                                    cursor: 'pointer', // Change cursor to pointer when hovering
                                  }}
                              >
                                {event.note}
                              </Typography.Text>
                            }
                          >
                            {(saving || validating) &&
                              <Skeleton active paragraph={{ rows: 2 }} />
                            }
                            { !(saving || validating) &&
                              <>                            
                              <Typography.Text style={{ display: 'block' }}>
                                {moment(event.start_date).format('MMM D YYYY')}
                                {event.end_date && ` - ${moment(event.end_date).format('MMM D YYYY')}`}
                              </Typography.Text>
                                <Row>
                                  <Typography.Text style={{ display: 'block', marginRight: '8px' }}>
                                    <b>Initial Volume:</b> {unit !== 'revenue' ? volumeFormatter.format(event.volume_lift.reduce((a, b) => a + b, 0)) : currencyFormatter.format(event.volume_lift.reduce((a, b) => a + b, 0))} {staticSuffix}
                                  </Typography.Text>
                                  <Typography.Text style={{ display: 'block' }}>
                                    <b>Recurring Business:</b> {unit !== 'revenue' ? volumeFormatter.format(event.ongoing_add.reduce((a, b) => a + b, 0)) : currencyFormatter.format(event.ongoing_add.reduce((a, b) => a + b, 0))} {rateSuffix}
                                  </Typography.Text>
                                </Row>
                          
                              <Row style={{maxWidth: '290px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: '2px'}}>
                                <Tooltip title={event.customers.join(', ')}>
                                  <b>Customers: </b>
                                  {event.customers.map((customer, index) => (
                                      <span key={index}>
                                          <Tag>
                                              {customer ? customer : '(All Customers)'}
                                          </Tag>
                                      </span>
                                  ))}
                                </Tooltip>
                              </Row>
                              </>
                            }
                          </Card>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <FooterOverlay stage={stage}/>
              </div>
          </div>
        ))}
      </div>
    </DragDropContext>
  }
    {mode === 'row' && filteredEvents !== null &&
      ((saving || validating) ? <Skeleton active paragraph={{ rows: 10 }} /> : <Table
        dataSource={Object.values(filteredEvents).flat()}
        columns={columns}
        rowKey="id"
        pagination={{
          defaultPageSize: 25,
          showSizeChanger: true,
          pageSizeOptions: ['25', '50', '100'],
        }}
      />)
    }
    </div>
  )
}

export default EventList;
