import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Table, Button, Row, Col, InputNumber, Typography, Card, Tag, Tooltip, DatePicker, Divider, Popover } from 'antd';
import { UserOutlined, DeleteOutlined, CheckCircleTwoTone, WarningTwoTone, ShopOutlined, ShoppingCartOutlined, DollarOutlined, WarningOutlined, InfoCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import Loading from '../Loading';
import { fobDisplayLabels, unitDisplayLabels } from '../Settings/OrderSettings';
import { detailedCurrencyFormatter } from '../utils';
import CustomerShipToModal from './CustomerShipToModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons/faArrowRightFromBracket';
import dayjs from 'dayjs';
import { read } from 'xlsx';

const colorPrimary = '#4fad96';

const statusOptions = [
    { label: 'New', value: 'New', closed: false},
    { label: 'Order Approved', value: 'Order Approved', closed: false},
    { label: 'Pending Order Changes', value: 'Pending Order Changes', closed: false},
    { label: 'Sent to Warehouse', value: 'Sent to Warehouse', closed: false},
    { label: 'Pending Pickup', value: 'Pending Pickup', closed: false},
    { label: 'Picked Up', value: 'Picked Up', closed: false},
    { label: 'Delivered', value: 'Delivered', closed: false},
    { label: 'Pending Cancellation', value: 'Pending Cancellation' },
    { label: 'Invoiced', value: 'Invoiced', closed: true},
    { label: 'Paid', value: 'Paid', closed: true},
    { label: 'Cancelled', value: 'Cancelled', closed: true},
];

const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};


const OrderEntryForm = ({ formState, handleFormChange, handleProductChange, handleAddLineItem, handleDeleteLineItem, workflowState, userData, fullscreen = false, readOnly = false, allowConfigOverride = false, external=false}) => {    

    const formatter = value => {
        const [integer, decimal] = value.toString().split('.');
        return `${integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${decimal ? '.' + decimal : ''}`;
    };
    const lineTotalFormatter = value => {
        const [integer, decimal] = parseFloat(value).toFixed(2).split('.');
        return `${integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${decimal}`;
    };
    const parser = value => value.replace(/\$\s?|(,*)/g, '');
    const getEmailValidationStatus = () => {
        if (formState.BUYER_CONTACT_EMAIL) {
            return isValidEmail(formState.BUYER_CONTACT_EMAIL) ? 'success' : 'error';
        }
        return '';
    };
    
    const [overrideConfig, setOverrideConfig] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCustomerSpecificPricing = (CUSTOMER_MATCHES) => {
        // set new customer-specific prices
        const newLineItens = formState.USER_SELECTED_PRODUCT_LINE_ITEMS.map((item) => {
            if (item.association) {
                const product = workflowState.products.find(p => p.uuid === item.association);
                // customer price if one exists, else default price
                const productPrice = (CUSTOMER_MATCHES.config?.productOverrides ?? {})[product.uuid]?.price ?? product.price;
                return {...item, 'PRODUCT_PRICE': productPrice}
            } else return {}
        });
        
        handleFormChange('USER_SELECTED_PRODUCT_LINE_ITEMS', newLineItens);
    }

    const handleAddNonProductLineItem = () => {
        handleFormChange('NON_PRODUCT_LINE_ITEMS', [...formState.NON_PRODUCT_LINE_ITEMS, {'DESCRIPTION': 'Charge / Discount', 'TOTAL': 0.0}])
    }

    const handleDeleteNonProductLineItem = (index) => {
        handleFormChange('NON_PRODUCT_LINE_ITEMS', [...formState.NON_PRODUCT_LINE_ITEMS.filter((r, i) => i !== index)])
    } 

    const handleNonProductLineItemChange = (index, field, val) => {
        handleFormChange('NON_PRODUCT_LINE_ITEMS', [...formState.NON_PRODUCT_LINE_ITEMS.map((r, i) => {
            if (i === index) {
                return {...r, [field]: val}
            } else return r
        })])
    } 

    const handleModalOk = (result) => {
        handleFormChange('CUSTOMER_MATCHES', result['CUSTOMER_MATCHES']);
        handleFormChange('SHIP_TO_MATCHES', result['SHIP_TO_MATCHES']);
        handleCustomerSpecificPricing(result['CUSTOMER_MATCHES']);
        setIsModalVisible(false);
    };

    const calculateTotal = () => { 
        const newTotal = formState.USER_SELECTED_PRODUCT_LINE_ITEMS.reduce((acc, item) => {
            const price = item.PRODUCT_PRICE || 0;
            const quantity = item.PRODUCT_QUANTITY || 0;
            return acc + (price * quantity);
        }, 0);

        const nonProduct = (formState.NON_PRODUCT_LINE_ITEMS ?? []).reduce((acc, item) => {
            const price = item.TOTAL || 0;
            return acc + price;
        }, 0);
        
        return newTotal + nonProduct;
    };

    useEffect(() => {
        // this updates line item units to the enforced unit on a line item change

        if (!formState.USER_SELECTED_PRODUCT_LINE_ITEMS || readOnly) return;
        
        // This will update product units whenever the product association changes.
        formState.USER_SELECTED_PRODUCT_LINE_ITEMS.forEach((item, index) => {
            if (item.association) {
                const product = workflowState.products.find(p => p.uuid === item.association);
                if (product && product.enforce_unit !== item.PRODUCT_UNIT) {
                    handleProductChange(index, 'PRODUCT_UNIT', product.enforce_unit);
                }
            }
        });

        const newTotal = calculateTotal();
        if (newTotal !== formState.TOTAL_PRICE) {
            handleFormChange('TOTAL_PRICE', newTotal);
        }
    }, [formState.USER_SELECTED_PRODUCT_LINE_ITEMS]);

    if (Object.keys(formState).length === 0)
        return <Loading time={10} style={{paddingTop: readOnly ? 200: 0}}/>
    
    return (
        <Form layout="vertical" initialValues={{ ...formState}}>
            <h2 style={{ fontSize: '16px' }}><ShoppingCartOutlined /> Order Details</h2>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Order #" name="DOCUMENT_ID">
                        <Input disabled={readOnly} value={formState.DOCUMENT_ID} onChange={e => handleFormChange('DOCUMENT_ID', e.target.value)} />
                    </Form.Item>
                </Col>
                { !external &&
                    <Col span={12}>
                        <Form.Item label="Placed On" name="PURCHASE_DATE">
                            <DatePicker
                                disabled={readOnly}
                                value={formState.PURCHASE_DATE ? dayjs(formState.PURCHASE_DATE) : null}
                                onChange={(date, dateString) => {handleFormChange('PURCHASE_DATE', date)}}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </Col>
                }
            </Row>
            <Row gutter={16}>
                { !external && 
                    <>
                    <Col span={12}>
                        <Form.Item label="Status" name="STATUS">
                            <Select
                                disabled={readOnly}
                                value={formState.STATUS}
                                defaultValue={'New'}
                                onChange={value => handleFormChange('STATUS', value)}
                            >
                                {statusOptions.map(option => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.closed ? <Tag color='yellow'>Closed</Tag> : <Tag color='blue'>Open</Tag>} {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Shipped On" name="SHIPPED_ON">
                            <DatePicker
                                disabled={readOnly}
                                value={formState.SHIPPED_ON ? dayjs(formState.SHIPPED_ON) : null}
                                onChange={(date, dateString) => handleFormChange('SHIPPED_ON', date)}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </Col>
                    </>
                }
            </Row>
            { (external || (!formState.PRODUCT_LINE_ITEMS && !(formState.error || formState.DOCUMENT_TYPE_CLASSIFICATION == 'OTHER'))) && <>
                <h2 style={{ fontSize: '16px' }}><UserOutlined /> Contact Details</h2>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Contact Phone" name="BUYER_CONTACT_PHONE">
                            <Input disabled={readOnly} value={formState.BUYER_CONTACT_PHONE} onChange={e => handleFormChange('BUYER_CONTACT_PHONE', e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={external ? [{ required: true, message: 'Please enter a valid email address.' }] : undefined}
                            label="Contact Email"
                            name="BUYER_CONTACT_EMAIL"
                            validateStatus={getEmailValidationStatus()}
                            help={getEmailValidationStatus() === 'error' ? 'Please enter a valid email address.' : ''}
                        >
                            <Input
                                disabled={readOnly}
                                value={formState.BUYER_CONTACT_EMAIL}
                                onChange={e => handleFormChange('BUYER_CONTACT_EMAIL', e.target.value)}
                                placeholder="example@me.com"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Contact Name" name="BUYER_CONTACT_NAME">
                        <Input disabled={readOnly} value={formState.BUYER_CONTACT_NAME} onChange={e => handleFormChange('BUYER_CONTACT_NAME', e.target.value)} />
                </Form.Item>
                </>
            }
            <h2 style={{ fontSize: '16px' }}><ShopOutlined /> Customer & Shipping</h2>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item style={{paddingTop: 4}} label="Due On" name="REQUESTED_OR_DUE_SHIP_DATE">
                        <DatePicker
                            disabled={readOnly}
                            style={{marginTop: 6}}
                            value={formState.REQUESTED_OR_DUE_SHIP_DATE ? dayjs(formState.REQUESTED_OR_DUE_SHIP_DATE) : null}
                            placeholder='ASAP'
                            onChange={(date, dateString) => {handleFormChange('REQUESTED_OR_DUE_SHIP_DATE', date)}}
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    {allowConfigOverride && (
                        <Form.Item
                            label={
                                <Row justify="space-between" align="middle">
                                <Col flex="auto"><Typography.Text>Incoterm (via {formState.FOB_TYPE})</Typography.Text></Col>
                                <Col flex="none">
                                    <Button disabled={readOnly} onClick={() => setOverrideConfig(!overrideConfig)} type="link">
                                        Override
                                    </Button>
                                </Col>
                            </Row>
                            }
                            name="FOB"
                        >
                            <Select
                                placeholder="Select Incoterm"
                                value={formState.FOB}
                                onChange={(value) => {
                                    handleFormChange('FOB', value);
                                    handleFormChange('FOB_TYPE', 'Order Override');
                                }}
                                options={[{ label: fobDisplayLabels['destination'], value: 'destination' }, { label: fobDisplayLabels['origin'], value: 'origin' }]}
                                disabled={!overrideConfig}
                            />
                        </Form.Item>
                    )}
                </Col>
            </Row>

            <Card type="inner" title={null}>
                <Row>
                    <Col flex={'90px'}>
                        <Typography.Text strong>Customer</Typography.Text>
                    </Col>
                    <Col flex={'20px'}>
                        {/* {formState.CUSTOMER_MATCHES.type === 'match' && (
                            <Tag color={colorPrimary}>
                                <FontAwesomeIcon icon={faWandMagicSparkles} /> Match
                            </Tag>
                        )} */}
                        {formState.CUSTOMER_MATCHES?.type === 'new' && (
                            <Tag color="blue">Create New</Tag>
                        )}
                    </Col>
                    <Col flex='auto'/>
                    <Col flex='50px'>
                        <Button disabled={readOnly} onClick={() => setIsModalVisible(true)} type="link">
                            Edit
                        </Button>
                    </Col>
                </Row>
                <Row>
                    {formState.CUSTOMER_MATCHES?.type === 'match' && (
                        <Typography.Text>
                            {formState.CUSTOMER_MATCHES?.name}
                        </Typography.Text>
                    )}
                    {formState.CUSTOMER_MATCHES?.type === 'new' && (
                        <Typography.Text>
                            {formState.CUSTOMER_MATCHES?.name === undefined ? (formState.BUYER_CUSTOMER_ENTITY_NAME !== null ? formState.BUYER_CUSTOMER_ENTITY_NAME : "None Selected") : formState.CUSTOMER_MATCHES?.name}
                        </Typography.Text> 
                    )}
                </Row>
            <br/>
            <Row>
                <Col flex='70px'>
                    <Typography.Text strong>Ship To</Typography.Text>
                </Col>
                <Col flex='20px'>
                    {/* {formState.SHIP_TO_MATCHES.type === 'match' && (
                        <Tag color={colorPrimary}>
                            <FontAwesomeIcon icon={faWandMagicSparkles} /> Match
                        </Tag>
                    )} */}

                    {(formState.SHIP_TO_MATCHES?.type === 'match' && formState.SHIP_TO_MATCHES?.id === null) && 
                        <Tag color="grey">Skip</Tag>
                    }
                    {formState.SHIP_TO_MATCHES?.type === 'new' && 
                        <Tag color="blue">Create New</Tag>
                    }
                </Col>
                <Col flex='auto'/>
            </Row>
            <Row>
                {(formState.SHIP_TO_MATCHES?.type === 'match' && formState.SHIP_TO_MATCHES?.id !== null) && (
                    <Typography.Text>
                        {formState.SHIP_TO_MATCHES?.name}
                    </Typography.Text>
                )}
                {formState.SHIP_TO_MATCHES?.type === 'new' && (
                    formState.SHIP_TO_MATCHES?.name === undefined ? (
                        <>
                        <Typography.Text>
                            {formState.SHIP_TO_RECIEVING_NAME}
                        </Typography.Text>     
                        <Typography.Text>
                            {formState.SHIP_TO_RECIEVING_ADDRESS}
                        </Typography.Text>          
                        </>
                    ) : (
                        <Typography.Text>
                            {formState.SHIP_TO_MATCHES?.name}
                        </Typography.Text>       
                    )
                )}
            </Row>
            </Card>
            <h2 style={{ fontSize: '16px' }}><DollarOutlined /> Line Item Details ({formState.USER_SELECTED_PRODUCT_LINE_ITEMS.length} product line items)</h2>
            {formState.USER_SELECTED_PRODUCT_LINE_ITEMS && (
                <Table
                    dataSource={formState.USER_SELECTED_PRODUCT_LINE_ITEMS}
                    style={{width: '100%'}}
                    columns={[
                        {
                            title: 'Product',
                            dataIndex: 'association',
                            key: 'association',
                            width: '70%',
                            render: (uuid, record, index) => {
                                const recordProduct = workflowState.products.find(p => p.uuid === uuid);
                                return (
                                    <div>
                                        {formState.PRODUCT_LINE_ITEMS && <Popover content={
                                            <>
                                                {recordProduct && (index >= formState.PRODUCT_LINE_ITEMS.length || recordProduct.uuid !== formState.PRODUCT_LINE_ITEMS[index][1]['id']) && <Row><b>Product Selection:&nbsp;</b> {recordProduct?.name}</Row>}
                                                {recordProduct && index < formState.PRODUCT_LINE_ITEMS.length && recordProduct.uuid === formState.PRODUCT_LINE_ITEMS[index][1]['id'] && (!formState.PRODUCT_LINE_ITEMS[index][1]['under_threshold'] && !formState.PRODUCT_LINE_ITEMS[index][0]['quantity_warning']) && <Row>
                                                        <CheckCircleTwoTone twoToneColor='#52c41a' style={{paddingRight: 5}}/>
                                                        <Typography.Text strong>AI Match</Typography.Text>
                                                    </Row>
                                                }
                                                {recordProduct && index < formState.PRODUCT_LINE_ITEMS.length && recordProduct.uuid === formState.PRODUCT_LINE_ITEMS[index][1]['id'] && formState.PRODUCT_LINE_ITEMS[index][1]['under_threshold'] && <Row>
                                                    <WarningTwoTone twoToneColor="#FFD666" style={{paddingRight: 5}}/>
                                                    <Typography.Text strong>Needs Attention (uncertain product)</Typography.Text>
                                                    </Row>
                                                }
                                                {recordProduct && index < formState.PRODUCT_LINE_ITEMS.length && recordProduct.uuid === formState.PRODUCT_LINE_ITEMS[index][1]['id'] && formState.PRODUCT_LINE_ITEMS[index][0]['quantity_warning'] && <Row>
                                                    <WarningTwoTone twoToneColor="#FFD666" style={{paddingRight: 5}}/>
                                                    <Typography.Text strong>Needs Attention (uncertain quantity)</Typography.Text>
                                                    </Row>
                                                }
                                                <Row style={{paddingTop: 15}}>
                                                    <b>Original Line Item:&nbsp;</b>
                                                </Row>
                                                <div style={{paddingLeft: 15}}>
                                                    {record?.PRODUCT_DESCRIPTION && <Row>
                                                        <Typography.Text>Description: {record?.PRODUCT_DESCRIPTION}</Typography.Text>
                                                    </Row>}
                                                    {record?.original_quantity && <Row>
                                                        <Typography.Text>Quantity: {record?.original_quantity ?? 'N/A'}</Typography.Text>
                                                    </Row>}
                                                    {record?.PRODUCT_CODES && (record?.PRODUCT_CODES ?? []).length > 0 && (
                                                        <Row>
                                                            <Typography.Text>
                                                            Codes:
                                                            <ul>
                                                                {Array.isArray(record.PRODUCT_CODES) 
                                                                ? record.PRODUCT_CODES.map((code, index) => (
                                                                    <li key={index}>{code}</li>
                                                                    ))
                                                                : <li>{record.PRODUCT_CODES}</li>
                                                                }
                                                            </ul>
                                                            </Typography.Text>
                                                        </Row>
                                                    )}
                                                </div>
                                            </>
                                        }>
                                            {
                                                index < formState.PRODUCT_LINE_ITEMS.length && (formState.PRODUCT_LINE_ITEMS[index][1]['under_threshold'] || formState.PRODUCT_LINE_ITEMS[index][0]['quantity_warning']) ? <Tag color='yellow'><WarningOutlined/></Tag> : <Tag><InfoCircleOutlined/></Tag>
                                            }
                                        </Popover>}
                                        {!formState.PRODUCT_LINE_ITEMS && formState.USER_SELECTED_PRODUCT_LINE_ITEMS && <Popover content={
                                            <>
                                                <Row><b>Product Selection:&nbsp;</b> {recordProduct?.name}</Row>
                                                <Row></Row>
                                            </>
                                        }>
                                            <Tag><InfoCircleOutlined/></Tag>
                                        </Popover>}
                                        <Select
                                            disabled={readOnly}
                                            showSearch
                                            style={{width: !fullscreen ? 210 : '90%'}}
                                            filterOption={(input, option) => {
                                                return option.label.props.children[1].toLowerCase().includes(input.toLowerCase())
                                            }}
                                            defaultValue={uuid}
                                            onChange={value => {
                                                handleProductChange(index, value);
                                            }}
                                            options={workflowState.products.sort((a, b) => {
                                                const aFound = (record?.all_candidates ?? []).includes(a.uuid)
                                                const bFound = (record?.all_candidates ?? []).includes(b.uuid)
                                                const aSelected = a.uuid === uuid;
                                                const bSelected = b.uuid === uuid;

                                                // First in list is selected product, followed by next closest matches in all_candidates

                                                if (bSelected && !aSelected) {
                                                    return 1;
                                                } else if (aSelected && !bSelected) {
                                                    return -1;
                                                }

                                                if (bFound && !aFound) {
                                                    return 1;
                                                } else if (aFound && !bFound) {
                                                    return -1;
                                                }

                                                if (b.archived && !a.archived) {
                                                    return -2;
                                                } else if (a.archived && !b.archived) {
                                                    return 2;
                                                }
                                                
                                                return 0;
                                            }).map(product => {
                                                
                                                return {
                                                    label: <Typography.Text>{product.archived && <Tag color='yellow'>Inactive</Tag>}{product?.name}</Typography.Text>, 
                                                    value: product.uuid, key: product.uuid
                                                }
                                            })}
                                        />
                                    </div>
                            )}
                        },
                        {
                            title: 'Quantity',
                            dataIndex: 'PRODUCT_QUANTITY',
                            key: 'PRODUCT_QUANTITY',
                            width: !fullscreen ? 65 : 90,
                            render: (value, record, index) => (
                                <InputNumber
                                    disabled={readOnly}
                                    min={0}
                                    controls={false}
                                    style={{width: !fullscreen ? 65 : 90}}
                                    value={value}
                                    onChange={(val) => {
                                        handleProductChange(index, 'PRODUCT_QUANTITY', val);
                                    }}
                                    formatter={formatter}
                                    parser={parser}
                                />
                            )
                        },
                        {
                            title: 'Unit',
                            dataIndex: 'PRODUCT_UNIT',
                            key: 'PRODUCT_UNIT',
                            width: 60,
                            render: (value, record, index) => {
                                return <div style={{width: 60}}>
                                    <Typography.Text>{unitDisplayLabels[value]}</Typography.Text>
                                </div>
                            }
                        },
                        {
                            title: 'Price',
                            dataIndex: 'PRODUCT_PRICE',
                            key: 'PRODUCT_PRICE',
                            width: !fullscreen ? 75 : 100,
                            render: (value, record, index) => (
                                <InputNumber
                                    min={0}
                                    disabled={readOnly || external}
                                    value={value}
                                    controls={false}
                                    style={{width: !fullscreen ? 75 : 100}}
                                    onChange={(val) => {
                                        handleProductChange(index, 'PRODUCT_PRICE', val);
                                    }}
                                    formatter={value => `$${formatter(value)}`}
                                    parser={parser}
                                />
                            )
                        },
                        {
                            title: (
                                <Button onClick={handleAddLineItem} disabled={readOnly} type="link" style={{ marginBottom: 16 }}>
                                    + New
                                </Button>
                            ),
                            key: 'action',
                            render: (_, record, index) => (
                                <Button onClick={() => handleDeleteLineItem(index)} disabled={readOnly} danger>
                                    <DeleteOutlined />
                                </Button>
                            )
                        }
                    ]}
                    pagination={false}
                    size="small" 
                />
            )}
            <Row>
                <Col></Col>
                <Col flex='auto'></Col>
                <Col flex={1}></Col>
            </Row>
            {formState.NON_PRODUCT_LINE_ITEMS && (
                <Table
                    dataSource={formState.NON_PRODUCT_LINE_ITEMS}
                    style={{'width': '100%'}}
                    locale={{
                        emptyText: 'Add discounts, commissions, service fee, freight cost etc.'
                    }}
                    columns={[
                        {
                            title: 'Financial Line Items',
                            dataIndex: 'DESCRIPTION',
                            key: 'DESCRIPTION', 
                            width: '80%',
                            render: (value, record, index) => (
                                <div style={{width: !fullscreen ? 395 : '100%'}}>
                                    <Popover content={<Typography.Text>Financial line item</Typography.Text>}>
                                        <Tag><InfoCircleOutlined/></Tag>
                                    </Popover>
                                    <Input
                                        style={{width: !fullscreen ? 355 : '90%'}}
                                        value={value}
                                        onChange={(val) => {
                                            handleNonProductLineItemChange(index, 'DESCRIPTION', val.target.value);
                                        }}
                                    />
                                </div>
                            )
                        },
                        {
                            title: undefined,
                            dataIndex: 'TOTAL',
                            key: 'TOTAL',
                            width: !fullscreen ? 75 : 270,
                            render: (value, record, index) => (
                                <div>
                                    <InputNumber
                                        min={-100000}
                                        style={{maxWidth: !fullscreen ? 75 : undefined, width: !fullscreen ? undefined : 270}}
                                        value={value}
                                        controls={false}
                                        onChange={(val) => {
                                            handleNonProductLineItemChange(index, 'TOTAL', val);
                                        }}
                                        formatter={value => `$${formatter(value)}`}
                                        parser={parser}
                                    />
                                </div>
                            )
                        },
                        {
                            title: (
                                <Button onClick={handleAddNonProductLineItem} disabled={readOnly} type="link" style={{ marginBottom: 16 }}>
                                    + New
                                </Button>
                            ),
                            key: 'action',
                            render: (_, record, index) => (
                                <Button onClick={() => handleDeleteNonProductLineItem(index)} disabled={readOnly} danger>
                                    <DeleteOutlined />
                                </Button>
                            )
                        }
                    ]}
                    pagination={false}
                    size="small" 
                />
            )}
            <br/>
            <Row gutter={16}>
                <Col flex={'300px'}>
                    <Typography.Text
                        style={{ width: '100%', paddingLeft: '5px'}}
                    >
                        Line Item Total
                    </Typography.Text>
                </Col>
                <Col flex='auto'/>
                <Col flex={'100px'}>
                    <Typography.Text
                        style={{ width: '100%' }}
                    >
                        {` $${lineTotalFormatter(formState.TOTAL_PRICE ? formState.TOTAL_PRICE : 0)}`}
                    </Typography.Text>
                </Col>
            </Row>
            <Divider/>
            <Form.Item label="Notes" name="NOTES">
                <TextArea disabled={readOnly} rows={3} value={formState.NOTES} onChange={e => handleFormChange('NOTES', e.target.value)} />
            </Form.Item>
            { !readOnly &&
                <CustomerShipToModal
                    open={isModalVisible}
                    onOk={handleModalOk}
                    onCancel={() => setIsModalVisible(false)}
                    formState={formState}
                    userData={userData}
                    external={external}
                    navLinks={false}
                />
            }
        </Form>
    );
};

export {OrderEntryForm, statusOptions};
