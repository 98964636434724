import {Col, Divider, Layout, Menu, Popover, Row} from "antd";
import logo from "./logo.svg";
import AuthNav from "./auth/authNav";
import './index.css';
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "./components/Loading";
import React, {useEffect, useState} from "react";
import {useNavigate, Outlet, Link, useLocation} from "react-router-dom";

const {Header, Content, Footer, Sider} = Layout;

const menuColor = '#010F3C'
const contentColor = 'white'

const AppLayout = (props) => {
    const {userData, hasError, setHasError} = props;
    const {pathname} = useLocation();

    const defaultKey = (pathname.includes('setting') && !pathname.includes('/orders/')) ?  ['3'] : ((pathname.includes('/orders/') || pathname === '/') ? ['1'] : ['2']);

    if (!userData) return <Loading style={{paddingTop: 200}}/>

    return (
        <Layout className="layout">
            <Row className={'nav'} wrap={false}>
                <Col flex="70px" style={{ background: menuColor, color: contentColor, fontSize: 24}}>
                    <img className="logo" height={'45'} src={logo} alt="Logo"/>
                </Col>
                <Col flex="115px" className={"logo-name"}
                     style={{minWidth: 100, background: menuColor, color: contentColor, fontSize: 24}}>
                    Pantry
                </Col>
                <Col flex="auto" style={{background: menuColor}}>
                    <Menu
                        className={"nav-menu"}
                        theme="light"
                        style={{color: contentColor, background: menuColor, fontSize: 20}}
                        mode="horizontal"
                        selectedKeys={defaultKey}
                        items={[
                            {
                                key: '1',
                                label: <Link to="/orders/open-orders">Orders</Link>,
                            },
                            userData.setupState !== 'noReports' ? {
                                key: '2',
                                label: <Link to="/sales/customer-dashboard">Reports</Link>,
                            } : undefined,
                            {
                                key: '3',
                                label: <Link to="/settings/order-settings">Settings</Link>,
                            },
                        ]}
                    />
                </Col>
                <Col flex="120px" style={{background: menuColor}}>
                    <AuthNav userData={userData}/>
                </Col>
            </Row>
            <Outlet />
            <Divider style={{ margin: 0, borderColor: 'black' }} /> 
            <Footer style={{textAlign: 'center', background: 'white'}}>Pantry Software ©2024</Footer>
        </Layout>
    );
}

export default withAuthenticationRequired(AppLayout, {
    onRedirecting: () => <Loading style={{paddingTop: 200}}/>,
});