import {
    Button,
    Card,
    Col,
    DatePicker,
    Statistic,
    Skeleton,
    Modal, Input,
    Row,
    Select, Drawer,
    Table,
    Dropdown,
    Tag, Tooltip, Radio,
    Typography, 
    Alert
} from "antd";
import {DownloadOutlined, SearchOutlined, LoadingOutlined, ArrowDownOutlined, ArrowUpOutlined, RobotOutlined, DownOutlined, SlidersOutlined} from '@ant-design/icons'; 
import React, {useState, useEffect, useRef, useContext} from "react";
import {Link, useSearchParams} from 'react-router-dom';
import { fetchCustomerIndex } from "../../endpoints/customerIndex";
import MultiSelect from "../ui/MultiSelect";
import dayjs from "dayjs";
import Loading from "../Loading";
import {currencyFormatter, volumeFormatter, getPeriodStart, buildTimeKey, exportToExcel, nullSafeConcat} from '../utils'

const colorPrimary = '#4fad96';

const CustomerDashboard = (props) => {
    const {userData, setHasError} = props;
    const {accessToken} = userData;

    const [searchParams, setSearchParams] = useSearchParams();

    const [dataSource, setDataSource] = useState(null);

    const [tableData, setTableData] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [pulling, setPulling] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [groupBy, setGroupBy] = useState(searchParams.get('groupBy') ? searchParams.get('groupBy') : null);
    const [groupByOptions, setGroupByOptions] = useState([]);

    const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const abortControllerRef = useRef(null);

    const [volumeSetting, setVolumeSetting] = useState(true);
    const [unit, setUnit] = useState('revenue');

    const quarterStart = dayjs(getPeriodStart(new Date(), 'QS'));
    const quarterEnd = quarterStart.add(2, 'month');
    const [trange, setTrange] = useState([quarterStart, quarterEnd]);

    const fetchDashboard = async () => {
        // If there's an ongoing fetch, cancel it
        if (abortControllerRef.current && dataSource !== null) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController
        abortControllerRef.current = new AbortController();

        setPulling(true);
        let projData;
        let wasAborted;
        try {
            const trangeISOStr = trange.map(date => date.format('YYYY-MM-DD')).join(',');

            projData = await fetchCustomerIndex(
                trangeISOStr, accessToken, !volumeSetting, unit === 'revenue', groupBy === 'None' ? null : groupBy,
                { signal: abortControllerRef.current.signal }
            );
        } catch (e) {
            if (e.name === 'AbortError') {
                console.log('Fetch aborted');
                wasAborted = true;
            } else {
                wasAborted = false;
                setHasError(e);
            }
            console.log(e);
        }
        console.log(projData)
        
        if (!wasAborted)
            setPulling(false);

        if (!projData) return;

        setGroupByOptions(projData['metaData']['property_options'] ? projData['metaData']['property_options'] : []);
        setDataSource(projData);
    }

    useEffect(() => {
        if (dataSource === null) fetchDashboard();
    }, []);

    useEffect(() => {
        fetchDashboard();
    }, [volumeSetting, trange, unit, groupBy]);

    useEffect(() => {
        if (dataSource) {
            setExportData(applyFilter(dataSource['tableData']));
            setTableData(applyFilter(dataSource['tableData']));
            setCustomerOptions(dataSource['tableData'].map((r) => {
                return {id: r.customer_id, name: r.customer_name}
            }));
            
            if (selectedCustomerIds.length === 0 && groupBy === 'None')
                setSelectedCustomerIds(dataSource['tableData'].map((c) => c.customer_id));
        }
    }, [dataSource]);

    useEffect(() => {
        if (dataSource) {
            setExportData(applyFilter(dataSource['tableData']));
            setTableData(applyFilter(dataSource['tableData']));
        }
    }, [selectedCustomerIds]);

    if (dataSource === null)
        return <Loading/>

    const handleGroupByChange = (value) => {
        setGroupBy(value);
    
        const newSearchParams = new URLSearchParams(searchParams);
        if (value !== null) 
            newSearchParams.set('groupBy', value);
        else
            newSearchParams.delete('groupBy');

        setSearchParams(newSearchParams);
    };

    const volumeName = volumeSetting ? dataSource['metaData']['default_po_unit'] : dataSource['metaData']['default_agg_unit'];
    const staticSuffix = (unit === 'revenue' ? '' : volumeName)
    const weeklyRateSuffix = (unit === 'revenue' ? '' : volumeName) + ' / Week';
    const ratePrefix = (unit === 'revenue' ? '$' : '');

    const applyFilter = (dataIn) => {
        console.log(selectedCustomerIds)
        console.log(groupBy)

        if (groupBy !== 'None')
            return dataIn
        else
            return dataIn.filter((c) => selectedCustomerIds.includes(c.customer_id))
    }

    const sortHelper = (a, b, sortOrder) => {
        let aVal = NaN;
        if (a !== null && a !== 0) {
            aVal = a;
        }
        let bVal = NaN;
        if (b !== null && b !== 0) {
            bVal = b;
        }
        if (isNaN(aVal)) {
            return sortOrder === 'ascend' ? 1 : -1;
        }
        if (isNaN(bVal)) {
            return sortOrder === 'ascend' ? -1 : 1;
        }

        return aVal > bVal ? 1 : -1
    };

    const columns = [
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            width: 300,
            key: 'name',
            render: (val, row) => {
                if (val === '')
                    return <Typography.Text>No {groupBy} Set</Typography.Text>
                else
                    return (
                        <Link 
                            to={{ 
                                pathname: '/sales/customer', 
                                search: `?ids=${row['customer_ids'] ? row['customer_ids'].join(',') : row['customer_id']}` 
                            }}
                            style={{
                                color: "#0091AE", // Set the text color to blue
                                textDecoration: 'underline', // Underline the text
                                cursor: 'pointer', // Change cursor to pointer when hovering
                            }}
                        >
                            {val}
                        </Link>
                )
            },
            sorter: (a, b) => {
                return a.customer_name > b.customer_name ? 1 : -1
            }
        },
        {
            title: `YTD (${buildTimeKey(new Date(new Date().getFullYear(), 0, 1), 'MS')}-${buildTimeKey(new Date(), 'MS')})`,
            children: [
                {
                    title: `Actual`,
                    dataIndex: 'year_to_date_actuals',
                    width: 200,
                    align: 'right',
                    key: 'year_to_date_actuals',
                    defaultSortOrder: 'descend',
                    render: (val, row) => {
                        if (val === null) {
                            return {
                                children: <span style={{color: "gray"}}>--</span>
                            };
                        }
                        if (unit === 'revenue') {
                            return `${currencyFormatter.format(val)}` 
                        } else {
                            return `${volumeFormatter.format(val)}` 
                        }
                    },
                    sorter: (a, b, sortOrder) => sortHelper(a.year_to_date_actuals, b.year_to_date_actuals, sortOrder)
                },
            
                {
                    title: `vs Last Year`,
                    width: 200,
                    align: 'right',
                    key: 'last_year_to_date_actuals',
                    render: (val, row) => {
                        if (val === null || !val['last_year_to_date_actuals'] || val['year_to_date_actuals'] === null) {
                            return {
                                children: <span style={{color: "gray"}}>--</span>
                            };
                        }
                        let ret_val = +(-100 + (val['year_to_date_actuals'] / val['last_year_to_date_actuals']*100)).toFixed(1);
                        let ret_str = ret_val + "%";
                        return {
                            children: <span style={{ color: ret_val > 0 ? '#3f8600' : '#cf1322' }}>{ret_val === 0 ? '' : ret_val > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}{ret_str.replace("-", "")}</span>
                        };
                    },
                    sorter: (a, b, sortOrder) => {
                        const a_percentage = a.last_year_to_date_actuals && a.year_to_date_actuals ? -100 + (a.year_to_date_actuals / a.last_year_to_date_actuals*100) : null;
                        const b_percentage = b.last_year_to_date_actuals && b.year_to_date_actuals ? -100 + (b.year_to_date_actuals / b.last_year_to_date_actuals*100) : null;

                        return sortHelper(a_percentage, b_percentage, sortOrder);
                    }
                },
            ]
        },
        {
            title: `Selected Period (${buildTimeKey(new Date(trange[0]), 'MS')}` + `-` + `${buildTimeKey(new Date(trange[1]), 'MS')})`,
            children: [
                {
                    title: `Actual`,
                    dataIndex: 'current_period_actuals',
                    className: 'bold-border',
                    width: 200,
                    align: 'right',
                    key: 'current_period_actuals',
                    render: (val, row) => {
                        if (val === null) {
                            return {
                                children: <span style={{color: "gray"}}>--</span>
                            };
                        }
                        if (unit === 'revenue') {
                            return `${currencyFormatter.format(val)}` 
                        } else {
                            return `${volumeFormatter.format(val)}` 
                        }
                    },
                    sorter: (a, b, sortOrder) => sortHelper(a.current_period_actuals, b.current_period_actuals, sortOrder)
                },
                {
                    title: `vs Last Year`,
                    width: 200,
                    key: 'last_period_actuals',
                    align: 'right',
                    render: (val, row) => {
                        if (val === null || !val['last_period_actuals'] || val['current_period_actuals'] === null) {
                            return {
                                children: <span style={{color: "gray"}}>--</span>
                            };
                        }
                        let ret_val = +(-100 + (val['current_period_actuals'] / val['last_period_actuals']*100)).toFixed(1);
                        let ret_str = ret_val + "%";
                        return {
                            children: <span style={{ color: ret_val > 0 ? '#3f8600' : '#cf1322' }}>{ret_val === 0 ? '' : ret_val > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}{ret_str.replace("-", "")}</span>
                        };
                    },
                    sorter: (a, b, sortOrder) => {
                        const a_percentage = a.last_period_actuals && a.current_period_actuals ? -100 + (a.current_period_actuals / a.last_period_actuals*100) : null;
                        const b_percentage = b.last_period_actuals && b.current_period_actuals ? -100 + (b.current_period_actuals / b.last_period_actuals*100) : null;
            
                        return sortHelper(a_percentage, b_percentage, sortOrder);
                    }
                },
                // {
                //     title: 'Progress to Forecast',
                //     width: 200,
                //     key: 'current_period_forecast',
                //     align: 'right',
                //     render: (val, row) => {
                //         if (val === null || !val['current_period_actuals'] || val['current_period_forecast'] === null) {
                //             return {
                //                 children: <span style={{color: "gray"}}>--</span>
                //             };
                //         }
                //         let ret_val = +(val['current_period_actuals'] / val['current_period_forecast']*100).toFixed(1);
                //         let ret_str = ret_val + "%";
                //         return {
                //             children: <span style={{ color: "black" }}>{ret_str.replace("-", "")}</span>
                //         };
                //     },
                //     sorter: (a, b, sortOrder) => {
                //         const a_percentage = a.current_period_forecast && a.current_period_actuals ? (a.current_period_actuals / a.current_period_forecast*100) : null;
                //         const b_percentage = b.current_period_forecast && b.current_period_actuals ? (b.current_period_actuals / b.current_period_forecast*100) : null;
            
                //         return sortHelper(a_percentage, b_percentage, sortOrder);
                //     }
                // },
                {
                    title: `Pipeline`,
                    width: 200,
                    key: 'current_period_pipeline',
                    align: 'right',
                    render: (val, row) => {
                        if (val === null || val['current_period_pipeline'] === null) {
                            return {
                                children: <span style={{color: "gray"}}>--</span>
                            };
                        }
                        const v = val['current_period_pipeline'];
                        if (unit === 'revenue') {
                            return `${currencyFormatter.format(v)}` 
                        } else {
                            return `${volumeFormatter.format(v)}` 
                        }
                    },
                    sorter: (a, b, sortOrder) => sortHelper(a.current_period_pipeline, b.current_period_pipeline, sortOrder)
                },
            ]
        }
    ]

    const disabledDate = (current) => {
        // TODO(arlo) need real logic for this
        const today = new Date();
        const selectedDate = current.toDate();
        const diff = (today.getFullYear() - selectedDate.getFullYear()) * 12 + (today.getMonth() - selectedDate.getMonth());
        return diff < -18;
    }

    const onTimePickChange = (value, dateString) => {
        const [startMonth, endMonth] = dateString;
        
        if (startMonth && endMonth)
            setTrange([dayjs(startMonth), dayjs(endMonth)]);
    }

    return (
        <div>
            <Row>
                <Col flex={'200px'}>
                    <Select
                        style={{width: '100%'}}
                        optionFilterProp="children"
                        defaultValue={'revenue'}
                        suffixIcon={pulling ? <LoadingOutlined style={{color: 'black'}}/> : undefined}
                        onChange={(value, event) => {
                            if (value === 'volume_unit' || value === 'volume_case') {
                                setUnit('volume');
                                if (value === 'volume_unit')
                                    setVolumeSetting(true);
                                else
                                    setVolumeSetting(false);
                            } else {
                                setUnit('revenue');
                                setVolumeSetting(true);
                            }
                        }}
                        options={
                            nullSafeConcat(
                            [
                                {label: `Volume (${dataSource['metaData']['default_po_unit']})`, value: 'volume_unit'},
                            ], dataSource['metaData']['default_agg_unit'] === null ? null : [
                                {label: `Volume (${dataSource['metaData']['default_agg_unit']})`, value: 'volume_case'},
                            ]).concat([
                                {label: 'Revenue', value: 'revenue'},
                            ])
                        }
                    />
                </Col>
                <Col flex={'15px'}>
                </Col>
                <Col flex={'254px'}>
                    <DatePicker.RangePicker
                        format="YYYY-MM"
                        picker="month"
                        allowClear
                        value={trange}
                        disabledDate={disabledDate}
                        onChange={onTimePickChange}
                    />
                </Col>
                {
                    groupBy === 'None' &&
                    <>
                    <Col flex={'15px'}>
                    </Col>
                    <Col flex={'275px'}>
                        <MultiSelect
                            selectOptions={customerOptions}
                            setSelected={setSelectedCustomerIds}
                            selected={selectedCustomerIds}
                            objectName='Customer'
                            loading={pulling}
                        />
                    </Col>
                    </>
                }
                <Col flex={'15px'}>
                </Col>
                <Col flex='200px'>
                    <Button
                        icon={<SlidersOutlined />}
                        onClick={() => setDrawerVisible(!drawerVisible)}
                        ghost={groupBy !== 'None' ? true : false}
                        type={groupBy !== 'None' ? 'primary' : 'default'}
                    >
                        Advanced Filters {groupBy !== 'None' ? '(1)' : ''}
                    </Button>
                </Col>
                <Col flex={'auto'}>
                </Col>
                <Col flex={'100px'}>
                    {exportData && 
                        <>
                        <Button type={"primary"} onClick={() => exportToExcel(exportData, 'PantryCustomers')}>
                            <DownloadOutlined style={{paddingRight: 5}}/>
                            Export
                        </Button>
                        </>
                    }
                </Col>
            </Row>
            <br/>
            <Row gutter={16}>
                <Col span={8}>
                    <Card bordered={false}>
                    {(pulling || (selectedCustomerIds.length === 0 && groupBy === 'None')) && <Skeleton active paragraph={{ rows: 1 }} />}
                    {!(pulling || (selectedCustomerIds.length === 0 && groupBy === 'None')) && <>
                        <Statistic
                            title={`Actual YTD (${dayjs(new Date(new Date().getFullYear(), 0, 1)).format('MMM D, YYYY')}-${dayjs(new Date()).endOf('month').format('MMM D, YYYY')})`}
                            value={applyFilter(dataSource['tableData']).map(c =>  c['year_to_date_actuals']).reduce((a, b) => a + b, 0)}
                            precision={0}
                            prefix={ratePrefix}
                            suffix={staticSuffix}
                        />
                        <Row>
                            { [-100 + applyFilter(dataSource['tableData']).map(c => c['year_to_date_actuals']).reduce((a, b) => a + b, 0) / applyFilter(dataSource['tableData']).map(c => c['last_year_to_date_actuals']).reduce((a, b) => a + b, 0) * 100].map((val) => {
                                return (
                                <>
                                    <Statistic
                                        value={val}
                                        precision={1}
                                        prefix={val === 0 ? '' : val > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                                        valueStyle={{ color: val === 0 ? 'black' : val > 0.0 ? '#3f8600' : '#cf1322', fontSize: '14px' }}
                                        suffix="%"
                                    />
                                    <Typography.Text>&nbsp;vs {dayjs(new Date(new Date().getFullYear() - 1, 0, 1)).format('MMM D, YYYY')}-{dayjs(new Date()).subtract(1, 'year').endOf('month').format('MMM D, YYYY')}</Typography.Text>
                                </>
                                )
                            })}
                        </Row>
                    </>}
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                    {(pulling || (selectedCustomerIds.length === 0 && groupBy === 'None')) && <Skeleton active paragraph={{ rows: 1 }} />}
                    {!(pulling || (selectedCustomerIds.length === 0 && groupBy === 'None')) && <>
                        <Statistic
                            title={`Actual (${dayjs(new Date(trange[0])).startOf('month').format('MMM D, YYYY')}` + `-` + `${dayjs(new Date(trange[1])).endOf('month').format('MMM D, YYYY')})`}
                            value={applyFilter(dataSource['tableData']).map(c => c['current_period_actuals']).reduce((a, b) => a + b, 0)}
                            precision={0}
                            prefix={ratePrefix}
                            suffix={staticSuffix}
                        />
                        <Row>
                        { [-100 + applyFilter(dataSource['tableData']).map(c => c['current_period_actuals']).reduce((a, b) => a + b, 0) / applyFilter(dataSource['tableData']).map(c => c['last_period_actuals']).reduce((a, b) => a + b, 0) * 100].map((val) => {
                            return (
                            <>
                                <Statistic
                                    value={val}
                                    precision={1}
                                    prefix={val === 0 ? '' : val > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                                    valueStyle={{ color: val === 0 ? 'black' : val > 0.0 ? '#3f8600' : '#cf1322', fontSize: '14px' }}
                                    suffix="%"
                                />
                                <Typography.Text>&nbsp;vs {dayjs(new Date(trange[0])).subtract(1, 'year').startOf('month').format('MMM D, YYYY')}-{dayjs(new Date(trange[1])).subtract(1, 'year').endOf('month').format('MMM D, YYYY')}</Typography.Text>
                            </>
                            )
                        })}
                        </Row>
                    </>}
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                    {(pulling || (selectedCustomerIds.length === 0 && groupBy === 'None')) && <Skeleton active paragraph={{ rows: 1 }} />}
                    {!(pulling || (selectedCustomerIds.length === 0 && groupBy === 'None')) && <>
                        <Statistic
                        title={`Pipeline (${dayjs(new Date(trange[0])).startOf('month').format('MMM D, YYYY')}` + `-` + `${dayjs(new Date(trange[1])).endOf('month').format('MMM D, YYYY')})`}
                        value={applyFilter(dataSource['tableData']).map(c => c['current_period_pipeline']).reduce((a, b) => a + b, 0)}
                        precision={0} 
                        prefix={ratePrefix}
                        suffix={staticSuffix}
                        />
                        <Row>
                        { [0].map((val) => {
                            return (
                            <>
                                {/* <Statistic
                                value={val}
                                precision={1}
                                prefix={val === 0 ? '' : val > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                                valueStyle={{ color: val === 0 ? 'black' : val > 0.0 ? '#3f8600' : '#cf1322', fontSize: '14px' }}
                                suffix="%"
                                /> */}
                                <Typography.Text>&nbsp;</Typography.Text>
                            </>
                            )
                        })}
                        </Row>
                    </>}
                    </Card>
                </Col>
            </Row>
            <br/>
            {!pulling ? <Table
                dataSource={tableData} 
                columns={columns}
                pagination={{
                    defaultPageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ['25', '50', '100'],
                }}
            /> : <Skeleton active paragraph={{ rows: 10 }} />
            }
            <Drawer
                title="Advanced Filters"
                placement="right"
                closable={true}
                onClose={() => setDrawerVisible(!drawerVisible)}
                open={drawerVisible}
                width={320}
            >
                <Row>
                    <b>Group Customers By</b>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Group By"
                        value={groupBy}
                        onChange={handleGroupByChange}
                        options={[{label: '(None)', value: 'None'}, ...groupByOptions.map(option => ({ label: option, value: option }))]}
                    />
                </Row>
            </Drawer>
        </div>
        
    )
}

export default CustomerDashboard;