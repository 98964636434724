import {parseTimeKey, getNextPeriod} from "../../utils";
import React, { useState, useEffect } from "react";
import { Row, Button, Select, Drawer, Input, Typography, Tag, Col, InputNumber, DatePicker, Modal} from 'antd';
import {findChangedIndex} from "../../utils"
import MultiSelect from "../../ui/MultiSelect";
import {getStages, stages, stageDescriptions, stageWeights} from './Inputs';

const colorPrimary = '#4fad96';

const AddEvent = ({userData, unit, localUpdates, setLocalUpdates, localEvents, setLocalEvents, prepopulateEvent, setPrepopulateEvent, isModalVisible, dataSource, setIsModalVisible, volumeSetting}) => {
    const [eventType, setEventType] = useState(null);
    const [eventId, setEventId] = useState(null);
    const [eventCustomers, setEventCustomers] = useState([]);
    const [eventDateRange, setEventDateRange] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false); // new state to track form validity
    const [customerProp, setCustomerProp] = useState(null);
    const [selectedSkus, setSelectedSkus] = useState([]);
    const [eventVolume, setEventVolume] = useState({});
    const [eventOngoing, setEventOngoing] = useState({});
    const [eventOwner, setEventOwner] = useState(null);

    const [newCust, setNewCust] = useState(null);
    const [newProduct, setNewProduct] = useState(null);

    const [eventStage, setEventStage] = useState('Upcoming');
    const [eventNote, setEventNote] = useState(null);

    const [newCustOpen, setNewCustOpen] = useState(false);
    const [newProductOpen, setNewProductOpen] = useState(false);

    const volumeName = unit === 'revenue' ? 'dollars' : (dataSource['products'].length > 0 ? (volumeSetting ? dataSource['products'][0]['po_unit'] : dataSource['products'][0]['agg_unit']) : 'units');

    const clearEventState = () => {
        setEventType(null);
        setEventNote(null);
        setEventStage('Upcoming');
        setSelectedSkus([]);
        setEventCustomers([]);
        setEventDateRange([]);
        setEventVolume([]); // Reset the event volume
        setEventOngoing([]); // Reset the event volume
        setNewCust(null); // Reset the event volume
        setNewProduct(null);
        setEventId(null);
        setEventOwner(null);
    }

    const saveAndClose = async () => {
        var start_date = parseTimeKey(eventDateRange[0], "MS");
        const today = new Date();
        // if we are moving a deal to complete, set the end date
        if (eventStage === 'Complete' && eventDateRange.length == 1 && start_date.getTime() < today.getTime()) {
            const today_str = today.toISOString().split('T')[0]; // format YYYY-MM-DD to match date picker
            eventDateRange.push(today_str);
        }

        // TODO: jeez i need a struct or something
        const newEvent = {
            action: eventId === null ? 'create' : 'update',
            ongoingAdd: eventOngoing,
            eventSku: selectedSkus,
            eventId: eventId !== null ? eventId : crypto.randomUUID(),
            newCust: eventCustomers.length > 0 ? null : newCust,
            weight: stageWeights[eventStage],
            eventType: eventType,
            eventCustomers: eventCustomers,
            eventDateRange: eventDateRange,
            eventNote: eventNote,
            eventStage: eventStage,
            eventLift: eventVolume,
            owner: eventOwner,
            percentLift: null,
            productName: selectedSkus.map((sku) => dataSource['products'].find(entry => entry.product_id === sku).name)
        }
        console.log(newEvent)
        setLocalUpdates([
            ...localUpdates, 
            newEvent
        ]);

        const newEventOtherFormat = {
            ongoing_add: newEvent.ongoingAdd,
            product_id: newEvent.eventSku,
            id: newEvent.eventId,  // if not null, backend will update instead of create
            weight: newEvent.weight,
            event_type: newEvent.eventType,
            customer_ids: newEvent.eventCustomers,
            customers: [...dataSource['customers'].filter((c) => newEvent.eventCustomers.includes(c.id)).map((c) => c.name)],
            start_date: newEvent.eventDateRange[0],
            end_date: newEvent.eventDateRange.length > 1 ? newEvent.eventDateRange[1] : null,
            note: newEvent.eventNote,
            stage: newEvent.eventStage,
            owner: newEvent.owner,
            volume_lift: newEvent.eventLift,
            percentage_lift: newEvent.percentLift,
            productName: newEvent.productName
        }

        // Update local events to reflect in UI instantly as backend processes
        const stageEventList = [...localEvents[eventStage]];
        if (prepopulateEvent !== undefined && prepopulateEvent !== null) {
            if (prepopulateEvent.stage === eventStage) {
                // swap out item from existing stage
                const newStageList = stageEventList.map(item => {
                    if (item.id === eventId) {
                        // TODO: NEED STANDARD DATA STRUCTURE!!
                        return newEventOtherFormat;
                    }
                    return item;
                });
                setLocalEvents({
                    ...localEvents,
                    [eventStage]: newStageList,
                });
            } else {
                // need to move to new stage
                setLocalEvents({
                    ...localEvents,
                    [eventStage]: [newEventOtherFormat, ...localEvents[eventStage]],
                    [prepopulateEvent.stage]: localEvents[prepopulateEvent.stage].filter((i) => i.id !== eventId),
                });
            }
        } else {
            // Adding a new Event
            const newStageList = [{...newEventOtherFormat}, ...stageEventList];
            setLocalEvents({
                ...localEvents,
                [eventStage]: newStageList,
            });
        }

        handleClose();
    }
    const deleteEvent = async (row) => {
        setLocalUpdates([
            ...localUpdates, 
            {action: 'delete', eventId: row.id}
        ]);
        const stageEventList = [...localEvents[eventStage]];
        let newStageList = stageEventList.filter((item) => item.id !== eventId);
        setLocalEvents({
          ...localEvents,
          [eventStage]: newStageList,
        });
        handleClose();
    }

    const handleClose = () => {
        setIsModalVisible(false);
    }

    const editEvent = async () => {
        clearEventState();

        if (prepopulateEvent === undefined || prepopulateEvent === null) {
            setPrepopulateEvent(undefined);
            return;
        }

        setEventId(prepopulateEvent.id);
        setEventNote(prepopulateEvent.note);
        setEventStage(prepopulateEvent.stage);
        setSelectedSkus(prepopulateEvent.product_id);
        setEventType(prepopulateEvent.event_type);
        setEventCustomers(prepopulateEvent.customer_ids);
        setEventDateRange(['Promotion - OI', 'Promotion - MCB', 'Promotion - Direct Scan', 'Forecast Adjustment'].includes(prepopulateEvent.event_type) ? [prepopulateEvent.start_date, prepopulateEvent.end_date] : [prepopulateEvent.start_date]);
        setEventOngoing(prepopulateEvent.ongoing_add);
        setEventOwner(prepopulateEvent.owner);
        //setNewCust(prepopulateEvent.customers[0]);
        setEventVolume(prepopulateEvent.volume_lift);
        setIsModalVisible(true);
    }

    // ensure if we set date range and then switch to event type without date range that the pre-set date is not weird
    useEffect(() => {
        if (eventType !== null && prepopulateEvent !== undefined) {
            setEventDateRange(['Promotion - OI', 'Promotion - MCB', 'Promotion - Direct Scan', 'Forecast Adjustment'].includes(prepopulateEvent.event_type) ? [prepopulateEvent.start_date, prepopulateEvent.end_date] : [prepopulateEvent.start_date]);
        }
    }, [eventType]);

    useEffect(() => {
        editEvent();
        console.log(selectedSkus)

    }, [prepopulateEvent]);

    useEffect(() => {
        if (dataSource === null) return;

        if (dataSource['new_customer_ids'].length > 0) {
            // newly created customer, add to selected
            const uniqueCustomerIds = dataSource['new_customer_ids'].filter(id => !eventCustomers.includes(id));

            // Combine eventCustomers and uniqueCustomerIds
            setEventCustomers([
                ...eventCustomers,
                ...uniqueCustomerIds
            ]);
        }

        if (dataSource['new_product_ids'].length > 0) {
            // newly created customer, add to selected
            const uniqueProductIds = dataSource['new_product_ids'].filter(id => !selectedSkus.includes(id));

            // Combine eventCustomers and uniqueCustomerIds
            setSelectedSkus([
                ...selectedSkus,
                ...uniqueProductIds
            ]);
        }
    }, [dataSource]);

    const buildPresets = (label, sku, type) => {
        // todo: fix
        // const skuIndex = parseInt(dataSource['products'].find(entry => entry.product_id === sku).key);
        // const velocity = dataSource['settings'][skuIndex].velocity;

        if (label.includes('Discont') && type === 'ongoing') {
            // set to average velocity for product weighted by cust_prop
            return 0//Math.round(-1 * customerProp / 100 * velocity);
        } else {
            return 0;
        }
    }

    useEffect(() => {
        if (eventCustomers.includes(null)) {
            // apply to all customers
            setCustomerProp(100);
            return;
        }
        const customerProportion = Math.round(dataSource['customers']
            .filter(entry => eventCustomers.includes(entry.id))
            .reduce((accumulator, entry) => accumulator + entry.sales_proportion, 0) * 10000) / 100;
        setCustomerProp(customerProportion);
    }, [eventCustomers]);

    useEffect(() => {
        const isValid = selectedSkus.length > 0 && eventNote !== null && /\S/.test(eventNote) && eventType !== null && (eventCustomers.length > 0 || newCust !== null) && eventDateRange.length > 0;
        
        setIsFormValid(isValid);
    }, [eventType, eventCustomers, eventDateRange, eventNote, selectedSkus, newCust]);

    return (
    <>
    <Drawer
        title={prepopulateEvent !== undefined ? "Edit Event" : "Add Event"}
        placement="right"
        closable={true}
        onClose={handleClose}
        open={isModalVisible}
        width={600}
        bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
        <div style={{ flex: 1, overflowY: 'auto' }}> 
        <b>Event Type</b>
        <Select
            placeholder="Select event type"
            style={{ width: '100%', marginBottom: 16 }}
            value={eventType}
            options={[
                //{label: 'Forecast Adjustment', value: 'Forecast Adjustment', key: 'adj'},
                {label: 'New Business', value: 'New Business', key: 'new'},
                {label: 'Promotion - Direct Scan', value: 'Promotion - Direct Scan', key: 'ds'},
                {label: 'Promotion - MCB', value: 'Promotion - MCB', key: 'mcb'},
                {label: 'Promotion - OI', value: 'Promotion - OI', key: 'oi'},
                {label: 'Discontinue for Customer', value: 'Discontinue for Customer', key: 'discont'},
            ]}
            onChange={(value) => {
                setEventType(value);
            }}
        />
        { eventType !== null && 
            <>
            <b>Event Name</b>
            <Input
                placeholder=""
                style={{ width: '100%', marginBottom: 16 }}
                value={eventNote}
                onChange={(obj) => {
                    setEventNote(obj.target.value);
                }}
            />
            <b>Event Owner (optional)</b>
            <Input
                placeholder={'ex. ' + userData.email}
                style={{ width: '100%', marginBottom: 16 }}
                value={eventOwner}
                onChange={(obj) => {
                    setEventOwner(obj.target.value);
                }}
            />
            </>
        }
        { (eventType !== null) &&
            <>
            <Row justify="space-between" align="middle">
                <Col>
                    <b>Customer(s)</b>
                </Col>
                <Col>
                    <Typography.Text
                        onClick={() => {
                            setNewCustOpen(true);
                        }}
                        style={{
                            color: "#0091AE", // Set the text color to blue
                            textDecoration: 'underline', // Underline the text
                            cursor: 'pointer', // Change cursor to pointer when hovering
                        }}
                    >
                        + Add New
                    </Typography.Text>
                </Col>
            </Row>
            <MultiSelect
                selectOptions={[{name: '(All Customers)', id: null}, ...dataSource['customers'].map((c) => {return {name: c.name, id: c.id}})]}
                setSelected={setEventCustomers}
                selected={eventCustomers}
                style={{ width: '100%', marginBottom: 16 }}
                objectName="Customer"
                loading={!dataSource}
            />
            { eventCustomers.length > 0 &&
                <Typography.Text>
                    Selected customers generally <Tag>{customerProp}% </Tag>of monthly sales
                </Typography.Text>
            }
            <Row style={{ width: '100%', marginBottom: 16}}></Row>
            </>
        }
        {   (eventCustomers.length > 0 || newCust !== null) &&
            <>
            <b>Stage</b>
            <Select
                style={{ width: '100%', marginBottom: 16 }}
                value={eventStage}
                options={getStages(prepopulateEvent !== undefined).map((s) => {return {
                    label: s + ((s !== 'Complete') ? (' - ' + stageWeights[s]*100 + '%') : ''),
                    value: s,
                    key: s
                }})}
                onChange={(obj, val) => {setEventStage(val.value)}}
            />
            <Row justify="space-between" align="middle">
                <Col>
                    <b>Product(s)</b>
                </Col>
                <Col>
                    <Typography.Text 
                        onClick={() => {
                            setNewProductOpen(true);
                        }}
                        style={{
                            color: "#0091AE", // Set the text color to blue
                            textDecoration: 'underline', // Underline the text
                            cursor: 'pointer', // Change cursor to pointer when hovering
                        }}
                    >
                        + Add New
                    </Typography.Text>
                </Col>
            </Row>
            <MultiSelect
                selectOptions={dataSource['products'].filter((p) => p.archived !== true || selectedSkus.includes(p.product_id)).map((p) => {
                    var suffix = (p['archived'] === true) ? ' (Archived)' : '';
                        return ({name: p.name + suffix, id: p.product_id, disabled: p.archived === true})
                    })
                }
                setSelected={
                    (values) => {
                        const changedIdx = findChangedIndex(selectedSkus, values);
    
                        if (values.length > selectedSkus.length) {
                            // added product
                            setEventVolume(prevVolume => {
                                let newVolume = [...prevVolume];
                                newVolume.splice(changedIdx, 0, buildPresets(eventType, values[changedIdx], 'initial')); // Insert 0 at the changed index
                                return newVolume;
                            });
                        
                            setEventOngoing(prevOngoing => {
                                let newOngoing = [...prevOngoing];
                                newOngoing.splice(changedIdx, 0, buildPresets(eventType, values[changedIdx], 'ongoing')); // Insert 0 at the changed index
                                return newOngoing;
                            });
                        } else {
                            // removed product
                            setEventVolume(prevVolume => {
                                let newVolume = [...prevVolume];
                                newVolume.splice(changedIdx, 1); // Remove item at the changed index
                                return newVolume;
                            });
                        
                            setEventOngoing(prevOngoing => {
                                let newOngoing = [...prevOngoing];
                                newOngoing.splice(changedIdx, 1); // Remove item at the changed index
                                return newOngoing;
                            });
                        }
                        
                        setSelectedSkus(values);                
                    }
                }
                selected={selectedSkus}
                style={{ width: '100%', marginBottom: 16 }}
                objectName="Product"
                loading={!dataSource}
            />
            </>
        }
        { eventCustomers.length > 0 && (eventType.toLowerCase().includes('promo')) &&
            <>
            <b>Dates</b>
            <DatePicker.RangePicker
                format="YYYY-MM-DD"
                //key={eventDateRange.length}
                allowClear={false}
                style={{ width: '100%', marginBottom: 16 }}
                placeholder={eventDateRange.length > 0 ? eventDateRange : ['Start Date', 'End Date']}
                onChange={(t, r) => setEventDateRange(r)}
            />
            { selectedSkus.length > 0 &&
                <b style={{ display: 'block'}}>
                    Event Volume Lift(s)
                </b>
            }
            {
                selectedSkus.map((sku, skuIdx) => {
                    return (
                        <>
                        <Typography.Text>{dataSource['products'].find(entry => entry.product_id === sku).name}</Typography.Text>
                        <InputNumber
                            value={eventVolume[skuIdx]}
                            style={{ width: '100%' }}
                            onChange={(value) => {
                                const newVolumes = [...eventVolume]; 
                                console.log(value)
                                newVolumes[skuIdx] = value !== null ? value : 0;
                                setEventVolume(newVolumes);
                            }}
                        />
                        <br/>
                        </>
                    )
                })
            }
            <Row style={{ justifyContent: 'flex-end' }}>
                { eventCustomers.length > 0 && eventDateRange.length > 0 && eventDateRange[0] && eventVolume &&
                    <Typography.Text>
                        Add <Tag>{eventVolume.reduce((a, b) => a + b, 0)}</Tag>{volumeName} to forecast between <Tag>{eventDateRange[0]}</Tag>and <Tag>{eventDateRange[1]}</Tag>
                    </Typography.Text>
                }
            </Row>
            <br/>
            </>
        }
        { eventCustomers.length > 0 && eventType.toLowerCase().includes('discontinue') &&
            <>
                <b>Dates</b>
                <DatePicker
                    format="YYYY-MM-DD"
                    //key={eventDateRange.length}
                    style={{ width: '100%', marginBottom: 16 }}
                    placeholder={eventDateRange.length > 0 ? eventDateRange : ['Discontinue Date']}
                    onChange={(t, r) => setEventDateRange([r])}
                />
                { selectedSkus.length > 0 &&
                    <b style={{ display: 'block'}}>
                        Event Volume Lift(s)
                    </b>
                }
                {
                    selectedSkus.map((sku, skuIdx) => {
                    return (
                            <>
                            <Typography.Text>{dataSource['products'].find(entry => entry.product_id === sku).name}</Typography.Text>
                            <InputNumber
                                placeholder={'Recommended: -' + Math.round()}
                                value={eventOngoing[skuIdx]}
                                style={{ width: '100%' }}
                                onChange={(value) => {
                                    const newOngoing = [...eventOngoing]; 
                                    // save null as zero
                                    newOngoing[skuIdx] = value !== null ? value : 0;
                                    setEventOngoing(newOngoing);
                                }}
                            />
                            <br/>
                            </>
                        )
                    })
                }
                <Row style={{ justifyContent: 'flex-end' }}>
                    { eventCustomers.length > 0 && eventDateRange.length > 0 && eventDateRange[0] && eventOngoing &&
                        <Typography.Text>
                            Add <Tag>{eventOngoing.reduce((a, b) => a + b, 0)}</Tag>{volumeName} monthly to forecast starting <Tag>{eventDateRange[0]}</Tag>
                        </Typography.Text>
                    }
                </Row>
            </>
        }
        { (eventCustomers.length > 0 || newCust !== null) && (eventType.toLowerCase().includes('new bus') || eventType.toLowerCase().includes('new cust')) &&
            <>
                <b>Dates</b>
                <DatePicker
                    format="YYYY-MM-DD"
                    //key={eventDateRange.length}
                    style={{ width: '100%', marginBottom: 16 }}
                    placeholder={eventDateRange.length > 0 ? eventDateRange : ['New Business Starts On']}
                    onChange={(t, r) => setEventDateRange([r])}
                />
                { selectedSkus.length > 0 &&
                    <Row gutter={16}>
                        <Col span={12}>
                            <b style={{ display: 'block', marginBottom: 16 }}>
                                Initial Order Volume ({volumeName})
                            </b>
                        </Col>
                        <Col span={12}>
                            <b style={{ display: 'block', marginBottom: 16 }}>
                                Ongoing Velocity Add ({volumeName} / Month)
                            </b>
                        </Col>
                    </Row>
                }
                {
                    selectedSkus.map((sku, skuIdx) => {
                        return (
                            <>
                            <Typography.Text>{dataSource['products'].find(entry => entry.product_id === sku).name}</Typography.Text>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <InputNumber
                                        value={eventVolume[skuIdx]}
                                        style={{ width: '100%' }}
                                        onChange={(value) => {
                                            const newVolumes = [...eventVolume]; 
                                            newVolumes[skuIdx] = value !== null ? value : 0;;
                                            setEventVolume(newVolumes);
                                        }}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputNumber
                                        value={eventOngoing[skuIdx]}
                                        style={{ width: '100%' }}
                                        onChange={(value) => {
                                            const newOngoing = [...eventOngoing]; 
                                            // svae null as zero
                                            newOngoing[skuIdx] = value !== null ? value : 0;;
                                            setEventOngoing(newOngoing);
                                        }}
                                    />
                                </Col>
                            </Row>
                            </>
                        )
                    })
                }
                <Row style={{ justifyContent: 'flex-end' }}>
                    { eventCustomers.length > 0 && eventDateRange.length > 0 && eventDateRange[0] &&
                        <Typography.Text>
                            Add <Tag>{eventVolume.reduce((a, b) => a + b, 0)}</Tag>{volumeName} to forecast on <Tag>{eventDateRange[0]}</Tag>and <Tag>{eventOngoing.reduce((a, b) => a + b, 0)}</Tag>monthly after that
                        </Typography.Text>
                    }
                </Row>
            </>
        }
        </div>
        <Row align={'bottom'}>
            <Col flex={'80px'}>
            <Button 
                type="primary" 
                disabled={!isFormValid || (!volumeSetting || unit === 'revenue')}
                onClick={saveAndClose}
            >
                Save
            </Button>
            </Col>
            <Col flex={'80px'}>
            <Button 
                type="default" 
                onClick={handleClose} // Add your cancel function here
            >
                Cancel
            </Button>
            </Col>
            <Col flex={'auto'}>
            </Col>
            <Col align="right">
            <Button 
                danger
                disabled={prepopulateEvent===undefined}
                onClick={() => deleteEvent(prepopulateEvent)}
            >
                Delete
            </Button>
            </Col>
        </Row>
    </Drawer>
    <Drawer
        placement="right"
        closable={true}
        onClose={() => setNewCustOpen(false)}
        open={newCustOpen}
        width={600}
        bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        title="Add New Customer"
    >
        <div style={{ flex: 1, overflowY: 'auto' }}> 
        <b>New Customer Name</b>
        <Input
            placeholder={'Enter new customer name'}
            value={newCust}
            onChange={(nc) => {
                setNewCust(nc.target.value);
            }}
        />
        </div>
        <Row align={'bottom'}>
            <Col flex={'80px'}>
            <Button 
                type="primary" 
                disabled={!newCust}
                onClick={() => {
                    setLocalUpdates([
                        ...localUpdates, 
                        {'action': 'new_customer', 'name': newCust}
                    ]);
                    setNewCustOpen(false);
                }}
            >
                Save
            </Button>
            </Col>
            <Col flex={'80px'}>
            <Button
                type="default" 
                onClick={() => setNewCustOpen(false)} 
            >
                Cancel
            </Button>
            </Col>
            <Col flex={'auto'}>
            </Col>
            <Col align="right">
            </Col>
        </Row>
    </Drawer>
    <Drawer
        placement="right"
        closable={true}
        onClose={() => setNewProductOpen(false)}
        open={newProductOpen}
        width={600}
        bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        title="Add New Product"
    >
        <div style={{ flex: 1, overflowY: 'auto' }}> 
        <b>New Product Name</b>
        <Input
            placeholder={'Enter new product name'}
            value={newProduct}
            onChange={(nc) => {
                setNewProduct(nc.target.value);
            }}
        />
        </div>
        <Row align={'bottom'}>
            <Col flex={'80px'}>
            <Button 
                type="primary" 
                disabled={!newProduct}
                onClick={() => {
                    setLocalUpdates([
                        ...localUpdates, 
                        {'action': 'new_product', 'name': newProduct}
                    ]);
                    setNewProductOpen(false);
                }}
            >
                Save
            </Button>
            </Col>
            <Col flex={'80px'}>
            <Button
                type="default" 
                onClick={() => setNewProductOpen(false)} 
            >
                Cancel
            </Button>
            </Col>
            <Col flex={'auto'}>
            </Col>
            <Col align="right">
            </Col>
        </Row>
    </Drawer>
    </>
  )
}

export default AddEvent;
