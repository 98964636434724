import React, {useState, useEffect, useRef, useContext} from "react";
import { buildTimeKey } from "../utils";
import {
    Input,
    Modal,
    Table,
    Tag,
} from "antd";
import { volumeFormatter } from "../utils";

const ChangeName = ({visible, setVisible, savePlan, productId}) => {
    const [newName, setNewName] = useState(null);
    const [canSave, setCanSave] = useState(false);

    useEffect(() => {
        setNewName(null);
    }, [visible]);

    const handleSave = () => {
        savePlan(
            [{
                'sku': productId, 'display_name': newName, 'type': 'product'
            }], 
            false
        );
        
        setNewName(null);
        setVisible(false);
    }
  
    return (
      <>
        <Modal
          title="Change Product Display Name"
          open={visible}
          onCancel={() => {setVisible(false);}}
          onOk={() => handleSave()}
          okButtonProps={{ disabled: !canSave }} 
          okText={'Save'}
        >
          <Input onChange={(v) => {
            setNewName(v.target.value); 
            console.log(v.target.value)
            setCanSave(v.target.value.length > 1);
          }}/>
        </Modal>
      </>
    );
  };

  
  export default ChangeName;