import {Layout, Menu, Button, Row} from "antd";
import React, {useEffect, useState} from "react";
import {Content} from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import {
    SettingOutlined,
    ShoppingCartOutlined,
    CompassOutlined,
    MonitorOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SmileOutlined,
    DollarOutlined, LineChartOutlined
} from "@ant-design/icons";
import Loading from "../Loading";
import moment from "moment";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import PlanningTable from "./PlanningTable";
import SkuSettings from "./SkuSettings";
import {updateSkuPlan} from "../../endpoints/skuPlan";
import AssumptionSettings from "./Assumptions";
import SkuExplorer from "./SkuExplorer";
import Dashboard from "./Dashboard";
import Inputs from "./Pipeline/Inputs";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";

const sideColor = '#F5F5F5'

const PlanningContainer = (props) => {
    const {userData, hasError, setHasError} = props;

    const nav = useNavigate();

    const [skuSelect, setSkuSelect] = useState(null);
    const [selectedSku, setSelectedSku] = useState(null);

    const [noData, setNoData] = useState(false);

    const [validating, setValidating] = useState(false);
    const [dataSource, setDataSource] = useState(null);
    const [volumeSetting, setVolumeSetting] = useState(true);
    const [aggPeriod, setAggPeriod] = useState('MS');
    const [unit, setUnit] = useState('volume');
    const [pageMenu, setPageMenu] = useState('customers');

    const sixMonthsAgo = moment().subtract(6, "months").startOf("month");
    const sixMonthsLater = moment().add(6, "months").startOf("month");
    const [trange, setTrange] = useState([sixMonthsAgo, sixMonthsLater]);

    const {pathname} = useLocation();

    if (pathname.includes('customer') && pageMenu !== 'customers') {
        // hack for side menu until everything in here has a route
        setPageMenu('customers');
    } else if (pathname.includes('order') && pageMenu !== 'order') {
        setPageMenu('order');
    } else if ((!pathname.includes('customer') && pageMenu === 'customers') || (!pathname.includes('order') && pageMenu === 'order')) {
        setPageMenu('sku');
    }

    const [collapsed, setCollapsed] = useState(false); 
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const savePlan = async (to_save, initial, agg_unit_enabled = null) => {
        if (!validating) {
            setValidating(true);
        }

        let planData;
        try {
            planData = await updateSkuPlan(
                userData.email,
                to_save,
                userData.accessToken,
                initial,
                !volumeSetting,
                aggPeriod,
                unit === 'revenue',
                trange,
                agg_unit_enabled,
            );
        } catch (e) {
            setHasError(true);
            planData = null;
        }

        if (planData) {
            if (planData['plan'].length === 0)
                setNoData(true); 
            else if (selectedSku === null)
                setSelectedSku(planData['plan'][0]['sku']);

            setDataSource(planData);
        } else {
            // prevent infinite loop of attempted fetch if broken backend or network
            setDataSource([]);
            console.log('failed fetch')
        }

        setValidating(false);
        return planData;
    }

    const handleSave = async (row, colKey) => {
        if (validating) return;

        var toSave = [];

        let dataKey;
        if (row.key.includes('setting')) {
            const newData = {
                'sku': row['sku'],
                'inventory': row['warehouse_inventory'],
                'type': 'product',
                'po_unit': null,
                'agg_unit': null,
                'agg_unit_size': null,
                'sales_unit_conversion': null,
                'archived': null
            };

            if (colKey === 'po_unit') {
                newData['po_unit'] = row['po_unit']
            } else if (colKey === 'agg_unit') {
                newData['agg_unit'] = row['agg_unit']
            } else if (colKey === 'agg_unit_size') {
                newData['agg_unit_size'] = row['agg_unit_size']
            } else if (colKey === 'sales_unit_conversion') {
                newData['sales_unit_conversion'] = row['sales_unit_conversion']
            } else if (colKey === 'archived') {
                newData['archived'] = row['archived']
            }

            toSave.push(newData);
        } else if (row.key.includes('actual')) {
            dataKey = 'actuals'
        } else if (row.key.includes('promotion')) {
            dataKey = 'assumptions'
        } else if (row.key.includes('plan_orders')) {
            toSave.push({'sku': row['sku'], 'month': colKey, 'value': row[colKey], 'type': 'demand_plan'});
        } else if (row.key.includes('plan_pos')) {
            toSave.push({'sku': row['sku'], 'month': colKey, 'value': row[colKey], 'type': 'production_plan'});
        } else
            throw new Error('Unknown data key: '+row.key);

        await savePlan(toSave);
    };

    let content;
    if (pageMenu === 'products' || skuSelect) {

        if (pageMenu !== 'products') setPageMenu('products');
        //if (unit !== 'volume') setUnit('volume')
        if (skuSelect !== null && skuSelect !== selectedSku)
            setSelectedSku(skuSelect);

        // TODO: remove once weekly works on skuExplorer
        // if (aggPeriod !== 'MS')
        //     setAggPeriod('MS')

        content = dataSource && selectedSku && <SkuExplorer
            dataSource={dataSource}
            setHasError={setHasError}
            userData={userData}
            skuSelect={skuSelect}
            setDataSource={setDataSource}
            trange={trange}
            setTrange={setTrange}
            validating={validating}
            savePlan={savePlan}
            handleSave={handleSave}
            unit={unit}
            setUnit={setUnit}
            selectedSku={selectedSku}
            aggPeriod={aggPeriod}
            setAggPeriod={setAggPeriod}
            setSkuSelect={setSkuSelect}
            setSelectedSku={setSelectedSku}
            volumeSetting={volumeSetting}
            setVolumeSetting={setVolumeSetting}
        />

    } else if (pageMenu === 'sku') {
        content = (
            dataSource ? <PlanningTable
                validating={validating}
                setSkuSelect={setSkuSelect}
                dataSource={dataSource}
                handleSave={handleSave}
                unit={unit}
                setUnit={setUnit}
                aggPeriod={aggPeriod}
                setAggPeriod={setAggPeriod}
                volumeSetting={volumeSetting}
                setVolumeSetting={setVolumeSetting}
                accessToken={userData.accessToken}
            /> : <Loading/>
        );
    } else if (pageMenu === 'dash') {
        content = (
            <Dashboard
                validating={validating}
                setSkuSelect={setSkuSelect}
                dataSource={dataSource}
                handleSave={handleSave}
                unit={unit}
                setUnit={setUnit}
                volumeSetting={volumeSetting}
                setVolumeSetting={setVolumeSetting}
                accessToken={userData.accessToken}
            />
        );
    } else if (pageMenu === 'inputs') {
        content = (
            <Inputs
                hasError={hasError}
                setHasError={setHasError}
                savePlan={savePlan}
                userData={userData}
                productIds={[]}
                customerIds={[]}
            />
        );
    } else if (pageMenu === 'settings') {
        content = (
            <SkuSettings
                validating={validating}
                dataSource={dataSource}
                handleSave={handleSave}
                accessToken={userData.accessToken}
            />
        );
    } else if (pageMenu === 'assumptions') {
        content = (
            <AssumptionSettings
                validating={validating}
                dataSource={dataSource}
                setDataSource={setDataSource}
                handleSave={handleSave}
                savePlan={savePlan}
                accessToken={userData.accessToken}
            />
        )
    } else if (pageMenu === 'customers') {
        content = undefined;
    } else if (pageMenu === 'order') {
        content = undefined;
    }

    useEffect(() => {
        (async () => {
            // Initial fetch
            if (dataSource === null && !validating) {
                await savePlan(null, true);
            }
        })();
    });

    // handle unit conversions
    useEffect(() => {
        if (dataSource === null) return;

        savePlan(null, false);
    }, [volumeSetting, unit, aggPeriod, trange]);

    return (
        <Layout>
            <Sider
                className={"side-menu"} 
                style={{background: sideColor}} 
                trigger={null} 
                width={150}
                collapsible  // <-- Make the Sider collapsible
                collapsed={collapsed}  // <-- Use the collapsed state here
                onCollapse={toggleCollapsed}  // <-- Use the toggle function here
                collapsedWidth={55}  // <-- Optional: set the width when the Sider is collapsed
            >
                <Menu
                    style={{background: sideColor}}
                    mode="inline"
                    selectedKeys={[pageMenu]}
                    onSelect={({key, keyPath, selectedKeys, domEvent}) => {

                        setSkuSelect(null); // hacky undo product override.. should move to react router to handle
                        setPageMenu(key);
                        
                        if (key === 'order') {
                            nav('/sales/open-orders');
                        } else if (!key.includes('customer'))
                            nav('/sales');
                        else
                            nav('/sales/customer-dashboard');
                    }}
                    items={[
                        {
                            key: 'customers',
                            icon: <><SmileOutlined /></>,
                            label: <Link to='/sales/customer-dashboard'>Customers</Link>
                        },
                        {
                            key: 'products',
                            icon: <CompassOutlined />,
                            label: 'Products',
                        },
                        {
                            key: 'sku',
                            icon: <LineChartOutlined />,
                            label: 'Forecast',
                        },
                        // TODO: this should be done with user permissions
                        userData['email'].includes('@pantry.ai') || userData['email'].includes('@pantrysoftware.com') ? {
                            key: 'assumptions',
                            icon: <SettingOutlined/>,
                            label: 'Settings',
                        } : undefined,
                        // {
                        //     key: 'settings',
                        //     icon: <SettingOutlined />,
                        //     label: 'Settings',
                        // },
                    ]}
                />
                <Row className="sider-collapse-button" style={{justifyContent: 'right'}}>
                    <Button onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                    </Button>
                </Row>      
            </Sider>
            <Content>
                <div className="app-layout-content">
                    {content}
                    <Outlet />
                </div>
            </Content>
        </Layout>
    );
}

export default withAuthenticationRequired(PlanningContainer, {
    onRedirecting: () => <Loading/>,
});
