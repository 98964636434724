import {
    LoadingOutlined,
    DollarCircleOutlined,
    LineChartOutlined,
    ToolOutlined,
    ContainerOutlined,
    CarOutlined,
    CalendarOutlined, SettingOutlined
} from "@ant-design/icons";
import React, {useContext, useEffect, useRef, useState} from "react";
import {
    Button,
    Col,
    Row,
    Select,
    Table,
    Typography,
    Form,
    Input,
    Popconfirm,
    Tag,
    Popover,
    Badge,
    Space,
    Dropdown, Tooltip, Calendar, Tabs, InputNumber, Checkbox
} from "antd";
import { nullSafeConcat } from "../utils";

const colorPrimary = '#4fad96';
const EditableContext = React.createContext(null);

const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};


const EditableCell = ({
                          title,
                          editable,
                          required,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({[dataIndex]: record[dataIndex]});
    };

    const save = async () => {
        try {
            var values = await form.validateFields();

            // If the value is an empty string or undefined, set it to 0
            if (values[dataIndex] === '' || values[dataIndex] === undefined) {
                values[dataIndex] = '0';
            } else {
                // Convert the value to a string and clean commas
                values[dataIndex] = String(values[dataIndex]).replace(/,/g, '');
            }

            toggleEdit();
            handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={[
                    {
                        required: required,
                        message: `${title} is required.`,
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (dataIndex !== 'volume' && !dataIndex.includes('inventory'))
                                return Promise.resolve();
                            if (!isNaN(value))
                                return Promise.resolve();

                            return Promise.reject(new Error('Numbers only'));
                        },
                    }),
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};


const AssumptionSettings = (props) => {
    const {dataSource, handleSave, validating, savePlan} = props;
    const [tab, setTab] = useState('1');
    const [addUnitEnabled, setAddUnitEnabled] = useState(dataSource['meta']['default_agg_unit_size'] !== null);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const inventoryCols = nullSafeConcat(
    [
        {
            title: 'SKU',
            dataIndex: 'sku',
            fixed: 'left',
            width: 200,
            //defaultSortOrder: 'descend',
            render: (_, row) => {
                if (row.archived) {
                    return <Typography.Text>{'(' + row.sku + ') ' + row.name} <Tag color={'gold'}>ARCHIVED</Tag></Typography.Text>
                } else {
                    return <Typography.Text>{'(' + row.sku + ') ' + row.name}</Typography.Text>
                }
            },
            // sorter: (a, b) => {
            //     if (a.archived === b.archived)
            //         return a.sku > b.sku ? 1 : 0
            //     else {
            //         return !a.archived ? 2 : -1
            //     }
            // }
        },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Entering current inventory lets Pantry know what existing finished product exists for we can estimate weeks-on-hand</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><ContainerOutlined/> Current Inventory (in default units)</Typography.Text>
                </Popover>
            ),
            width: 100,
            dataIndex: 'warehouse_inventory',
            editable: true,
        },
        // {
        //     title: (
        //         <Popover content={<div className={"tablePop"}><Typography.Text>Override the default for this SKU</Typography.Text></div>} title="What's this for?">
        //             <Typography.Text><SettingOutlined/> Default Unit Name</Typography.Text>
        //         </Popover>
        //     ),
        //     width: 100,
        //     dataIndex: 'po_unit',
        //     editable: true,
        // },
        // {
        //     title: (
        //         <Popover content={<div className={"tablePop"}><Typography.Text>Override the default for this SKU</Typography.Text></div>} title="What's this for?">
        //             <Typography.Text><SettingOutlined/> Secondary Unit Name</Typography.Text>
        //         </Popover>
        //     ),
        //     width: 100,
        //     dataIndex: 'agg_unit',
        //     editable: true,
        // },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Override the default for this SKU</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><SettingOutlined/> Sales to Primary Units Conversion</Typography.Text>
                </Popover>
            ),
            width: 100,
            dataIndex: 'sales_unit_conversion',
            editable: true,
        },
    ], dataSource['meta']['default_agg_unit_size'] === null ? null : [
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Override the default for this SKU</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><SettingOutlined/> Default to Secondary Units Conversion</Typography.Text>
                </Popover>
            ),
            width: 100,
            dataIndex: 'agg_unit_size',
            editable: true,
        },
        // {
        //     title: (
        //         <Popover content={<div className={"tablePop"}><Typography.Text>Entering in-transit inventory lets Pantry know what inventory is on the way from source</Typography.Text></div>} title="What's this for?">
        //             <Typography.Text><CarOutlined /> In-Transit Inventory</Typography.Text>
        //         </Popover>
        //     ),
        //     width: 100,
        //     dataIndex: 'transit_inventory',
        //     editable: true,
        // },
        // {
        //     title: (
        //         <Popover content={<div className={"tablePop"}><Typography.Text>Entering in-production inventory lets Pantry know what inventory is already being produced</Typography.Text></div>} title="What's this for?">
        //             <Typography.Text><ToolOutlined/> In-Production Inventory</Typography.Text>
        //         </Popover>
        //     ),
        //     width: 100,
        //     dataIndex: 'production_inventory',
        //     editable: true,
        // },
    ]);

    const defaultColumns = [
        {
            title: 'Promotion Label',
            dataIndex: 'label',
            fixed: 'left',
            width: 100,
            editable: true
        },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Lead Time tells Pantry how long (in days) it takes to secure the finished goods for a SKU</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><ContainerOutlined/> SKU</Typography.Text>
                </Popover>
            ),
            width: 100,
            align: 'right',
            dataIndex: 'sku',
            render: (val, row) => {
                return (
                    <Select
                        showSearch
                        style={{width: '100%'}}
                        optionFilterProp="children"
                        placeholder={row.display_sku}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value, event) => {
                            const rowMutation = {key: row.key, display_sku: event.label, sku: event.value ? event.value : -1};
                            handleSave(rowMutation);
                        }}
                        options={
                            dataSource['plan'].map((plan_row) => {
                                return {label: '(' + plan_row.sku + ') ' + plan_row.name, value: plan_row.sku}
                            })
                        }
                    />
                );
            },
            editable: false,
        },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Start time of the promotion or campaign</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><CalendarOutlined/> Start</Typography.Text>
                </Popover>
            ),
            width: 100,
            editable: false,
            render: (val, row) => {
                return (
                    <Popover content={
                        <Calendar mode='year' style={{width: 300}} fullscreen={false} onSelect={(value, mode) => {
                            const selectedDate = value.format('MMM YYYY');

                            handleSave({key: row.key, start: selectedDate})
                        }} />
                    }>
                        <Typography.Text>{row.start ? row.start : 'Select Start'}</Typography.Text>
                    </Popover>
                )
            },
            dataIndex: 'start',
            align: 'right',
        },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>End time of the promotion or campaign</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><CalendarOutlined /> End</Typography.Text>
                </Popover>
            ),
            width: 100,
            render: (val, row) => {
                return (
                    <Popover content={
                        <Calendar mode='year' style={{width: 300}} fullscreen={false} onSelect={(value, mode) => {
                            const selectedDate = value.format('MMM YYYY');
                            handleSave({key: row.key, end: selectedDate})
                        }} />
                    }>
                        <Typography.Text>{row.end ? row.end : 'Select End'}</Typography.Text>
                    </Popover>
                )
            },
            dataIndex: 'end',
            align: 'right',
            editable: false,
        },
        {
            title: (
                <Popover content={<div className={"tablePop"}><Typography.Text>Lead Time tells Pantry how long (in days) it takes to secure the finished goods for a SKU</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><LineChartOutlined/> Expected Promotion Volume</Typography.Text>
                </Popover>
            ),
            width: 100,
            dataIndex: 'volume',
            render: (val) => val + ' units',
            align: 'right',
            editable: true,
        },
        {
            title:  (
                <Popover content={<div className={"tablePop"}><Typography.Text>Minimum Order Quantity (MOQ) tells Pantry how small the minimum size of a production run can be for a SKU (e.g. a co-packer minimum order size)</Typography.Text></div>} title="What's this for?">
                    <Typography.Text><DollarCircleOutlined/> Discount Rate</Typography.Text>
                </Popover>
            ),
            dataIndex: 'discount',
            render: (val) => val + ' %',
            align: 'right',
            width: 100,
            editable: true,
        },
    ];

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const applyEditable = (col) => {
        if (!col.editable) {
            return col;
        }
        if (col.children) {
            col.children = col.children.map((child) => {
                if (!child.editable) {
                    return child;
                }
                return {
                    ...child,
                    onCell: (record) => ({
                        record,
                        required: child.required,
                        editable: child.editable,
                        dataIndex: child.dataIndex,
                        title: child.title,
                        handleSave: (row) => handleSave(row, child.dataIndex),
                    }),
                };
            })
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                required: col.required,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: (row) => handleSave(row, col.dataIndex),
            }),
        };
    }

    const handleAdd = () => {
        // TODO: this is built by SKU now, need to fix
        const len = dataSource['assumptions']['promotions'].length + 1;
        const k = 'promotions_' + len;

        const newRow = {
            key: k,
            label: 'New Promotion ' + len,
            sku: ' ',
            start: 'Select Start',
            end: 'Select End',
            discount: 0.0,
            volume: 0
        };

        handleSave(newRow);
    };

    const columns = defaultColumns.map(applyEditable);
    const invCols = inventoryCols.map(applyEditable);

    return (
        <div>
            {/*<Tabs*/}
            {/*    size={"large"}*/}
            {/*    type={"card"}*/}
            {/*    defaultActiveKey="1" items={[*/}
            {/*    {*/}
            {/*        key: '1',*/}
            {/*        label: "Inventory",*/}
            {/*    },*/}
            {/*    // {*/}
            {/*    //     key: '2',*/}
            {/*    //     label: "Promotions",*/}
            {/*    // },*/}
            {/*]} onChange={(t) => {return setTab(t)}} />*/}

            { dataSource && tab === '1' &&
                <>
                <Row>
                    <Col flex={'auto'}><Typography.Text style={{fontSize: 18, fontWeight: "bold"}}>Unit Defaults</Typography.Text>
                    </Col>
                    <Col flex={'100px'}>
                        {validating &&
                            <Tag  color={colorPrimary}><Typography.Text style={{color: "white"}}><LoadingOutlined/> Loading</Typography.Text></Tag>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col flex={'auto'}>
                        <Typography.Text>These defaults apply to all SKUs that do not have SKU-specific overrides in the table below</Typography.Text>
                    </Col>
                    <Col flex={'100px'}>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Typography.Text style={{padding: "5px 5px 5px 0"}}>Aggregated unit enabled: </Typography.Text>
                    <Checkbox
                        checked={addUnitEnabled}
                        onChange={(value, event) => {
                            setAddUnitEnabled(value.target.checked);
                            savePlan({}, null, value.target.checked);
                        }}
                    >
                    </Checkbox>
                </Row>
                <Row>
                    <Typography.Text style={{padding: "5px 5px 5px 0"}}>My Sales Orders are in</Typography.Text>
                    <Input defaultValue={dataSource['meta']['default_po_unit']} style={{width: 100}} onPressEnter={(val) => {
                        const newData = [{default_po_unit: val.target.value, default_agg_unit: dataSource['meta']['default_agg_unit'], default_agg_unit_size: dataSource['meta']['default_agg_unit_size'], type: 'account'}];
                        savePlan(newData);
                    }}/>
                    <Typography.Text style={{padding: "5px 5px 5px 5px"}}> (default unit)</Typography.Text>
                </Row>
                <br/>
                <Row 
                    style={{display: dataSource['meta']['default_agg_unit_size'] === null ? 'none' : ''}}
                    >
                    <Typography.Text style={{padding: "5px 5px 5px 0px"}}> My secondary unit is  </Typography.Text>
                    <Input defaultValue={dataSource['meta']['default_agg_unit']} style={{width: 100}} onPressEnter={(val) => {
                        const newData = [{default_po_unit: dataSource['meta']['default_po_unit'], default_agg_unit: val.target.value, default_agg_unit_size: dataSource['meta']['default_agg_unit_size'], type: 'account'}];
                        savePlan(newData);
                    }}/>
                    <Typography.Text style={{padding: "5px 5px 5px 5px"}}> and there are</Typography.Text>
                    <InputNumber
                        type="number"
                        defaultValue={dataSource['meta']['default_agg_unit_size']} style={{width: 100}}
                        onPressEnter={(val) => {
                            const newData = [{default_po_unit: dataSource['meta']['default_po_unit'], default_agg_unit:  dataSource['meta']['default_agg_unit'], default_agg_unit_size: val.target.value ? val.target.value : 0, type: 'account'}];

                            savePlan(newData);
                        }}
                    />
                    <Typography.Text style={{padding: "5px 5px 5px 5px"}}> {dataSource['meta']['default_po_unit']} in one {dataSource['meta']['default_agg_unit']}</Typography.Text>
                    {/*<Input defaultValue={dataSource['meta']['units']['default_po_unit']} style={{width: 100}} onPressEnter={(val) => {*/}
                    {/*    const newData = {...dataSource};*/}
                    {/*    newData['meta']['units']['default_po_unit'] = val.target.value;*/}
                    {/*    savePlan(newData);*/}
                    {/*}}/>*/}
                </Row>
                <br/>
                <Row>
                    <Col flex={'auto'}><Typography.Text style={{fontSize: 18, fontWeight: "bold"}}>Inventory</Typography.Text>
                    </Col>
                    <Col flex={'100px'}>
                    </Col>
                </Row>
                <Table
                    components={components}
                    pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['25', '50', '100']}}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource['settings']}
                    //scroll={{ x: 1000 }}
                    columns={invCols}
                />
                </>
            }
            <br/>
            { dataSource && tab === '2' &&
                <>
                <Row>
                    <Col flex={'auto'}><Typography.Text style={{fontSize: 18, fontWeight: "bold"}}>Promotions</Typography.Text>
                    </Col>
                    <Col flex={'100px'}>
                        <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                            New Promotion
                        </Button>
                    </Col>
                </Row>
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['25', '50', '100']}}
                    bordered
                    dataSource={dataSource['assumptions']['promotions']}
                    //scroll={{ x: 1000 }}
                    columns={columns}
                />
                </>
            }
        </div>
    );
}

export default AssumptionSettings;